import { FC, useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { IIMportedFurniture } from "../../interfaces/IImportedFurniture";
import styled from "styled-components";
import { ICategory } from "../../interfaces/ICategory";
import Categories from "../../components/_common/Categories/Categories";
import LoadingAnimation from "../../components/_common/Loading";
import Searchbar from "../../components/_common/Searchbar";
import Furniture from "./components/Furniture";
import importedFurnitureController from "../../Api/importedFurnitureController";
import { Typography } from "@mui/material";


const Container = styled.div`
    display: flex;
    `

const CategoriesContainer = styled.div`
    width: 20vw;

    @media screen and (max-width: 940px){
        display: none;
    }
`

interface ImportedFurnitureProps {
}

const ImportedFurniture: FC<ImportedFurnitureProps> = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [furnitureData, setFurnitureData] = useState<IIMportedFurniture[]>([])
    const [furnitureToDisplay, setFurnitureToDisplay] = useState<IIMportedFurniture[]>([])
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [categoryFilter, setCategoryFilter] = useState<string>('')
    const [searchValue, setSearchValue] = useState<string>('');
    const [showMobileFilters, setShowMobileFilters] = useState<boolean>(false);


    const getImportedFurniture = useCallback(async () => {
        setLoading(true);
        const res = await importedFurnitureController.getAllImportedFurniture();

        if (!res.ok || !res.furniture) return setLoading(false);

        setFurnitureData(res.furniture);
        if (res.categories) setCategories(res.categories);
        setLoading(false)
    }, [])


    useEffect(() => {
        const newFilteredFurniturePack: IIMportedFurniture[] = [];

        for (const singleFurniture of furnitureData) {
            let displayFurniture = true;

            // Filter by category
            if (singleFurniture && categoryFilter) {
                if (singleFurniture.categories) {
                    if (!singleFurniture.categories.some(category => category === categoryFilter)) {
                        displayFurniture = false;
                    }
                } else {
                    displayFurniture = false;
                }
            }

            // Filter by search value
            if (!singleFurniture.name.toLowerCase().includes(searchValue.toLowerCase()) && searchValue !== '') {
                displayFurniture = false;
            }

            displayFurniture && newFilteredFurniturePack.push(singleFurniture);
        }

        setFurnitureToDisplay(newFilteredFurniturePack);
    }, [categoryFilter, furnitureData, searchValue]);


    useEffect(() => {
        getImportedFurniture()
    }, [getImportedFurniture])


    const renderFurniture = () => {
        if (loading) return <LoadingAnimation />

        if (!furnitureData || !Array.isArray(furnitureData) || furnitureData.length === 0) return (
            <Typography
                component="div"
                sx={{ marginTop: '150px', textAlign: 'center' }}>
                Brak mebli
            </Typography>
        )

        return (
            <Container>
                <CategoriesContainer>
                    <Categories
                        categoryData={categories}
                        searchValue={searchValue}
                        categoryFilter={categoryFilter}
                        showMobileFilters={showMobileFilters}
                        onSetShowMobileFilters={(show: boolean) => setShowMobileFilters(show)}
                        onSetCategoryHandler={(categoryValue) => setCategoryFilter(categoryValue)}
                        onSetSearchValueHandler={(searchValue: string) => setSearchValue(searchValue)}
                    />
                </CategoriesContainer>
                <div>
                    <Searchbar
                        searchValue={searchValue}
                        updateSearchValueHandler={(newSearchValue: string) => setSearchValue(newSearchValue)}
                    />
                    <Furniture
                        furnitureToDisplay={furnitureToDisplay}
                    />
                </div>
            </Container>
        )
    }


    return (
        <Layout
            title="Meble importowane"
            onHandleClickSearchIcon={() => setShowMobileFilters(!showMobileFilters)}
        >
            {renderFurniture()}
        </Layout>
    )
}

export default ImportedFurniture;