import styled from "@emotion/styled";
import { Badge, Button } from "@mui/material";
import { FC, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Inline from "yet-another-react-lightbox/plugins/inline";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

const StyledPhoto = styled.img`
    width: 70px;
    height: 70px;
    object-fit: contain;
`;

const SlidesViewer = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (max-width: 940px){
        display: none;
    }
`

const CustomBadge = styled(Badge)`
    @media screen and (min-width: 940px){
        display: none;
    }

`

interface ImageComponentProps {
    imageUrl: string;
}

const ImageComponent: FC<ImageComponentProps> = ({
    imageUrl,
}) => {
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);

    const toggleOpen = (state: boolean) => () => setOpen(state);

    const updateIndex = ({ index: current }: { index: number }) =>
        setIndex(current);

    const slides = [{ src: imageUrl }];

    return (
        <>
            <CustomBadge
                sx={{
                    position: 'absolute',
                    right: 30,
                }}
                badgeContent={`${index !== undefined ? index + 1 : 1}/${slides?.length}`}
                color="primary"
            />
            <Lightbox
                styles={{ container: { backgroundColor: "rgba(255, 255, 255, 0.8)" } }}
                index={index}
                slides={slides}
                plugins={[Inline]}
                on={{
                    view: updateIndex,
                    click: toggleOpen(true),
                }}
                carousel={{
                    padding: 0,
                    spacing: 0,
                    imageFit: "contain",
                }}
                inline={{
                    style: {
                        maxHeight: "55vh",
                        width: "100%",
                        aspectRatio: "3 / 2",
                        margin: "0 auto",
                        cursor: 'pointer'
                    },
                }}
            />
            <SlidesViewer>
                {slides.slice(0, 8).map((slide, index) => (
                    <Button
                        key={index}
                        onClick={() => { setIndex(index); }}
                        color="primary"
                    >
                        <StyledPhoto src={slide.src} alt="furniture" />
                    </Button>
                ))}
            </SlidesViewer>
            <Lightbox
                plugins={[Zoom]}
                open={open}
                close={toggleOpen(false)}
                index={index}
                slides={slides}
                on={{ view: updateIndex }}
                animation={{ fade: 0 }}
                controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
            />
        </>
    )
}

export default ImageComponent;