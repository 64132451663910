import styled from "@emotion/styled";
import { Button, Divider } from "@mui/material";
import { FC, useRef, useState } from "react";
import CustomDialog from "../../../../../../components/_common/CustomDialog";
import LoadingAnimation from "../../../../../../components/_common/Loading";
import { useNavigate } from "react-router-dom";
import furnitureController from "../../../../../../Api/furnitureController";
import { useToast } from "../../../../../../hooks/useToast";

const MyDivider = styled(Divider)`
    margin-top: 15px;
    margin-bottom: 10px;
`

interface DeleteButtonComponentProps {
    showEditTools: boolean,
    furnitureId: string,
}

const DeleteButtonComponent: FC<DeleteButtonComponentProps> = ({
    showEditTools,
    furnitureId,
}) => {
    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const toast = useRef(useToast()).current


    const handleDelete = async () => {
        setLoading(true)
        const res = await furnitureController.deleteFurniture(furnitureId)
        setLoading(false)
        if (!res.ok) {
            return toast.error(res.message)
        }
        toast.success(res.message)
        navigate('/meble')
    }


    if (!showEditTools) return <></>

    if (loading) return <LoadingAnimation />

    return (
        <>
            <Button
                color="error"
                variant="contained"
                sx={{
                    width: '100%'
                }}
                onClick={() => setShowDeleteAlert(true)}
            >
                Usuń mebel
            </Button>
            <MyDivider />
            <CustomDialog
                sx={{ backgroundColor: 'red'}}
                open={showDeleteAlert}
                onSetOpen={setShowDeleteAlert}
                title="Usuwanie mebla"
                description="Czy na pewno chcesz usunąć mebel z bazy?"
                onAgree={() => handleDelete()}
            />
        </>
    )
}

export default DeleteButtonComponent;