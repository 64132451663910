import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ListItemButton, ListItemText, Collapse, List } from "@mui/material";
import { FC } from "react";
import { ICollection } from "../../../../../../interfaces/ICollection";

interface CollectionsComponentProps {
    producerFilter?: string | null;
    collections?: ICollection[] | null;
    collectionFilter?: string | null;
    showCollections: boolean;
    onSetShowCollections: (show: boolean) => void;
    onHandleClickCollection: (collectionName: string) => () => void;
}

const CollectionsComponent: FC<CollectionsComponentProps> = ({
    producerFilter,
    collections,
    showCollections,
    collectionFilter,
    onSetShowCollections,
    onHandleClickCollection,
}) => {
    return (
        <>
            <ListItemButton
                disabled={!collections || collections.length === 0}
                onClick={() => onSetShowCollections(!showCollections)}
            >
                <ListItemText
                    primary={
                        `Kolekcje ${producerFilter && (!collections || collections.length === 0)
                            ? '(Producent nie posiada kolekcji)'
                            : !producerFilter
                                ? '(Wybierz producenta)'
                                : ''
                        }`
                    }
                />
                {showCollections ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={showCollections} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {collections && collections.map((collection) => {

                        if (collection.name.toLowerCase() === 'signal') return

                        return (
                            <ListItemButton
                                sx={{ pl: 4 }}
                                key={collection.name}
                                onClick={onHandleClickCollection(collection._id)}
                            >
                                <ListItemText
                                    primary={collection.name}
                                    sx={{ color: collectionFilter === collection.name ? 'rgba(0, 21, 255, 0.658)' : '' }}
                                />
                            </ListItemButton>
                        )
                    }
                    )}
                </List>
            </Collapse>
        </>
    )
}

export default CollectionsComponent;