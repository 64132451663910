import { FC, useState } from "react";
import styled from "styled-components";
import searchIcon from '../../assets/img/search.svg'


const Search = styled.div`
    margin: 25px;
    display: flex;
    justify-content: center;
    margin: 25px 5vw;
    border: 1px solid lightgray;
    border-radius: 5px;
    transition: outline 1s ease;

    @media screen and (max-width: 940px) {
        display: none;
}
`

const SearchIcon = styled.img`
    padding: 10px 0px 10px 10px;
`

const SearchInput = styled.input`
    padding-left: 10px;
    font-size: large;
    border: none;
    background-color: none;
    height: 2em;
    width: 100%;
    outline: none;
    font-weight: lighter;
`

const SearchButton = styled.button`
    min-width: 100px;
    font-size: medium;
    border: none;
    border-radius: 4px;
    transition: all 0.5s;
    cursor: pointer;
    font-weight: lighter;

    &:hover {
        color: rgba(0, 21, 255, 0.658);
        border: 1px solid rgba(0, 21, 255, 0.658);
        background-color: white;
}

    &:active {
        background-color: rgba(0, 0, 0, 0.225);
        color: rgba(0, 21, 255, 0.658);
}
`

interface SearchbarProps {
    searchValue: string,
    updateSearchValueHandler: (newSearchValue: string) => void,
}

const Searchbar: FC<SearchbarProps> = ({
    searchValue,
    updateSearchValueHandler,
}) => {
    const [newSearchValue, setNewSearchValue] = useState<string>(searchValue || '')

    return (
        <Search>
            <SearchIcon
                alt="search icon"
                src={searchIcon}
            />

            <SearchInput
                value={newSearchValue}
                onChange={(e) => setNewSearchValue(e.target.value)}
                onKeyDown={e => (e.key === 'Enter' && updateSearchValueHandler(newSearchValue))}
                type='text'
                placeholder='Wyszukaj...'
                inputMode="search"
            />

            <SearchButton
                onClick={() => updateSearchValueHandler(newSearchValue)}
            >
                Szukaj
            </SearchButton>
        </Search>
    )
}

export default Searchbar;