import { TableContainer, Paper, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { FC } from "react";

interface FeaturesComponentProps {
    features: any[],
}

const FeaturesComponent: FC<FeaturesComponentProps> = ({
    features,
}) => {
    return (
        <>
            <TableContainer sx={{ maxWidth: 650 }} component={Paper}>
                <Table aria-label="simple table">
                    <TableBody>
                        {features[0].feature.map((feature: any) => (
                            <TableRow
                                key={feature.name[0]}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {feature.name[0]}
                                </TableCell>
                                <TableCell align="left">{feature.value[0]}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default FeaturesComponent;