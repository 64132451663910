import styles from './adminPanel.module.scss';
import ProducersComponent from './Components/ProducersComponent/ProducersComponent';
import CollectionsComponent from './Components/CollectionsComponent/CollectionsComponent';
import FurnitureComponent from './Components/FurnitureComponent/FurnitureComponent';
import PromotionalFurnitureComponent from './Components/PromotionalFurnitureComponent/PromotionalFurnitureComponent';
import ImageSlider from './Components/ImageSliderComponent/ImageSlider';
import { useLayoutEffect, useState } from 'react';
import StorageComponent from './Components/StorageController/StorageComponent';
import Layout from '../../../components/layout/Layout'
import { fakeTokenMinsk, fakeTokenPiaseczno } from '../../../config';
import { Button } from '@mui/material';
import importedFurnitureController from '../../../Api/importedFurnitureController';
import Charts from './Components/Charts/Charts';
import styled from '@emotion/styled';


const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`


export default function AdminPanel() {

    const [isLoggedValue, setIsLoggedValue] = useState(false);


    const isLogged = () => {
        const selectedShowroom = localStorage.getItem('selectedShowroom');
        const fakeTokenMinskStored = localStorage.getItem('fakeTokenMinsk');
        const fakeTokenPiasecznoStored = localStorage.getItem('fakeTokenPiaseczno');

        const isMinskLogged = selectedShowroom === 'minsk' && fakeTokenMinskStored === fakeTokenMinsk;
        const isPiasecznoLogged = selectedShowroom === 'piaseczno' && fakeTokenPiasecznoStored === fakeTokenPiaseczno;

        if (isMinskLogged || isPiasecznoLogged) {
            setIsLoggedValue(true);
        } else {
            window.location.href = '/signin';
        }
    }


    useLayoutEffect(() => {
        isLogged();
    }, [])


    return (
        <Layout title='Panel administratora'>
            <div className={styles.general}>
                {

                    isLoggedValue ? (
                        <>
                            <ImageSlider />
                            <ProducersComponent />
                            <CollectionsComponent />
                            <FurnitureComponent />
                            <PromotionalFurnitureComponent />
                            <StorageComponent />
                            <ButtonContainer>
                                <Button onClick={() => importedFurnitureController.forceUpdateImportedFurniture}>
                                    Aktualizuj importowane meble
                                </Button>
                            </ButtonContainer>
                            <Charts />
                        </>
                    ) : (
                        <>
                        </>
                    )

                }

            </div>
        </Layout>
    )
}