import { DoneOutline, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails, FormGroup, FormControlLabel, Switch, Divider, Icon } from "@mui/material";
import { FC, useState } from "react";
import CustomInput from "../../../../_common/CustomInput";
import styled from "@emotion/styled";
import ImagesForm from "./components/ImagesForm";
import CustomButton from "../../../../_common/CustomButton";


const MyInput = styled(CustomInput)`
    margin-bottom: 20px;
`


interface DetailsProps {
    name: string,
    onSetName: (name: string) => void,
    price: number | null,
    onSetPrice: (price: number) => void,
    width: number | null,
    onSetWidth: (width: number) => void,
    depth: number | null,
    onSetDepth: (depth: number) => void,
    height: number | null,
    onSetHeight: (height: number) => void,
    crossed: number | null,
    onSetCrossed: (crossed: number) => void,
    isPriceVissible: boolean,
    onSetIsPriceVissible: (isPriceVissible: boolean) => void,
    description: string | null,
    onSetDescription: (description: string) => void,
    images: any[],
    onSetImages: (images: any) => void,
    expanded: string | false,
    onSetExpanded: (expandName: string) => void,
    onClickAccordion: (accordionName: string) => void,
}

const Details: FC<DetailsProps> = ({
    name,
    onSetName,
    price,
    onSetPrice,
    width,
    onSetWidth,
    depth,
    onSetDepth,
    height,
    onSetHeight,
    crossed,
    onSetCrossed,
    isPriceVissible,
    onSetIsPriceVissible,
    description,
    onSetDescription,
    images,
    onSetImages,
    expanded,
    onSetExpanded,
    onClickAccordion,
}) => {
    const [showOk, setShowOk] = useState(false)

    return (
        <Accordion expanded={expanded === 'Szczegóły'}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
                onClick={() => onClickAccordion('Szczegóły')}
            >
                3. Szczegóły
                {(name && images.length > 0 && showOk) &&
                    <Icon sx={{ ml: 1 }}>
                        <DoneOutline color="success" />
                    </Icon>
                }
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 7 }}>
                <FormGroup>
                    <MyInput
                        mode='text'
                        value={name}
                        setValue={onSetName}
                        label="Nazwa"
                        placeholder="Nazwa mebla"
                        variant='filled'
                    />
                    <MyInput
                        mode="numeric"
                        value={price}
                        setValue={onSetPrice}
                        label="Cena"
                        placeholder="Cena mebla"
                        variant='filled'
                    />
                    <MyInput
                        mode="numeric"
                        value={crossed}
                        setValue={onSetCrossed}
                        label="Przekreślona cena"
                        placeholder="Przekreślona cena"
                        variant='filled'
                    />
                    <FormControlLabel
                        sx={{ mb: 2 }}
                        control={
                            <Switch
                                checked={isPriceVissible}
                                onChange={(e) => onSetIsPriceVissible(e.target.checked)}
                            />
                        } label="Wyświetlaj cenę mebla"
                    />
                    <MyInput
                        variant='filled'
                        mode="numeric"
                        value={width}
                        setValue={onSetWidth}
                        label="Szerokość"
                        placeholder="Szerokość mebla"
                    />
                    <MyInput
                        variant='filled'
                        mode="numeric"
                        value={height}
                        setValue={onSetHeight}
                        label="Wysokość"
                        placeholder="Wysokość mebla"
                    />
                    <MyInput
                        variant='filled'
                        mode="numeric"
                        value={depth}
                        setValue={onSetDepth}
                        label="Głębokość"
                        placeholder="Głębokość mebla"
                    />
                    <MyInput
                        variant='filled'
                        mode="multiline"
                        value={description}
                        setValue={onSetDescription}
                        label="Opis"
                        placeholder="Opis mebla"
                        maxRows={20}
                    />
                    <ImagesForm
                        images={images}
                        onSetImages={onSetImages}
                    />
                </FormGroup>
                <Divider sx={{ m: 2 }} />
                <CustomButton
                    sx={{ width: '100%' }}
                    text="Dalej"
                    onClick={() => {
                        onSetExpanded('Pomieszczenia');
                        setShowOk(true);
                    }}
                />
            </AccordionDetails>
        </Accordion>
    )
}

export default Details;