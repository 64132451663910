import { Edit } from "@mui/icons-material";
import { Card, IconButton, Table, TableCell } from "@mui/material";
import { FC } from "react";
import { useModal } from "../../../../../../hooks/useModal";
import EditDimensionForm from "../../../../../../components/forms/furniture/EditDimensionForm";

interface DimensionsComponentProps {
    id: string,
    width?: number | null,
    height?: number | null,
    depth?: number | null,
    showEditTools: boolean,
    onRefreshFurniture: () => void;
}

const DimensionsComponent: FC<DimensionsComponentProps> = ({
    id,
    width,
    height,
    depth,
    showEditTools,
    onRefreshFurniture,
}) => {
    const modal = useModal();

    interface EditDimensionButtonProps {
        dimensionName: 'width' | 'height' | 'depth';
    }

    const EditDimensionButton: FC<EditDimensionButtonProps> = ({ dimensionName }) => {
        return (
            <IconButton
                sx={{ p: 0, m: 0, mr: 1 }}
                color="secondary"
                onClick={() => modal.open(
                    <EditDimensionForm
                        _id={id}
                        onRefreshFurniture={onRefreshFurniture}
                        dimensionName={dimensionName}
                        value={
                            dimensionName === 'width' ? width :
                                dimensionName === 'height' ? height :
                                    dimensionName === 'depth' ? depth : null
                        }
                    />
                )}
            >
                <Edit />
            </IconButton>
        );
    };

    return (
        <Card>
            {((width && width !== 0) || showEditTools) ?
                <Table sx={{ width: '100%' }}>
                    <TableCell align="left">
                        {showEditTools &&
                            <EditDimensionButton dimensionName='width' />
                        }
                        Szerokość:
                    </TableCell>
                    <TableCell align="right">{width}</TableCell>
                </Table>
                :
                <></>
            }
            {((height && height !== 0) || showEditTools) ?
                <Table sx={{ width: '100%' }}>
                    <TableCell align="left">
                        {showEditTools &&
                            <EditDimensionButton dimensionName='height' />
                        }
                        Wysokość:
                    </TableCell>
                    <TableCell align="right">{height}</TableCell>
                </Table>
                :
                <></>
            }
            {((depth && depth !== 0) || showEditTools) ?
                <Table sx={{ width: '100%' }}>
                    <TableCell align="left">
                        {showEditTools &&
                            <EditDimensionButton dimensionName='depth' />
                        }
                        Głębokość:
                    </TableCell>
                    <TableCell align="right">{depth}</TableCell>
                </Table>
                :
                <></>
            }
        </Card>
    )
}

export default DimensionsComponent;