import { FC } from "react";
import Layout from "../../components/layout/Layout";
import { ProductionQuantityLimits } from "@mui/icons-material";
import { Box, Card, Divider, List, Typography } from "@mui/material";
import { grey } from '@mui/material/colors';
import { useGlobalState } from "../../context/context";
import styled from "@emotion/styled";
import CustomerForm from "./components/CustomerForm";
import FurnitureItem from "./components/FurnitureItem";

const Container = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 50px;
    min-height: 60vh;
    width: 100%;

    @media screen and (max-width: 940px){
        display: block;
    }
`

const EmptyBucketIcon = styled(ProductionQuantityLimits)`
    width: 300px;
    height: 300px;
    color: ${grey[400]};
`

const EmptyBucketText = styled(Typography)`
    color: ${grey[800]};
`

const EmptyBucketBox = styled(Box)`
    margin-top: 80px;
    display: flex;
    justify-content: center;
`

const CartPageCard = styled(Card)`
    width: 600px;
    max-width: calc(100% - 40px);
    padding: 20px;

    @media screen and (max-width: 940px){
        width: calc(100% - 40px);
    }
`

const FormCard = styled(Card)`
    width: 310px;
    height: 100%;
    padding: 20px;
    margin-left: 10px;
    background-color: #f5f5f5;
    
    @media screen and (min-height: 570px){
        position: sticky;
        top: 80px;
    }

    @media screen and (min-height: 680px){
        position: sticky;
        top: 188px;
    }

    @media screen and (max-width: 940px){
        margin-top: 30px;
        width: calc(100% - 40px);
        margin-left: 0px;
    }
`


interface CartPageProps {

}

const CartPage: FC<CartPageProps> = () => {

    const { state } = useGlobalState();
    const cartItems = state.basketFurniture;

    return (
        <Layout title='Koszyk'>
            <Container>
                {(cartItems?.length > 0 && Array.isArray(cartItems)) ? (
                    <>
                        <CartPageCard>
                            <Typography variant="h6" sx={{ mb: 3 }}>
                                Twój koszyk
                            </Typography>
                            <List>
                                {
                                    cartItems.map((item, index) => (
                                        <div key={index}>
                                            <FurnitureItem key={index} furnitureItem={item} />
                                            {index !== cartItems.length - 1 && <Divider variant="middle" />}
                                        </div>
                                    ))
                                }
                            </List>
                        </CartPageCard>
                        <FormCard>
                            <CustomerForm />
                        </FormCard>
                    </>
                ) : (
                    <EmptyBucketBox>
                        <div>
                            <EmptyBucketIcon />
                            <EmptyBucketText>
                                Twój koszyk jest pusty
                            </EmptyBucketText>
                            <EmptyBucketText>
                                Dodaj meble aby wysłać je do wyceny
                            </EmptyBucketText>
                        </div>
                    </EmptyBucketBox>
                )}
            </Container>
        </Layout >
    )
}

export default CartPage;