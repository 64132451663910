import styled from "@emotion/styled";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { FC, useRef, useState } from "react";
import { useCart } from "../../../../../../hooks/useCart";
import { getShowroomUrl } from "../../../../../../services/getShowroomUrl";
import CustomButton from "../../../../../../components/_common/CustomButton";
import { Edit } from "@mui/icons-material";
import { useModal } from "../../../../../../hooks/useModal";
import EditPriceForm from "../../../../../../components/forms/furniture/EditPriceForm";
import furnitureController, { IChangePriceVisibilityDto } from "../../../../../../Api/furnitureController";
import { useToast } from "../../../../../../hooks/useToast";
import LoadingAnimation from "../../../../../../components/_common/Loading";

const BASE_URL = getShowroomUrl();

const MyDivider = styled(Divider)`
    margin-top: 15px;
    margin-bottom: 10px;
`

const MyButton = styled(CustomButton) <{ isInCart?: boolean }>`
    background-color: ${({ isInCart }) => isInCart ? 'grey' : '#283890'};
    width: 100%;
    margin-top: 10px;
    padding: 0 10;
    font-size: 0.74rem;
`


interface PriceComponentProps {
    id: string,
    price?: number | null,
    showPrice: boolean,
    name: string,
    image: string,
    crossedPrice?: number | null,
    showEditTools: boolean,
    onRefreshFurniture: () => void;
}

const PriceComponent: FC<PriceComponentProps> = ({
    id,
    price,
    showPrice,
    name,
    image,
    crossedPrice,
    showEditTools,
    onRefreshFurniture,
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const modal = useModal();
    const cart = useCart();
    const toast = useRef(useToast()).current;
    const pageURL = window.location.origin;


    const handleClickAddToCart = () => {
        if (cart.isInCart(id)) {
            cart.remove(id);
        } else {
            cart.add({
                type: 'furniture',
                name: name,
                id: id,
                image: `${BASE_URL}${image}`,
                link: `${pageURL}/mebel/${id}`
            })
        }
    }


    const handleChangePriceVisibility = async () => {
        setLoading(true);
        const furnitureData: IChangePriceVisibilityDto = {
            furnitureId: id,
            isPriceVissible: !showPrice,
        }
        const res = await furnitureController.changePriceVisibility(furnitureData);
        setLoading(false);
        if (!res.ok) return toast.error(res.message);
        onRefreshFurniture();
        modal.close();
    }



    interface EditButtonProps {
        priceType: 'price' | 'crossedPrice';
    }

    const EditButton: FC<EditButtonProps> = ({
        priceType
    }) => {
        return (
            <IconButton
                color="secondary"
                onClick={() => modal.open(
                    <EditPriceForm
                        value={
                            priceType === 'price' ? price :
                                priceType === 'crossedPrice' ? crossedPrice : null
                        }
                        priceType={priceType}
                        furnitureId={id}
                        onRefreshFurniture={onRefreshFurniture}
                    />
                )}
            >
                <Edit />
            </IconButton>
        )
    }

    if (loading) return <LoadingAnimation />

    return (
        <>
            {showEditTools &&
                <>
                    {
                        showPrice ?
                            <Button
                                onClick={handleChangePriceVisibility}
                                sx={{
                                    width: '100%',
                                }}
                                color="warning"
                                variant='contained'
                            >
                                Cena widoczna
                            </Button>
                            :
                            <Button
                                onClick={handleChangePriceVisibility}
                                sx={{
                                    width: '100%',
                                }}
                                color="success"
                                variant='contained'
                            >
                                Cena niewidoczna
                            </Button>
                    }
                    <MyDivider />
                </>
            }
            {(showPrice && (crossedPrice && crossedPrice !== 0)) || showEditTools ?
                <Typography variant="h6" sx={{ textAlign: 'center', textDecoration: 'line-through' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {showEditTools && <EditButton priceType="crossedPrice" />}
                        {crossedPrice} zł
                    </Box>
                </Typography>
                : <></>
            }
            {(showPrice && (price && price !== 0)) || showEditTools ?
                <>
                    <Typography variant="h4" sx={{ textAlign: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {showEditTools && <EditButton priceType="price" />}
                            {price} zł
                        </Box>
                    </Typography>
                    <MyDivider />
                </> : <></>
            }
            {(!showPrice || (!price || price === 0)) &&
                <>
                    <MyButton
                        isInCart={cart.isInCart(id)}
                        onClick={handleClickAddToCart}
                        text={cart.isInCart(id) ? "Usuń z koszyka" : "Dodaj do koszyka"}
                    />
                    <MyDivider />
                </>
            }
        </>
    )
}

export default PriceComponent;