import { FC, useRef, useState } from "react";
import FormLayout from "../FormLayout";
import CustomInput from "../../_common/CustomInput";
import { Card, Divider } from "@mui/material";
import CustomButton from "../../_common/CustomButton";
import LoadingAnimation from "../../_common/Loading";
import { useToast } from "../../../hooks/useToast";
import furnitureController, { IEditDescriptionDto } from "../../../Api/furnitureController";
import { useModal } from "../../../hooks/useModal";

interface EditDescriptionFormProps {
    descriptionValue?: string | null;
    furnitureId: string;
    onRefreshFurniture: () => void;
}

const EditDescriptionForm: FC<EditDescriptionFormProps> = ({
    descriptionValue,
    furnitureId,
    onRefreshFurniture,
}) => {
    const [description, setDescription] = useState<string>(descriptionValue || '');
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useRef(useToast()).current;
    const modal = useModal();


    const handleEditDescription = async () => {
        setLoading(true);
        const furnitureData: IEditDescriptionDto = {
            description: description,
            furnitureId: furnitureId,
        }
        const res = await furnitureController.editDescription(furnitureData);
        setLoading(false);
        if (!res.ok) return toast.error(res.message);

        onRefreshFurniture();
        modal.close()
    }


    if (loading) return (
        <Card>
            <LoadingAnimation />
        </Card>
    )

    return (
        <FormLayout
            title="Edytuj opis"
            maxWidth='md'
        >
            <CustomInput
                sx={{
                    width: '100%',
                }}
                mode="multiline"
                maxRows={10}
                label="Opis"
                placeholder="Opis"
                value={description}
                setValue={setDescription}
                variant="filled"
            />
            <Divider sx={{ m: 1 }} />
            <CustomButton
                sx={{
                    width: '100%',
                }}
                text="Zapisz"
                onClick={handleEditDescription}
            />
        </FormLayout>
    )
}

export default EditDescriptionForm;