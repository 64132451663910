import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import { FC } from "react";
import { IProducer } from "../../../../../../interfaces/IProducer";


interface ProducersComponentProps {
    producers: IProducer[];
    producerFilter?: string | null;
    showProducers: boolean;
    onSetShowProducers: (show: boolean) => void;
    onHandleClickProducer: (producerName: string) => () => void;
}

const ProducersComponent: FC<ProducersComponentProps> = ({
    producers,
    showProducers,
    producerFilter,
    onSetShowProducers,
    onHandleClickProducer,
}) => {
    return (
        <>
            <ListItemButton onClick={() => onSetShowProducers(!showProducers)} >
                <ListItemText primary="Producenci" />
                {showProducers ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={showProducers} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {producers.map((producer) => {

                        if (producer.name.toLowerCase() === 'signal') return

                        return (
                            <ListItemButton
                                sx={{ pl: 4 }}
                                key={producer.name}
                                onClick={onHandleClickProducer(producer._id)}
                            >
                                <ListItemText
                                    primary={producer.name}
                                    sx={{ color: producerFilter === producer.name ? 'rgba(0, 21, 255, 0.658)' : '' }}
                                />
                            </ListItemButton>
                        )
                    }
                    )}
                </List>
            </Collapse>
        </>
    )
}

export default ProducersComponent;