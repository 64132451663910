import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ListItemButton, ListItemText, Collapse, List } from "@mui/material";
import { FC } from "react";
import { ICategory } from "../../../../../../interfaces/ICategory";
import CategoryPartComponent from "./CategoryPartComponent";


interface CategoryComponentProps {
    showCategories: boolean;
    onSetShowCategories: (show: boolean) => void;
    categories: ICategory[];
    categoryFilter: string;
    onHandleClickCategory: (categoryName: string) => () => void;
}

const CategoryComponent: FC<CategoryComponentProps> = ({
    showCategories,
    onSetShowCategories,
    categories,
    categoryFilter,
    onHandleClickCategory,
}) => {

    return (
        <>
            <ListItemButton onClick={() => onSetShowCategories(!showCategories)} >
                <ListItemText primary="Kategorie" />
                {showCategories ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={showCategories} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {categories.map((category) => (
                        <CategoryPartComponent
                            key={category.name}
                            category={category}
                            showCategories={showCategories}
                            categoryFilter={categoryFilter}
                            onHandleClickCategory={onHandleClickCategory}
                        />
                    ))}
                </List>
            </Collapse>
        </>
    )
}

export default CategoryComponent;