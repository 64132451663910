import { Divider, Typography, Card, Table, TableCell } from "@mui/material";
import { FC } from "react";
import styled from "@emotion/styled";
import CustomButton from "../../../../../components/_common/CustomButton";
import { useCart } from "../../../../../hooks/useCart";

const MyDivider = styled(Divider)`
    margin-top: 15px;
    margin-bottom: 10px;
`

const MyButton = styled(CustomButton) <{ isInCart?: boolean }>`
    background-color: ${({ isInCart }) => isInCart ? 'grey' : '#283890'};
    width: 100%;
    margin-top: 10px;
    padding: 0 10;
    font-size: 0.74rem;
`

interface DetailsComponentProps {
    id: string,
    name: string,
    image: string,
    price?: number | null,
    width?: number | null,
    height?: number | null,
    depth?: number | null,
}

const DetailsComponent: FC<DetailsComponentProps> = ({
    id,
    name,
    price,
    width,
    height,
    depth,
    image,
}) => {
    const cart = useCart();
    const pageURL = window.location.origin;

    const handleClickAddToCart = () => {
        if (cart.isInCart(id)) {
            cart.remove(id);
        } else {
            cart.add({
                type: 'imported furniture',
                name: name,
                id: id,
                image: image,
                link: `${pageURL}/mebel-importowany/${id}`
            })
        }
    }

    return (
        <>
            <Typography variant="h5" sx={{ textAlign: 'center' }}>
                {name}
            </Typography>
            {(price && price !== 0) ?
                <>
                    <MyDivider />
                    <Typography variant="h4" sx={{ textAlign: 'center' }}>
                        {price} zł
                    </Typography>
                </> : <></>
            }
            {
                (width || height || depth) && (
                    <>
                        <MyDivider />
                        <Card>
                            {(width && width !== 0) ?
                                <Table sx={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <TableCell align="left">Szerokość:</TableCell>
                                            <TableCell align="right">{width}</TableCell>
                                        </tr>
                                    </tbody>
                                </Table> : <></>
                            }
                            {(height && height !== 0) ?
                                <Table sx={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <TableCell align="left">Wysokość:</TableCell>
                                            <TableCell align="right">{height}</TableCell>
                                        </tr>
                                    </tbody>
                                </Table> : <></>
                            }
                            {(depth && depth !== 0) ?
                                <Table sx={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <TableCell align="left">Głębokość:</TableCell>
                                            <TableCell align="right">{depth}</TableCell>
                                        </tr>
                                    </tbody>
                                </Table> : <></>
                            }
                        </Card>
                    </>
                )
            }
            {(!price || price === 0) &&
                <>
                    <MyDivider />
                    <MyButton
                        isInCart={cart.isInCart(id)}
                        onClick={handleClickAddToCart}
                        text={cart.isInCart(id) ? "Usuń z koszyka" : "Dodaj do koszyka"}
                    />
                </>
            }
        </>
    )
}

export default DetailsComponent;