const isEmail = (value: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(value)) {
        return true
    } else {
        return false
    }

}

const isPhoneNumber = (value: string): boolean => {
    const phoneRegex = /^(\d{3}-\d{3}-\d{3}|\d{9})$/;

    if (phoneRegex.test(value)) {
        return true
    } else {
        return false
    }
}

const FormHelper = {
    isEmail,
    isPhoneNumber,
}

export default FormHelper