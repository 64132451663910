import { Button, Chip } from "@mui/material";
import { FC } from "react";
import { premisesData } from "../../../../../Premises/premisesData";
import styled from "@emotion/styled";
import { useModal } from "../../../../../../hooks/useModal";
import EditPremiseForm from "../../../../../../components/forms/furniture/EditPremiseForm";


const MyChip = styled(Chip)`
    margin: 3px;
    margin-left: 0;
`

interface PremisesComponentProps {
    furnitureId: string,
    furniturePremises?: string[] | null,
    onRefreshFurniture: () => void;
}

const PremisesComponent: FC<PremisesComponentProps> = ({
    furniturePremises,
    furnitureId,
    onRefreshFurniture,
}) => {
    const modal = useModal();

    return (
        <div>
            <Button
                size="small"
                sx={{
                    width: '100%',
                    mb: 1,
                }}
                color="secondary"
                variant="contained"
                onClick={() => modal.open(
                    <EditPremiseForm
                        furnitureId={furnitureId}
                        furniturePremises={furniturePremises}
                        onRefreshFurniture={onRefreshFurniture}
                    />
                )}
            >
                Edytuj pomieszczenia
            </Button>
            {furniturePremises?.map((premise: string) => (
                <div key={premise}>
                    <MyChip
                        color="secondary"
                        label={premise.toUpperCase()}
                    />
                </div>
            ))}
        </div>
    )
}

export default PremisesComponent;