import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Card } from "@mui/material";
import ImageComponent from "./components/ImageComponenet";
import DetailsComponent from "./components/DetailsComponent/DetailsComponent";
import DescriptionComponent from "./components/DescriptionComponent";

const Container = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 50px;
    width: 100%;

    @media screen and (max-width: 940px){
        display: block;
    }
`

const LeftCard = styled(Card)`
    width: 600px;
    max-width: calc(100% - 40px);
    padding: 20px;

    @media screen and (max-width: 940px){
        width: calc(100% - 40px);
    }
`

const DetailsCard = styled(Card)`
    width: 310px;
    height: 100%;
    padding: 20px;
    margin-left: 10px;
    background-color: #f5f5f5;
    
    @media screen and (min-height: 570px){
        position: sticky;
        top: 60px;
    }

    @media screen and (max-width: 940px){
        margin-top: 30px;
        width: calc(100% - 40px);
        margin-left: 0px;
        position: static;
    }
`

const Padding = styled.div`
    padding: 10px;
`


interface DetailsCardComponentProps {
    id: string,
    name: string,
    width?: number | null,
    height?: number | null,
    depth?: number | null,
    image: string;
    producerId?: string | null;
    partCollection?: string | null;
    price?: number | null;
    crossedPrice?: number | null;
    furnitureCategories?: string[] | null;
    furniturePremises?: string[] | null,
    showPrice: boolean;
    showEditTools: boolean;
    onSetShowEditTools: (state: boolean) => void;
    onRefreshFurniture: () => void;
}

const DetailsCardComponent: FC<DetailsCardComponentProps> = ({
    id,
    name,
    width,
    height,
    depth,
    image,
    producerId,
    partCollection,
    price,
    crossedPrice,
    showPrice,
    furnitureCategories,
    furniturePremises,
    showEditTools,
    onSetShowEditTools,
    onRefreshFurniture,
}) => {
    return (
        <DetailsCard>
            <DetailsComponent
                id={id}
                name={name}
                width={width}
                height={height}
                depth={depth}
                image={image}
                producerId={producerId}
                partCollection={partCollection}
                price={price}
                crossedPrice={crossedPrice}
                furnitureCategories={furnitureCategories}
                furniturePremises={furniturePremises}
                showPrice={showPrice}
                showEditTools={showEditTools}
                onSetShowEditTools={onSetShowEditTools}
                onRefreshFurniture={onRefreshFurniture}
            />
        </DetailsCard>
    )
}


interface FurnitureItemProps {
    id: string,
    name: string,
    images: string[],
    showPrice: boolean;
    thumbnailImages: string[];
    description?: string | null;
    width?: number | null;
    height?: number | null;
    depth?: number | null;
    producerId?: string | null;
    partCollection?: string | null;
    price?: number | null;
    crossedPrice?: number | null;
    showEditTools: boolean;
    index: number;
    showImageView: boolean;
    furnitureCategories?: string[] | null;
    furniturePremises?: string[] | null,
    onSetIndex: (index: number) => void;
    onSetLoading: (loading: boolean) => void;
    onRefreshFurniture: () => void;
    onSetShowEditTools: (state: boolean) => void;
    onSetShowImageView: (state: boolean) => void;
}

const FurnitureItem: FC<FurnitureItemProps> = ({
    id,
    name,
    images,
    thumbnailImages,
    description,
    width,
    height,
    depth,
    producerId,
    partCollection,
    showPrice,
    price,
    crossedPrice,
    showEditTools,
    index,
    showImageView,
    furnitureCategories,
    furniturePremises,
    onSetShowImageView,
    onSetIndex,
    onSetShowEditTools,
    onSetLoading,
    onRefreshFurniture,
}) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [screenWidth]);

    return (
        <Container>
            {screenWidth > 940 ?
                <>
                    <div>
                        <LeftCard>
                            <ImageComponent
                                showImageView={showImageView}
                                onSetShowImageView={onSetShowImageView}
                                indexToStartDisplay={index}
                                onSetIndexToStartDisplay={onSetIndex}
                                showEditTools={showEditTools}
                                onRefreshFurniture={onRefreshFurniture}
                                onSetLoading={(loading: boolean) => onSetLoading(loading)}
                                furnitureId={id}
                                images={images}
                                thumbnailImages={thumbnailImages}
                            />
                        </LeftCard>
                        <DescriptionComponent
                            furnitureId={id}
                            onRefreshFurniture={onRefreshFurniture}
                            showEditTools={showEditTools}
                            description={description}
                        />
                    </div>
                    <DetailsCardComponent
                        id={id}
                        name={name}
                        width={width}
                        height={height}
                        depth={depth}
                        image={images[0]}
                        producerId={producerId}
                        showPrice={showPrice}
                        partCollection={partCollection}
                        price={price}
                        crossedPrice={crossedPrice}
                        showEditTools={showEditTools}
                        onSetShowEditTools={onSetShowEditTools}
                        onRefreshFurniture={onRefreshFurniture}
                        furnitureCategories={furnitureCategories}
                        furniturePremises={furniturePremises}
                    />
                </>
                :
                <Padding>
                    <div>
                        <LeftCard>
                            <ImageComponent
                                showImageView={showImageView}
                                onSetShowImageView={onSetShowImageView}
                                indexToStartDisplay={index}
                                onSetIndexToStartDisplay={onSetIndex}
                                showEditTools={showEditTools}
                                onRefreshFurniture={onRefreshFurniture}
                                onSetLoading={(loading: boolean) => onSetLoading(loading)}
                                furnitureId={id}
                                images={images}
                                thumbnailImages={thumbnailImages}
                            />
                        </LeftCard>
                    </div>
                    <DetailsCardComponent
                        id={id}
                        name={name}
                        width={width}
                        height={height}
                        depth={depth}
                        image={images[0]}
                        producerId={producerId}
                        partCollection={partCollection}
                        price={price}
                        showPrice={showPrice}
                        showEditTools={showEditTools}
                        onSetShowEditTools={onSetShowEditTools}
                        onRefreshFurniture={onRefreshFurniture}
                        furnitureCategories={furnitureCategories}
                        furniturePremises={furniturePremises}
                    />
                    <DescriptionComponent
                        furnitureId={id}
                        onRefreshFurniture={onRefreshFurniture}
                        showEditTools={showEditTools}
                        description={description}
                    />
                </Padding>
            }
        </Container>
    )
}

export default FurnitureItem;