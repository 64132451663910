import { Close } from "@mui/icons-material";
import { IconButton, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { FC } from "react";
import styled from "styled-components";
import { useCart } from "../../../../../../../../../hooks/useCart";
import { useNavigate } from "react-router-dom";


const ImageThumbnail = styled.img`
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 5px;
`;


interface BasketFurnitureProps {
    id: string,
    name: string;
    image: string;
    type: 'furniture' | 'imported furniture',
}

const BasketFurniture: FC<BasketFurnitureProps> = ({
    id,
    name,
    image,
    type,
}) => {
    const cart = useCart()
    const navigate = useNavigate()


    const handleClickName = () => {
        if (type === 'furniture') {
            navigate(`/mebel/${id}`, { replace: true })
        }
    
        if (type === 'imported furniture') {
            navigate(`/mebel-importowany/${id}`, { replace: true })
        }
    };
    

    return (
        <ListItem sx={{ p: 1, userSelect: 'none' }}>
            <ListItemAvatar>
                <ImageThumbnail src={image} alt={name} />
            </ListItemAvatar>
            <ListItemText
                sx={{ marginLeft: 3, cursor: 'pointer' }}
                primary={name}
                onClick={handleClickName}
            />
            <IconButton color="error" onClick={() => cart.remove(id)}>
                <Close />
            </IconButton>
        </ListItem>
    )
}

export default BasketFurniture;