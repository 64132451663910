import { FC, useRef, useState } from "react";
import CustomInput from "../../_common/CustomInput";
import FormLayout from "../FormLayout";
import CustomButton from "../../_common/CustomButton";
import { Card, Divider } from "@mui/material";
import LoadingAnimation from "../../_common/Loading";
import furnitureController from "../../../Api/furnitureController";
import { useToast } from "../../../hooks/useToast";
import { useModal } from "../../../hooks/useModal";

interface EditNameFormProps {
    nameValue: string,
    furnitureId: string,
    onRefreshFurniture: () => void,
}

const EditNameForm: FC<EditNameFormProps> = ({
    nameValue,
    furnitureId,
    onRefreshFurniture,
}) => {
    const [name, setName] = useState<string>(nameValue || '');
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useRef(useToast()).current;
    const modal = useModal();

    const handleEditName = async () => {
        setLoading(true);
        const res = await furnitureController.editFurnitureName(furnitureId, name);
        setLoading(false);
        if (!res.ok) return toast.error(res.message);

        onRefreshFurniture();
        modal.close();
    }


    if (loading) return (
        <Card>
            <LoadingAnimation />
        </Card>
    )

    return (
        <FormLayout title="Edytuj nazwę">
            <CustomInput
                sx={{
                    width: '100%',
                }}
                mode="text"
                label="Nazwa"
                placeholder="Nazwa"
                value={name}
                setValue={setName}
                variant="filled"
            />
            <Divider sx={{ m: 1 }} />
            <CustomButton
                sx={{
                    width: '100%',
                }}
                text="Zapisz"
                onClick={() => handleEditName()}
            />
        </FormLayout>
    )
}

export default EditNameForm;