import { FC, useRef, useState } from "react";
import FormLayout from "../FormLayout";
import CustomInput from "../../_common/CustomInput";
import { Card, Divider } from "@mui/material";
import CustomButton from "../../_common/CustomButton";
import LoadingAnimation from "../../_common/Loading";
import { useModal } from "../../../hooks/useModal";
import { useToast } from "../../../hooks/useToast";
import furnitureController from "../../../Api/furnitureController";

interface EditPriceFormProps {
    priceType: 'price' | 'crossedPrice';
    value: number | null | undefined;
    furnitureId: string,
    onRefreshFurniture: () => void;
}

const EditPriceForm: FC<EditPriceFormProps> = ({
    priceType,
    value,
    furnitureId,
    onRefreshFurniture,
}) => {
    const [priceValue, setPriceValue] = useState<number>(value || 0);
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useRef(useToast()).current;
    const modal = useModal();

    const handleSavePrice = async () => {
        setLoading(true);
        const furnitureData = {
            furnitureId: furnitureId,
            priceType: priceType,
            value: priceValue,
        }
        const res = await furnitureController.editPrice(furnitureData)
        setLoading(false);
        if (!res.ok) return toast.error(res.message);
        onRefreshFurniture();
        modal.close();
    }


    if (loading) return (
        <Card>
            <LoadingAnimation />
        </Card>
    )

    return (
        <FormLayout
            title={
                priceType === 'price' ? 'Edytuj cenę' :
                priceType === 'crossedPrice' ? 'Edytuj przekreśloną cenę' : ''
            }
        >
            <CustomInput 
                sx={{
                    width: '100%',
                }}
                value={priceValue}
                setValue={setPriceValue}
                mode="numeric"
                label="Cena"
                variant="filled"
            />
            <Divider sx={{ m: 1 }} />
            <CustomButton
                sx={{
                    width: '100%',
                }}
                text="Zapisz"
                onClick={handleSavePrice}
            />
        </FormLayout>
    )
}

export default EditPriceForm;