import { FC, useCallback, useEffect, useRef, useState } from "react";
import FormLayout from "../FormLayout";
import { IProducer } from "../../../interfaces/IProducer";
import producersController from "../../../Api/producersController";
import { Card, ImageList } from "@mui/material";
import { getShowroomUrl } from "../../../services/getShowroomUrl";
import styled from "@emotion/styled";
import LoadingAnimation from "../../_common/Loading";
import furnitureController, { IEditProducerDto } from "../../../Api/furnitureController";
import { useToast } from "../../../hooks/useToast";
import { useModal } from "../../../hooks/useModal";

const BASE_URL = getShowroomUrl();


const ProducerImage = styled.img`
    width: 200px;
    height: 100px;
    object-fit: contain;
`

const ProducersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
`


interface EditProducerFormProps {
    furnitureId: string,
    producerId?: string;
    onRefreshFurniture: () => void;
}

const EditProducerForm: FC<EditProducerFormProps> = ({
    furnitureId,
    producerId,
    onRefreshFurniture,
}) => {
    const [producersList, setProducersList] = useState<IProducer[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const toast = useRef(useToast()).current;
    const modal = useModal();


    const handleEditProducer = async (producerId: string) => {
        setLoading(false)
        const furnitureData: IEditProducerDto = {
            producerId,
            furnitureId: furnitureId,
        }
        const res = await furnitureController.editProducer(furnitureData);
        setLoading(false);
        if (!res.ok) return toast.error(res.message);

        onRefreshFurniture();
        modal.close();
    }


    const getProducers = useCallback(async () => {
        const res = await producersController.getAllProducers();
        if (res.ok) {
            setProducersList(res.producers);
        }
        setLoading(false);
    }, [])

    useEffect(() => {
        getProducers();
    }, [getProducers])

    if (loading) return (
        <Card>
            <LoadingAnimation />
        </Card>
    )

    if (producersList === null) return (
        <FormLayout
            title="Błąd"
        >
            Błąd ładowania producentów
        </FormLayout>
    )

    return (
        <FormLayout
            title='Edycja producenta'
            maxWidth='xl'
        >
            <ImageList
                sx={{
                    pt: 4,
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                }}
            >
                <ProducersContainer style={{ userSelect: 'none' }}>
                    {producersList.map((producerItem) => (
                        <Card
                            key={producerItem._id}
                            sx={{
                                m: 1,
                                p: 2,
                                '&:hover': {
                                    transform: 'scale(1.15)',
                                },
                                transition: 'transform 0.2s, box-shadow 0.2s',
                                cursor: 'pointer',
                                boxShadow: (producerItem._id === producerId
                                    ?
                                    '0px 4px 20px rgba(0, 42, 255, 0.5)'
                                    :
                                    '0px 1px 3px rgba(0, 0, 0, 0.2)')
                            }}
                            onClick={() => handleEditProducer(producerItem._id)}
                        >
                            <ProducerImage
                                src={`${BASE_URL}${producerItem.logo}`}
                                alt={producerItem.name}
                            />
                        </Card>))
                    }
                </ProducersContainer>
            </ImageList>
        </FormLayout>
    )
}

export default EditProducerForm;