import { useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import ScrollToTop from './components/_common/ScrollToTop';
import './App.css';
import CookieComponent from './components/_common/CookieComponent';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import Furniture from './pages/Furniture/Furniture';
import Collections from './pages/Collections/Collections';
import Producers from './pages/Producers/Producers';
import Premises from './pages/Premises/Premises';
import Promotions from './pages/Promotions/Promotions';
import Contact from './pages/Contact/Contact';
import HomePage from './pages/HomePage/HomePage';
import PieceOfFurniture from './pages/PieceOfFurniture/PieceOfFurniture';
import SelectShowroomPage from './pages/SelectShowroomPage/SelectShowroomPage';
import SignIn from './pages/SignIn/SignIn';
import AdminPanel from './pages/_AdminPages/AdminPanel/AdminPanel';
import ImportedFurniture from './pages/ImportedFurniture/ImportedFurniture';
import SingleImportedFurniture from './pages/SingleImportedFurniture/SingleImportedFurniture';
import CartPage from './pages/CartPage/CartPage';
import { useCart } from './hooks/useCart';
import PromotionalPieceOfFurniture from './pages/PromotionalPieceOfFurniture/PromotionalPieceOfFurniture';
import { useUser } from './hooks/useUser';
import TransitionsModal from './components/_common/Modal';
// import Clarnet from './pages/_AdminPages/Clarnet/Clarnet';


const theme = createTheme({
  palette: {
    mode: 'light',
  },
});

function App() {

  const cart = useRef(useCart()).current;
  const user = useRef(useUser()).current;

  useEffect(() => {
    cart.get()
    user.autoLogin()
  }, [cart, user]);

  return (
    <ThemeProvider theme={theme}>
      <div className="generalView">

        <CookieComponent />
        <ToastContainer />

        <BrowserRouter>
          <ScrollToTop />
          <Routes>

            <Route
              path='/'
              element={<HomePage />}
            >
            </Route>

            <Route
              path='/meble'
              element={<Furniture />}
            />

            <Route
              path='/meble-importowane'
              element={<ImportedFurniture />}
            />

            <Route
              path='/kolekcje'
              element={<Collections />}
            />

            <Route
              path='/producenci'
              element={<Producers />}
            />

            <Route
              path='/pomieszczenia'
              element={<Premises />}
            />

            <Route
              path='/promocje'
              element={<Promotions />}
            />

            <Route
              path='/kontakt'
              element={<Contact />}
            />

            <Route
              path='/adminPanel'
              element={<AdminPanel />}
            />

            <Route
              path='/mebel/:furnitureId'
              element={<PieceOfFurniture />}
            />

            <Route
              path='/mebel-promocyjny/:furnitureId'
              element={<PromotionalPieceOfFurniture />}
            />

            <Route
              path='mebel-importowany/:symbol'
              element={<SingleImportedFurniture />}
            />

            <Route
              path='/wybierz-salon'
              element={<SelectShowroomPage />}
            />

            <Route
              path='/logowanie'
              element={<SignIn />}
            />

            <Route
              path='/koszyk'
              element={<CartPage />}
            />

            {/* <Route
              path='/clarnet'
              element={<Clarnet />}
            /> */}

            <Route path='*' element={<NotFoundPage />} />

          </Routes>
        </BrowserRouter>

        <TransitionsModal />

      </div>
    </ThemeProvider>
  );
}

export default App;
