import styles from './furniture.module.scss';
import { useState, useEffect, FC } from 'react';
import { useLocation } from 'react-router-dom';
import { premisesData } from '../../pages/Premises/premisesData';
import { getShowroomUrl } from '../../services/getShowroomUrl';
import Lottie from 'lottie-react'

import loadingAnimation from '../../assets/animations/Morphing.json';

import SalesWindow from '../../components/_common/SalesWindow/SalesWindow';
import Categories from '../../components/_common/Categories/Categories';

import furnitureController from '../../Api/furnitureController';
import producersController from '../../Api/producersController';
import collectionsController from '../../Api/collectionsController';
import { IFurniture } from '../../interfaces/IFurniture';
import { IProducer } from '../../interfaces/IProducer';
import { ICollection } from '../../interfaces/ICollection';
import Layout from '../../components/layout/Layout';
import { categoryData } from './categoryData';
import Searchbar from '../../components/_common/Searchbar';
import { useUser } from '../../hooks/useUser';
import { Box, Button } from '@mui/material';
import { useModal } from '../../hooks/useModal';
import AddFurnitureForm from '../../components/forms/furniture/AddFurnitureForm/AddFurnitureForm';

const BASE_URL = getShowroomUrl();


interface FurnitureProps {

}


const Furniture: FC<FurnitureProps> = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [furnitureData, setFurnitureData] = useState<IFurniture[] | null>(null);
    const [allFurnituresData, setAllFurnituresData] = useState<IFurniture[]>([]);
    const [producersData, setProducersData] = useState<IProducer[] | null>(null);
    const [collectionsData, setCollectionsData] = useState<ICollection[] | null>([]);
    const [collectionsToDisplay, setCollectionsToDisplay] = useState<ICollection[] | null>(null)
    const [producerFilter, setProducerFilter] = useState<string | null>(null);
    const [collectionFilter, setCollectionFilter] = useState<string | null>(null);
    const [premiseFilter, setPremiseFilter] = useState<string | null>(null);
    const [categoryFilter, setCategoryFilter] = useState<string>('')
    const [showPerPage, setShowPerPage] = useState<number>(1);
    const [searchValue, setSearchValue] = useState<string>('');
    const [showMobileFilters, setShowMobileFilters] = useState<boolean>(false);
    const user = useUser()
    const modal = useModal()


    const getAllFurniture = async () => {
        setLoading(true);
        const res = await furnitureController.getAllFurniture();

        if (!res.ok || !res.furniture) return setLoading(false);

        setAllFurnituresData(res.furniture);
        setLoading(false)
    }


    const search = (allFurnituresData: IFurniture[]) => {

        if (searchValue !== '') changeUrlParams('wyszukaj', searchValue);

        const newFilteredFurniturePack = [];

        for (const singleFurniture of allFurnituresData) {

            let displayFurniture = true;

            if (!singleFurniture.name.toLowerCase().includes(searchValue.toLowerCase()) && searchValue !== '') displayFurniture = false;
            if (singleFurniture.producer !== producerFilter && producerFilter !== '') displayFurniture = false;
            if (singleFurniture.partCollection !== collectionFilter && collectionFilter !== '') displayFurniture = false;

            if (premiseFilter) {
                if (singleFurniture.premises) {
                    if (!singleFurniture.premises.some(premise => premise.toLowerCase() === premiseFilter.toLowerCase())) displayFurniture = false;
                } else {
                    displayFurniture = false;
                }
            }

            if (categoryFilter) {
                if (singleFurniture.furnitureCategories) {
                    if (!singleFurniture.furnitureCategories.includes(categoryFilter)) displayFurniture = false;
                } else {
                    displayFurniture = false;
                }
            }

            displayFurniture && newFilteredFurniturePack.push(singleFurniture);
        }

        setFurnitureData(newFilteredFurniturePack);
    }


    const showPerPageHandler = () => {
        document.documentElement.scrollHeight - window.innerHeight <= window.scrollY + 1000 && setShowPerPage(showPerPage + 1);
    }

    window.addEventListener('scroll', showPerPageHandler)


    const getAllProducers = async () => {
        const res = await producersController.getAllProducers();
        setProducersData(res.producers);
    }

    const getAllCollections = async () => {
        const res = await collectionsController.getAllCollections();
        setCollectionsData(res.collections);
    }

    const setProducerFilterHandler = (producerIdValue: string) => {
        const producerId = producerIdValue;
        setProducerFilter(producerId);
        changeUrlParams('producerId', producerId);
        setCollectionFilter('')
    }

    const setCollectionFilterHandler = (collectionIdValue: string) => {
        const collectionId = collectionIdValue;
        setCollectionFilter(collectionId);
        changeUrlParams('collectionId', collectionId);
    }

    const setPremiseFilterHandler = (premiseNameValue: string) => {
        const premiseName = premiseNameValue;
        setPremiseFilter(premiseName);
        changeUrlParams('premiseName', premiseName);
    }

    const setCategoryHandler = (categoryValue: string) => {
        setCategoryFilter(categoryValue);
        changeUrlParams('category', categoryValue);
    }

    const resetFilters = () => {
        setProducerFilter('');
        setCollectionFilter('');
        setPremiseFilter('');
        changeUrlParams('producerId', '');
        changeUrlParams('collectionId', '');
        changeUrlParams('premiseName', '');
    }

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();

    const checkURLSearchParams = () => {
        query.get('producerId') ? setProducerFilter(query.get('producerId')) : setProducerFilter('')
        query.get('collectionId') ? setCollectionFilter(query.get('collectionId')) : setCollectionFilter('')
        query.get('premiseName') ? setPremiseFilter(query.get('premiseName')) : setPremiseFilter('')

        const categoryParam = query.get('category')
        categoryParam ? setCategoryFilter(categoryParam) : setCategoryFilter('')

        const searchParam = query.get('wyszukaj')
        searchParam && setSearchValue(searchParam)

        const showPerPageParam = query.get('showPerPage')
        showPerPageParam ? setShowPerPage(+showPerPageParam) : setShowPerPage(1)
    }


    const changeUrlParams = (name: string, value: string) => {
        const url = new URL(window.location.href);
        url.searchParams.set(`${name}`, value);
        window.history.pushState(null, '', url.toString());
    }


    const setCollectionsToDisplayHandler = () => {
        if (!collectionsData) return
        const collections = [...collectionsData]
        const collectionsToDisplay = collections.filter(x => x.producer === producerFilter)
        setCollectionsToDisplay(collectionsToDisplay);
    }


    useEffect(() => {
        getAllFurniture();
    }, [])

    useEffect(() => {
        checkURLSearchParams();
        getAllProducers();
        getAllCollections();
    }, [])

    useEffect(() => {
        allFurnituresData && search(allFurnituresData);
        setCollectionsToDisplayHandler()
    }, [producerFilter, collectionFilter, premiseFilter, allFurnituresData, categoryFilter, searchValue])


    const renderFurniture = () => {
        if (loading) return (
            <Lottie
                style={{
                    maxWidth: '250px',
                    maxHeight: '250px',
                }}
                animationData={loadingAnimation}
                loop={true}
            />
        )

        if (!furnitureData || !Array.isArray(furnitureData)) {
            return (
                <div
                    style={{
                        marginTop: '90px'
                    }}
                >
                    Brak mebli o podanych parametrach
                </div>
            )
        }

        return (
            furnitureData.map((furniture, index) => (
                <div key={index}>
                    {showPerPage * 20 >= index - 1 && (
                        <SalesWindow
                            type='furniture'
                            key={index}
                            link={`mebel/${furniture._id}`}
                            id={furniture._id}
                            name={furniture.name}
                            width={furniture.width || 0}
                            depth={furniture.depth || 0}
                            height={furniture.height || 0}
                            price={furniture.price || 0}
                            crossed={furniture.crossed || 0}
                            isPriceVissible={furniture.isPriceVissible}
                            image={`${BASE_URL}${furniture.thumbnailImagesPaths[0]}`}
                        />
                    )
                    }
                </div>
            ))
        )
    }


    return (
        <Layout
            onHandleClickSearchIcon={() => setShowMobileFilters(!showMobileFilters)}
            title='Meble'
        >
            <div className={styles.general}>
                <div className={styles.categoriesContainer}>
                    <Categories
                        searchValue={searchValue}
                        categoryData={categoryData}
                        categoryFilter={categoryFilter}
                        showMobileFilters={showMobileFilters}
                        onSetShowMobileFilters={(show: boolean) => setShowMobileFilters(show)}
                        onSetCategoryHandler={(categoryValue) => setCategoryHandler(categoryValue)}
                        onSetSearchValueHandler={(searchValue: string) => setSearchValue(searchValue)}
                        producers={producersData}
                        producerFilter={producerFilter}
                        onSetProducersHandler={(producerValue: string) => setProducerFilterHandler(producerValue)}
                        collections={collectionsToDisplay}
                        collectionFilter={collectionFilter}
                        onSetCollectionsHandler={(collectionValue: string) => setCollectionFilterHandler(collectionValue)}
                        premises={premisesData}
                        premiseFilter={premiseFilter}
                        onSetPremisesHandler={(premisesValue: string) => setPremiseFilterHandler(premisesValue)}
                    />
                </div>
                <div>
                    <Searchbar
                        searchValue={searchValue}
                        updateSearchValueHandler={(newSearchValue: string) => setSearchValue(newSearchValue)}
                    />
                    <div className={styles.filters}>
                        <select
                            className={styles.select}
                            value={producerFilter || ''}
                            onChange={(e) => {
                                const producerIdValue = e.target.value;
                                setProducerFilterHandler(producerIdValue);
                            }}
                        >
                            <option value=''>Dowolny producent</option>
                            {
                                !producersData
                                    || producersData.length === 0
                                    || !Array.isArray(producersData)
                                    ?
                                    (
                                        null
                                    ) : (
                                        producersData.map((producer, index) => {
                                            return (
                                                <option
                                                    className={styles.sortOption}
                                                    key={index}
                                                    value={producer._id}
                                                >
                                                    {producer.name}
                                                </option>
                                            )
                                        })
                                    )
                            }
                        </select>
                        <select
                            className={styles.select}
                            value={collectionFilter || ''}
                            onChange={(e) => {
                                const collectionIdValue = e.target.value;
                                setCollectionFilterHandler(collectionIdValue);
                            }}
                            disabled={(!producerFilter || collectionsToDisplay?.length === 0) && true}
                        >
                            <option value='' >Dowolna kolekcja</option>
                            {
                                !collectionsToDisplay || collectionsToDisplay.length === 0 || !Array.isArray(collectionsToDisplay) ? (
                                    null
                                ) : (
                                    collectionsToDisplay.map((collection, index) => {
                                        return (
                                            <option
                                                className={styles.sortOption}
                                                key={index}
                                                value={collection._id}
                                            >
                                                {collection.name}
                                            </option>
                                        )
                                    })
                                )
                            }
                        </select>
                        <select
                            className={styles.select}
                            value={premiseFilter || ''}
                            onChange={(e) => {
                                const premiseFilter = e.target.value;
                                setPremiseFilterHandler(premiseFilter);
                            }}
                        >
                            <option value='' >Dowolne pomieszczenie</option>
                            {
                                !premisesData || premisesData.length === 0 || !Array.isArray(premisesData) ? (
                                    null
                                ) : (
                                    premisesData.map((premise, index) => {
                                        return (
                                            <option
                                                className={styles.sortOption}
                                                key={index}
                                                value={premise.name}
                                            >
                                                {premise.name}
                                            </option>
                                        )
                                    })
                                )
                            }
                        </select>
                        {
                            producerFilter || collectionFilter || premiseFilter
                                ? <button className={styles.filterButton} onClick={resetFilters}>Resetuj filtry</button>
                                : ''
                        }

                    </div>
                    {(user.isAuthMinsk || user.isAuthPiaseczno) &&
                        <Box sx={{ display: 'flex', justifyContent: 'center', m: 2 }}>
                            <Button
                                color='secondary'
                                variant='contained'
                                onClick={() => modal.open(
                                    <AddFurnitureForm />
                                )}
                            >
                                Dodaj mebel
                            </Button>
                        </Box>
                    }
                    <div className={styles.furnitureContainer}>
                        {renderFurniture()}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Furniture;