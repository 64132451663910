import { Close } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { FC } from "react";
import styled from "styled-components";


const CategoryButtonRemove = styled(Box)`
    display: flex;
    justify-content: center;
    margin-top: 10px;
    padding-left: 20px;
`


interface RemoveFilterButtonProps {
    label: string;
    filterName: string;
    onClickHandler: () => void;
}

const RemoveFilterButton: FC<RemoveFilterButtonProps> = ({
    label,
    filterName,
    onClickHandler,
}) => {


    const handleClick = () => {
        onClickHandler();
    }


    return (
        <CategoryButtonRemove>
            <Button
                variant="outlined"
                color="error"
                onClick={handleClick}
            >
                <Close />
                {label}
                {filterName}
            </Button>
        </CategoryButtonRemove>
    )
}

export default RemoveFilterButton;