import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { FC } from "react";

interface CustomDialogProps {
    open: boolean;
    onSetOpen: (state: boolean) => void;
    title: string;
    description: string;
    onAgree: () => void;
    onDisagree?: () => void;
    agreeText?: string;
    disagreeText?: string;
    sx?: any;
}

const CustomDialog: FC<CustomDialogProps> = ({
    sx,
    open,
    title,
    agreeText,
    description,
    disagreeText,
    onAgree,
    onSetOpen,
    onDisagree,
}) => {
    const handleClose = () => {
        onSetOpen(false);
    };

    const handleClickDisagree = () => {
        if (onDisagree) onDisagree();
        handleClose();
    }

    const handleClickAgree = () => {
        onAgree();
        handleClose();
    }

    return (
        <Dialog
            sx={sx}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={handleClickDisagree}
                >
                    {disagreeText || 'Anuluj'}
                </Button>
                <Button
                    color='error'
                    onClick={handleClickAgree}
                    autoFocus
                >
                    {agreeText || 'Tak'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CustomDialog;