import { FC } from "react";
import styled from "@emotion/styled";
import { Card } from "@mui/material";
import ImageComponent from "./components/ImageComponenet";
import DetailsComponent from "./components/DetailsComponent";
import DescriptionComponent from "./components/DescriptionComponent";
import FeaturesComponent from "./components/FeaturesComponent";


const Container = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 50px;
    width: 100%;

    @media screen and (max-width: 940px){
        display: block;
    }
`

const LeftCard = styled(Card)`
    width: 600px;
    max-width: calc(100% - 40px);
    padding: 20px;

    @media screen and (max-width: 940px){
        width: calc(100% - 40px);
    }
`

const DetailsCard = styled(Card)`
    width: 310px;
    height: 100%;
    padding: 20px;
    margin-left: 10px;
    background-color: #f5f5f5;
    
    @media screen and (min-height: 570px){
        position: sticky;
        top: 80px;
    }

    @media screen and (max-width: 940px){
        margin-top: 30px;
        width: calc(100% - 40px);
        margin-left: 0px;
    }
`


interface FurnitureItemProps {
    symbol: string,
    name: string,
    desc?: string,
    photos: any[],
    width?: number | null,
    height ?: number | null,
    depth ?: number | null,
    image: string,
    features?: string[],
}

const FurnitureItem: FC<FurnitureItemProps> = ({
    symbol,
    name,
    desc,
    photos,
    width,
    height,
    depth,
    image,
    features,
}) => {


    return (
        <Container>
            <div>
                <LeftCard>
                    <ImageComponent
                        photos={photos}
                    />
                </LeftCard>
                {
                    desc &&
                    <LeftCard sx={{ mt: 2 }}>
                        <DescriptionComponent
                            description={desc}
                        />
                    </LeftCard>
                }
                {
                    features &&
                    <LeftCard sx={{ mt: 2 }}>
                        <FeaturesComponent
                            features={features}
                        />
                    </LeftCard>
                }
            </div>
            <DetailsCard>
                <DetailsComponent
                    id={symbol}
                    name={name}
                    width={width}
                    height={height}
                    depth={depth}
                    image={image}
                />
            </DetailsCard>
        </Container>
    )
}

export default FurnitureItem;