import { Box, List, SwipeableDrawer } from "@mui/material";
import { FC, useState } from "react";
import { ICategory } from "../../../../../interfaces/ICategory";
import RemoveFilterButton from "./components/RemoveFilterButton";
import SearchbarComponent from "./components/Searchbar";
import CategoryComponent from "./components/CategoryComponent";
import { IProducer } from "../../../../../interfaces/IProducer";
import { ICollection } from "../../../../../interfaces/ICollection";
import { IPremise } from "../../../../../interfaces/IPremise";
import ProducersComponent from "./components/ProducersComponent";
import PremisesComponent from "./components/PremisesComponent";
import CollectionsComponent from "./components/CollectionsComponent";


interface MobileFiltersProps {
    searchValue: string,
    categories: ICategory[];
    categoryFilter: string;
    showMobileFilters: boolean;
    onSetShowMobileFilters: (show: boolean) => void;
    onSetCategoryHandler: (categoryValue: string) => void;
    onSetSearchValueHandler: (searchValue: string) => void;
    producers?: IProducer[] | null;
    producerFilter?: string | null;
    onSetProducersHandler?: (producerValue: string) => void;
    collections?: ICollection[] | null;
    collectionFilter?: string | null;
    onSetCollectionsHandler?: (collectionValue: string) => void;
    premises?: IPremise[] | null;
    premiseFilter?: string | null;
    onSetPremisesHandler?: (premisesValue: string) => void;
}

const MobileFilters: FC<MobileFiltersProps> = ({
    categories,
    categoryFilter,
    onSetCategoryHandler,
    showMobileFilters,
    onSetShowMobileFilters,
    searchValue,
    onSetSearchValueHandler,
    producers,
    producerFilter,
    onSetProducersHandler,
    collections,
    collectionFilter,
    onSetCollectionsHandler,
    premises,
    premiseFilter,
    onSetPremisesHandler,
}) => {
    const [showCategories, setShowCategories] = useState<boolean>(false);
    const [newSearchValue, setNewSearchValue] = useState<string>(searchValue || '');
    const [showProducers, setShowProducers] = useState<boolean>(false);
    const [showCollections, setShowCollections] = useState<boolean>(false);
    const [showPremises, setShowPremises] = useState<boolean>(false);


    const closeAllFilters = () => {
        setShowCategories(false);
        setShowProducers(false);
        setShowCollections(false);
        setShowPremises(false);
    }


    const handleClickCategory = (categoryName: string) => () => {
        onSetCategoryHandler(categoryName);
        onSetShowMobileFilters(false);
        closeAllFilters();
        onSetSearchValueHandler(newSearchValue)
    }


    const handleRemove = (setFunction?: (value: string) => void, removeNewSearchValue?: boolean) => {
        if (!setFunction) return;
        setFunction('');
        if (removeNewSearchValue) setNewSearchValue('');
    }

    const handleClickRemoveCategoryButton = () => handleRemove(onSetCategoryHandler);
    const handleClickRemoveSearchValueButton = () => handleRemove(onSetSearchValueHandler, true);
    const handleClickRemoveProducerButton = () => handleRemove(onSetProducersHandler);
    const handleClickRemoveCollectionButton = () => handleRemove(onSetCollectionsHandler);
    const handleClickRemovePremiseButton = () => handleRemove(onSetPremisesHandler);


    const handleSearch = (searchValue: string) => {
        onSetSearchValueHandler(searchValue);
        onSetShowMobileFilters(false);
    }


    const handleClickProducer = (producerName: string) => () => {
        if (!onSetProducersHandler) return
        onSetProducersHandler(producerName);
        onSetSearchValueHandler(newSearchValue)
        closeAllFilters();
    }


    const handleClickCollection = (collectionName: string) => () => {
        if (!onSetCollectionsHandler) return
        onSetCollectionsHandler(collectionName);
        onSetShowMobileFilters(false);
        onSetSearchValueHandler(newSearchValue)
        closeAllFilters();
    }


    const handleClickPremise = (premiseName: string) => () => {
        if (!onSetPremisesHandler) return
        onSetPremisesHandler(premiseName);
        onSetShowMobileFilters(false);
        onSetSearchValueHandler(newSearchValue)
        closeAllFilters();
    }


    if (!showMobileFilters) return null;

    return (
        <SwipeableDrawer
            anchor="right"
            open={showMobileFilters}
            onClose={() => onSetShowMobileFilters(false)}
            onOpen={() => onSetShowMobileFilters(true)}
        >
            <Box sx={{ width: '85vw', paddingRight: 2 }}>

                <List>
                    <SearchbarComponent
                        newSearchValue={newSearchValue}
                        onSetNewSearchValue={setNewSearchValue}
                        onHandleSearch={handleSearch}
                    />
                    {searchValue &&
                        <RemoveFilterButton
                            label='Szukaj: '
                            onClickHandler={handleClickRemoveSearchValueButton}
                            filterName={searchValue}
                        />
                    }
                    {categoryFilter &&
                        <RemoveFilterButton
                            label="Kategorie: "
                            onClickHandler={handleClickRemoveCategoryButton}
                            filterName={categoryFilter}
                        />
                    }
                    {producerFilter &&
                        <RemoveFilterButton
                            label="Producent: "
                            onClickHandler={handleClickRemoveProducerButton}
                            filterName={producers?.find(producer => producer._id === producerFilter)?.name || ''}
                        />
                    }
                    {collectionFilter &&
                        <RemoveFilterButton
                            label="Kolekcja: "
                            onClickHandler={handleClickRemoveCollectionButton}
                            filterName={collections?.find(collection => collection._id === collectionFilter)?.name || ''}
                        />
                    }
                    {premiseFilter &&
                        <RemoveFilterButton
                            label="Pomieszczenie: "
                            onClickHandler={handleClickRemovePremiseButton}
                            filterName={premiseFilter}
                        />
                    }
                    <CategoryComponent
                        showCategories={showCategories}
                        onSetShowCategories={setShowCategories}
                        categories={categories}
                        categoryFilter={categoryFilter}
                        onHandleClickCategory={handleClickCategory}
                    />
                    {producers && <ProducersComponent
                        showProducers={showProducers}
                        onSetShowProducers={setShowProducers}
                        producers={producers}
                        producerFilter={producerFilter}
                        onHandleClickProducer={handleClickProducer}
                    />}
                    {collections && <CollectionsComponent
                        showCollections={showCollections}
                        onSetShowCollections={setShowCollections}
                        collections={collections}
                        collectionFilter={collectionFilter}
                        onHandleClickCollection={handleClickCollection}
                        producerFilter={producerFilter}
                    />
                    }
                    {premises && <PremisesComponent
                        showPremises={showPremises}
                        onSetShowPremises={setShowPremises}
                        premises={premises}
                        premiseFilter={premiseFilter}
                        onHandleClickPremise={handleClickPremise}
                    />}
                </List>
            </Box>
        </SwipeableDrawer>
    )
}

export default MobileFilters;