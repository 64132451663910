

export default function NotFoundPage() {

    return (
        <div style={{
            padding: 20,
        }}>
            <h2>
                404 - Nie znaleziono
            </h2>
        </div>
    )
}