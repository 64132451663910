import styled from "@emotion/styled";
import { FC, useState } from "react";
import { PieChart, Pie, Sector } from "recharts";


const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Wejścia na stronę ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};


const PieChartContainer = styled.div`
    display: flex;
    justify-content: center;
`


interface PieChartComponentProps {
    data: any;
    yearToDisplay: number | null;
}

const PieChartComponent: FC<PieChartComponentProps> = ({
    data,
    yearToDisplay,
}) => {
    const [activeIndex, setActiveIndex] = useState<number>(0)


    const onPieEnter = (data: any, index: number) => {
        setActiveIndex(index)
    }


    if (!yearToDisplay) return (<></>)

    return (
        <PieChartContainer>
            <PieChart width={1000} height={420}>
                <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={data[yearToDisplay]}
                    cx="50%"
                    cy="50%"
                    innerRadius={100}
                    outerRadius={160}
                    fill="#283890"
                    dataKey="pv"
                    name="Wejścia na stronę"
                    onMouseEnter={onPieEnter}
                />
            </PieChart>
        </PieChartContainer>
    )
}

export default PieChartComponent;