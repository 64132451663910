import styled from "@emotion/styled";
import { Box, Card, CardActions, CardHeader, CardMedia, Collapse, IconButton, List } from "@mui/material";
import { FC, useRef } from "react";
import { TransitionGroup } from 'react-transition-group';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const DropZone = styled.div`
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
`;

interface ImagesFormProps {
    images: any[];
    onSetImages: (images: any) => void;
}

const ImagesForm: FC<ImagesFormProps> = ({
    images,
    onSetImages,
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleDrop = (e: any) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files) as File[];
        onSetImages((prevState: any) => [...prevState, ...files]);
    };

    const handleDragOver = (e: any) => {
        e.preventDefault();
    };

    const handleFileSelect = (e: any) => {
        const files = Array.from(e.target.files) as File[];
        onSetImages((prevState: File[]) => [...prevState, ...files]);
    };

    const handleRemoveImage = (index: number) => {
        onSetImages((prevState: File[]) => prevState.filter((_, i) => i !== index));
    };

    const handleMoveImageUp = (index: number) => {
        if (index > 0) {
            onSetImages((prevState: File[]) => {
                const newImages = [...prevState];
                [newImages[index], newImages[index - 1]] = [newImages[index - 1], newImages[index]];
                return newImages;
            });
        }
    };

    const handleMoveImageDown = (index: number) => {
        if (index < images.length - 1) {
            onSetImages((prevState: File[]) => {
                const newImages = [...prevState];
                [newImages[index], newImages[index + 1]] = [newImages[index + 1], newImages[index]];
                return newImages;
            });
        }
    };

    const renderItem = ({ item, index, mainImage }: { item: any, index: number, mainImage: boolean }) => (
        <Card sx={{ maxWidth: 345, mb: 1, pt: mainImage ? 0 : 1 }} >
            {mainImage &&
                <CardHeader
                    sx={{ textAlign: 'center' }}
                    subheader='Zdjęcie główne'
                />
            }
            <CardMedia
                component="img"
                height="140"
                image={URL.createObjectURL(item)}
                sx={{ objectFit: 'contain' }}
            />
            <CardActions sx={{ justifyContent: 'center' }}>
                <IconButton onClick={() => handleMoveImageUp(index)} color="primary">
                    <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                    onClick={() => handleRemoveImage(index)} color="secondary"
                >
                    <DeleteIcon />
                </IconButton>
                <IconButton onClick={() => handleMoveImageDown(index)} color="primary">
                    <ArrowDownwardIcon />
                </IconButton>
            </CardActions>
        </Card>
    );

    return (
        <Box>
            <List sx={{ mt: 1 }}>
                <TransitionGroup>
                    {images.map((item, index) => (
                        <Collapse key={item.order}>
                            {renderItem({ item, index, mainImage: index === 0 })}
                        </Collapse>
                    ))}
                </TransitionGroup>
            </List>
            <DropZone
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={() => fileInputRef.current?.click()}
            >
                Przeciągnij zdjęcia tutaj lub kliknij
            </DropZone>
            <input
                type="file"
                multiple
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileSelect}
            />
        </Box>
    )
}

export default ImagesForm;