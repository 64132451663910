import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FC, useState } from 'react';
import { navigationButtons } from '../navigationButtons';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import homeIcon from '../../../../../assets/img/mobileMenuIcons/home.svg';
import logo from '../../../../../assets/img/ufo.png';
import menuIcon from '../../../../../assets/img/list.svg';
import searchIcon from '../../../../../assets/img/search.svg'
import { Badge } from '@mui/material';
import { ShoppingCart } from '@mui/icons-material';
import { useGlobalState } from '../../../../../context/context';
import ShowroomDialog from '../../../../_common/ShowroomDialog';

const MobileNavigationContainer = styled.div`
    @media (min-width: 941px) {
        display: none;
    }
`;

const NavigationIcon = styled('img')(() => ({
    width: '45px',
    height: '45px',
    marginRight: '10px',
}));

const NavigationLink = styled(Link)`
    text-decoration: none;
    color: black;
`;

const SmallLogo = styled('img')(() => ({
    width: '70px',
    marginLeft: '10px',
}));

const LargeLogo = styled('img')(() => ({
    width: '100px',
    marginLeft: '10px',
}));

const FirstLane = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    height: '60px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
    zIndex: 1000,
    position: 'fixed',
    width: '100%',
    top: 0,

    '@media (max-height: 450px)': {
        display: 'none',
    },
}));

const MenuIcon = styled('img')(() => ({
    width: '30px',
    height: '30px',
    position: 'absolute',
    left: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
}));

const SearchIcon = styled('img')(() => ({
    width: '24px',
    height: '24px',
    position: 'absolute',
    right: '15px',
    top: '50%',
    transform: 'translateY(-50%)',
}));

const NavigationBar = styled('img')(() => ({
    display: 'none',
    width: '30px',
    height: '30px',
    position: 'fixed',
    left: '10px',
    top: 30,
    transform: 'translateY(-50%)',
    backgroundColor: 'white',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
    padding: '10px',
    borderRadius: '50%',

    '@media (max-height: 450px)': {
        display: 'block',
    },
}));

const MobileMenuItem = styled(ListItem)`
    background-color: #f1f1f1;
    border-radius: 10px;
    margin: 7px 10px 7px 10px;
    padding: 15px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e1e1e1;
    }
`

const TopContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10px',
}));

const ShowroomNamesContainer = styled('div')`
    text-align: center;
    margin-left: 5px;
`

const SelectedShowroom = styled('div')`
    font-weight: bold;
`

const UnselectedShowroom = styled('div')`
    font-weight: normal;
    color: grey;
`


interface MobileNavigationProps {
    onHandleClickSearchIcon?: () => void;
}

const MobileNavigation: FC<MobileNavigationProps> = ({
    onHandleClickSearchIcon,
}) => {
    const [showMenu, setShowMenu] = useState(false);
    const [showShowroomAlert, setShowShowroomAlert] = useState(false);
    const { state } = useGlobalState();
    const cartItems = state.basketFurniture;

    const selectedShowroom = localStorage.getItem('selectedShowroom');


    const handleSetShowroom = (showroom: string) => () => {
        localStorage.setItem('selectedShowroom', showroom);
        setShowShowroomAlert(false);
        window.location.reload();
    }


    return (
        <MobileNavigationContainer>
            <NavigationBar
                onClick={() => setShowMenu(true)}
                src={menuIcon}
                alt="menu"
            />
            <FirstLane>
                <MenuIcon onClick={() => setShowMenu(!showMenu)} src={menuIcon} alt="menu" />
                <Link to='/'>
                    <SmallLogo src={logo} alt='logo' />
                </Link>
                {onHandleClickSearchIcon && <SearchIcon onClick={onHandleClickSearchIcon} src={searchIcon} />}
            </FirstLane>
            <SwipeableDrawer
                anchor={'left'}
                open={showMenu}
                onClose={() => setShowMenu(false)}
                onOpen={() => setShowMenu(true)}
            >
                <Box
                    sx={{ width: 320, paddingRight: 2 }}
                    onClick={() => setShowMenu(false)}
                >
                    <TopContainer>
                        <LargeLogo src={logo} alt='logo' />
                        <div onClick={() => setShowShowroomAlert(true)}>
                            <ShowroomNamesContainer>
                                <SelectedShowroom>{
                                    selectedShowroom === 'minsk' ?
                                        'Mińsk Mazowiecki' :
                                        'Piaseczno'
                                }</SelectedShowroom>
                                <UnselectedShowroom>{
                                    selectedShowroom === 'minsk' ?
                                        'Piaseczno' :
                                        'Mińsk Mazowiecki'
                                }</UnselectedShowroom>
                            </ShowroomNamesContainer>
                        </div>
                    </TopContainer>
                    <ShowroomDialog
                        open={showShowroomAlert}
                        onClose={() => setShowShowroomAlert(false)}
                        selectedValue={selectedShowroom}
                        buttons={[
                            {
                                name: 'Mińsk Mazowiecki',
                                onClick: handleSetShowroom('minsk'),
                                isActived: selectedShowroom === 'minsk',
                            },
                            {
                                name: 'Piaseczno',
                                onClick: handleSetShowroom('piaseczno'),
                                isActived: selectedShowroom === 'piaseczno',
                            },
                        ]}
                    />
                    <List>
                        <NavigationLink key='cart' to='/koszyk'>
                            <MobileMenuItem disablePadding>
                                <ListItemIcon sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Badge badgeContent={cartItems?.length || 0} color="secondary">
                                        <ShoppingCart fontSize='large' />
                                    </Badge>
                                </ListItemIcon>
                                <ListItemText primary={'Przejdź do koszyka'} />
                            </MobileMenuItem>
                        </NavigationLink>
                        <NavigationLink key='home' to='/'>
                            <MobileMenuItem disablePadding>
                                <ListItemIcon>
                                    <NavigationIcon src={homeIcon} alt='menu' />
                                </ListItemIcon>
                                <ListItemText primary={'Strona główna'} />
                            </MobileMenuItem>
                        </NavigationLink>
                        {navigationButtons?.map((button) => (
                            <NavigationLink key={button.url} to={button.url}>
                                <MobileMenuItem disablePadding>
                                    <ListItemIcon>
                                        <NavigationIcon src={button.mobileIcon} alt={button.name} />
                                    </ListItemIcon>
                                    <ListItemText primary={button.name} />
                                </MobileMenuItem>
                            </NavigationLink>
                        ))}
                    </List>
                </Box>
            </SwipeableDrawer>
        </MobileNavigationContainer>
    );
};

export default MobileNavigation;