import styled from "@emotion/styled";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide, Typography, useTheme, useMediaQuery } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { FC, forwardRef } from "react";


const CursiveText = styled(Typography)`
    font-style: italic;
    text-align: center;
`

const TermHeader = styled(Typography)`
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
`

const OrderedList = styled.ol`
    padding: 0;
    padding-left: 10px;
`

const SubOrderedList = styled.ol`
    padding: 0;
    padding-left: 20px;
`

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface RegulationsAlertProps {
    open: boolean;
    handleSetOpen: (value: boolean) => void;
    handleAgree: () => void;
    handleDisagree: () => void;
}

const RegulationsAlert: FC<RegulationsAlertProps> = ({
    open,
    handleSetOpen,
    handleAgree,
    handleDisagree,
}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    const handleClose = () => {
        handleSetOpen(false);
    };

    const handleClickDisagree = () => {
        handleSetOpen(false);
        handleDisagree();
    }

    const handleClickAgree = () => {
        handleSetOpen(false);
        handleAgree();
    }

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth="md"
                fullScreen={fullScreen}
                sx={{ p: 1 }}
            >
                <DialogTitle
                    sx={{ textAlign: 'center', fontWeight: 'bold', pb: 0 }}
                >
                    REGULAMIN
                </DialogTitle>
                <DialogTitle
                    sx={{ textAlign: 'center', fontSize: '', fontWeight: 'bold', p: 1 }}
                >
                    sprzedaży produktów oferowanych przez spółkę UFO spółka z ograniczoną odpowiedzialnością z siedzibą w
                    Warszawie
                </DialogTitle>
                <DialogContent>
                    <CursiveText>
                        z dnia 8. sierpnia 2024 roku
                    </CursiveText>
                    <CursiveText sx={{ pt: 2 }}>
                        § 1.
                    </CursiveText>
                    <TermHeader>
                        Postanowienia wstępne
                    </TermHeader>
                    <DialogContentText id="alert-dialog-slide-description">
                        <OrderedList>
                            <li>Regulamin obowiązuje wszystkich Kupujących będących konsumentami.</li>
                            <li>Definicje:
                                <SubOrderedList style={{ listStyleType: 'lower-alpha' }}>
                                    <li>
                                        <strong>Formularz kontaktowy </strong>
                                        – formularz elektroniczny dostępny na stronie internetowej Sprzedawcy, umożliwiający
                                        przesłanie zapytania dotyczącego wyceny produktów;
                                    </li>
                                    <li>
                                        <strong>Kodeks cywilny </strong>
                                        – ustawa z dnia 23 kwietnia 1964 roku - Kodeks cywilny (t.j. Dz. U. z 2024 r. poz. 1061);
                                    </li>
                                    <li>
                                        <strong>Konsument </strong>
                                        – osoba fizyczna dokonującą z przedsiębiorcą czynności prawnej niezwiązanej bezpośrednio
                                        z jej działalnością gospodarczą lub zawodową w rozumieniu art. 221 Kodeksu cywilnego,
                                        albo osoba fizyczna zawierająca umowę bezpośrednio związaną z jej działalnością
                                        gospodarczą, gdy z treści tej umowy wynika, że nie ma ona dla tej osoby charakteru
                                        zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez nią działalności
                                        gospodarczej, udostępnionego na podstawie przepisów o Centralnej Ewidencji i Informacji
                                        o Działalności Gospodarczej w rozumieniu art. 7aa ustawy o prawach konsumenta;
                                    </li>
                                    <li>
                                        <strong>Kupujący </strong>
                                        – osoba fizyczna posiadająca pełną zdolność do czynności prawnych, osoba prawna lub
                                        jednostka organizacyjna nieposiadająca osobowości prawnej, ale posiadająca zdolność
                                        prawną mająca odpowiednio miejsce zamieszkania lub siedzibę na terenie Rzeczypospolitej
                                        Polskiej, która zawiera ze Sprzedawcą umowę sprzedaży produktów, znajdujących się w
                                        katalogu dostępnym na stronie internetowej;
                                    </li>
                                    <li>
                                        <strong>Produkt </strong>
                                        – towar wyprodukowany według specyfikacji konsumenta lub służący zaspokojeniu jego
                                        zindywidualizowanych potrzeb, który znajduje się w katalogu dostępnym na stronie
                                        internetowej, będący przedmiotem umowy sprzedaży pomiędzy Kupującym a Sprzedawcą;
                                    </li>
                                    <li>
                                        <strong>Regulamin </strong>
                                        – niniejszy Regulamin
                                    </li>
                                    <li>
                                        <strong>Sprzedawca </strong>
                                        – spółka UFO spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie
                                        (KRS: 0000950835, NIP: 5272988407, REGON: 521139016);
                                    </li>
                                    <li>
                                        <strong>Strona internetowa </strong>
                                        – strona prowadzona przez Sprzedawcę pod adresem https://www.ufo-meble.pl, na której
                                        znajdują się podstawowe informacje dotyczące produktów, które mogą zostać przygotowane
                                        według specyfikacji konsumenta lub służące zaspokojeniu jego zindywidualizowanych
                                        potrzeb;
                                    </li>
                                    <li>
                                        <strong>Strona </strong>
                                        – Kupujący będący konsumentem lub Sprzedawca;
                                    </li>
                                    <li>
                                        <strong>Strony </strong>
                                        – łącznie Kupujący będący konsumentem oraz Sprzedawca;
                                    </li>
                                    <li>
                                        <strong>Ustawa o prawach konsumenta </strong>
                                        – ustawa z dnia 30 maja 2014 roku - o prawach konsumenta (t.j. Dz. U. z 2023 r. poz. 2759);
                                    </li>
                                    <li>
                                        <strong>Ustawa ODO </strong>
                                        – ustawa z dnia 10 maja 2018 roku - o ochronie danych osobowych (t.j. Dz. U. z 2019 r.
                                        poz. 1781);
                                    </li>
                                    <li>
                                        <strong>RODO </strong>
                                        – Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016
                                        roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
                                        sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
                                        rozporządzenie o ochronie danych).
                                    </li>
                                </SubOrderedList>
                            </li>
                            <li>
                                Regulamin określa warunki oraz zasady umowy sprzedaży zawieranej pomiędzy Sprzedawcą a Kupującym
                                będącym konsumentem w zakresie ustawowego prawa odstąpienia od umowy w oparciu o uprawnienia
                                przewidziane w ustawie o prawach konsumenta.
                            </li>
                            <li>
                                Każdy Kupujący będący konsumentem zostaje zapoznany z treścią Regulaminu w chwili otrzymania
                                oferty na adres e-mail Kupującego będącego konsumentem.
                            </li>
                            <li>
                                Akceptacja Regulaminu przez Kupującego będącego konsumentem jest dobrowolna, lecz konieczna do
                                zawarcia umowy sprzedaży produktu.
                            </li>
                            <li>
                                Administratorem danych osobowych przetwarzanych w związku z realizacją postanowień Regulaminu
                                jest Sprzedawca. Podanie danych osobowych jest dobrowolne i każda osoba, której dane są
                                przetwarzane przez Sprzedawcę ma prawo wglądu w ich treść, prawo ich aktualizacji i poprawiania.
                                Realizacja obowiązków Sprzedawcy odbywa się zgodnie z RODO oraz ustawą ODO.
                            </li>
                        </OrderedList>
                    </DialogContentText>
                    <CursiveText sx={{ pt: 2 }}>
                        § 2.
                    </CursiveText>
                    <TermHeader>
                        Warunki zawierania umowy sprzedaży
                    </TermHeader>
                    <DialogContentText id="alert-dialog-slide-description">
                        <OrderedList>
                            <li>
                                Katalog produktów dostępnych na stronie internetowej Sprzedawcy nie stanowi oferty, lecz
                                zaproszenie do zawarcia umowy sprzedaży.
                            </li>
                            <li>
                                Za pośrednictwem strony internetowej Sprzedawcy i zawartego w niej formularza kontaktowego lub
                                za pośrednictwem poczty elektronicznej poprzez przesłanie widomości e-mail na adres poczty
                                elektronicznej Sprzedawcy, Kupujący może złożyć zapytanie dotyczące wyceny produktów, które
                                zostaną przygotowane na jego indywidualne zamówienie.
                            </li>
                            <li>
                                Po złożeniu zapytania/zamówienia Sprzedawca potwierdza jego otrzymanie za pośrednictwem poczty
                                elektronicznej, ustosunkowując się do zamówienia, podając czas realizacji oraz wycenę i przyjmuje
                                zamówienie do realizacji tylko w sytuacji kiedy nastąpi jego akceptacja.
                            </li>
                            <li>
                                Przesłana Kupującemu przez Sprzedawcę wycena stanowi ofertę.
                            </li>
                            <li>
                                Podczas dokonywania akceptacji przesłanej wyceny, Kupujący będący konsumentem potwierdza
                                zapoznanie się i akceptuje niniejszy Regulamin, w tym w zakresie pouczenia o braku
                                przysługiwania ustawowego prawa odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa
                                lub na odległość, zgodnie z art. 38 ustawy o prawach konsumenta.
                            </li>
                            <li>
                                Przesłanie przez Sprzedawcę Kupującemu, po akceptacji przez niego wyceny, potwierdzenia
                                przyjęcia zamówienia jest równoznaczne z zawarciem umowy sprzedaży.
                            </li>
                        </OrderedList>
                    </DialogContentText>
                    <CursiveText sx={{ pt: 2 }}>
                        § 3.
                    </CursiveText>
                    <TermHeader>
                        Wyłączenie ustawowego prawa odstąpienia od umowy
                    </TermHeader>
                    <DialogContentText id="alert-dialog-slide-description">
                        <OrderedList>
                            <li>
                                W przypadku zawarcia umowy sprzedaży, Kupującemu będącemu konsumentem nie przysługuje prawo
                                odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa lub na odległość, zgodnie z art.
                                38 ust. 1 ustawy o prawach konsumenta.
                            </li>
                            <li>
                                Wszystkie produkty dostępne w katalogu dostępnym na stronie internetowej Sprzedawcy
                                przygotowywane są na zamówienie oraz według specyfikacji konsumenta lub służą zaspokojeniu
                                jego zindywidualizowanych potrzeb.
                            </li>
                            <li>
                                Mając na względzie postanowienia ust. 1 i 2 powyżej, Kupujący będący konsumentem zawierając
                                umowę sprzedaży przyjmuje do wiadomości i akceptuje, że nie przysługuje mu ustawowe prawo
                                odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa lub na odległość.
                            </li>
                        </OrderedList>
                    </DialogContentText>
                    <CursiveText sx={{ pt: 2 }}>
                        § 4.
                    </CursiveText>
                    <TermHeader>
                        Reklamacja
                    </TermHeader>
                    <DialogContentText id="alert-dialog-slide-description">
                        <OrderedList>
                            <li>
                                Reklamacje można składać:
                            </li>
                            <SubOrderedList style={{ listStyleType: 'lower-alpha' }}>
                                <li>
                                    poprzez stronię internetową Sprzedawcy: www.ufo-meble.pl;
                                </li>
                                <li>
                                    w formie dokumentowej na adres poczty elektronicznej: reklamacje@ufo-meble.pl
                                </li>
                            </SubOrderedList>
                            <li>
                                W celu usprawnienia rozpatrywania reklamacji zalecamy wskazanie w zgłoszeniu reklamacyjnym:
                                <SubOrderedList style={{ listStyleType: 'lower-alpha' }}>
                                    <li>
                                        informacji i okoliczności dotyczących przedmiotu reklamacji, w szczególności rodzaju i
                                        daty wystąpienia nieprawidłowości,
                                    </li>
                                    <li>
                                        żądania Kupującego będącego konsumentem,
                                    </li>
                                    <li>
                                        danych kontaktowych Kupującego będącego konsumentem składającego reklamację.
                                    </li>
                                </SubOrderedList>
                            </li>
                            <li>
                                Sprzedawca rozpatruje reklamację Kupującego będącego konsumentem i informuje o wyniku jej
                                rozpatrzenia niezwłocznie, nie później niż w terminie 14 (czternastu) dni kalendarzowych od dnia
                                jej złożenia. W przypadku, gdy podane w reklamacjach dane lub informacje wymagałyby uzupełnienia
                                Sprzedawca zwróci się do Kupującego będącego konsumentem, przed rozpatrzeniem reklamacji o ich
                                uzupełnienie, przy czym Sprzedawca zobowiązany jest udzielić odpowiedzi Kupującemu będącemu
                                konsumentem na reklamację w terminie maksymalnie 14 dni od jej otrzymania. Odpowiedź na
                                reklamację Sprzedawca przekazuje Kupującemu będącemu konsumentem na papierze lub innym trwałym
                                nośniku.
                            </li>
                            <li>
                                Zgodnie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) nr 524/2013 z dnia 21 maja 2013
                                roku w sprawie internetowego systemu rozstrzygania sporów konsumenckich Kupujący będący
                                konsumentem ma możliwość złożenia skargi za pośrednictwem platformy ODR pod adresem
                                https://webgate.ec.europa.eu/odr/main/?event=main.home.show.
                            </li>
                            <li>
                                Platforma ODR to platforma internetowego systemu rozstrzygania sporów pomiędzy konsumentami i
                                przedsiębiorcami na szczeblu unijnym, która stanowi interaktywną i wielojęzyczną stronę
                                internetową z punktem kompleksowej obsługi dla konsumentów i przedsiębiorców dążących do
                                pozasądowego rozstrzygnięcia sporu dotyczącego zobowiązań umownych wynikających z internetowej
                                umowy sprzedaży lub umowy o świadczenie usług.
                            </li>
                            <li>
                                Kupujący będący konsumentem posiada możliwość skorzystania z pozasądowych sposobów rozpatrywania
                                reklamacji i dochodzenia roszczeń, na przykład poprzez złożenie wniosku o rozstrzygnięcie sporu
                                do stałego polubownego sądu konsumenckiego lub złożenie wniosku w sprawie pozasądowego
                                rozwiązania sporu do wojewódzkiego inspektora Inspekcji Handlowej.
                            </li>
                        </OrderedList>
                    </DialogContentText>

                    <CursiveText sx={{ pt: 2 }}>
                        § 5.
                    </CursiveText>
                    <TermHeader>
                        Postanowienia końcowe
                    </TermHeader>
                    <DialogContentText id="alert-dialog-slide-description">
                        <OrderedList>
                            <li>
                                Regulamin obowiązuje przez czas nieokreślony.
                            </li>
                            <li>
                                Regulamin stanowi integralną część umowy sprzedaży zawieranej pomiędzy Sprzedawcą a Kupującym
                                będącym konsumentem.
                            </li>
                            <li>
                                Z zastrzeżeniem obowiązujących przepisów prawa, w tym Kodeksu cywilnego oraz ustawy o prawach
                                konsumentów:
                                <SubOrderedList style={{ listStyleType: 'lower-alpha' }}>
                                    <li>
                                        o ile Strony nie postanowią zgodnie inaczej, umowa sprzedaży jest zawarta na podstawie
                                        Regulaminu, co oznacza, że określa on ich prawa i obowiązki;
                                    </li>
                                    <li>
                                        o ile Strony zawrą jednak umowę sprzedaży, która będzie obejmować postanowienia sprzeczne
                                        z postanowieniami Regulaminu, stosuje się – w zakresie objętym taką kolizją – postanowienia
                                        umowne; w pozostałym zakresie Regulamin nadal wiąże Strony.
                                    </li>
                                </SubOrderedList>
                            </li>
                            <li>
                                W przypadku Kupujących będących konsumentami i wyłącznie w stosunku do nich:
                                <SubOrderedList style={{ listStyleType: 'lower-alpha' }}>
                                    <li>
                                        w zakresie nieuregulowanym Regulaminem stosuje się przepisy prawa konsumenckiego, w tym
                                        przepisy ustawy o prawach konsumenta;
                                    </li>
                                    <li>
                                        przed zawarciem umowy sprzedaży Sprzedawca dostarczy Kupującemu będącemu konsumentem
                                        Regulamin, jak również wykona obowiązki informacyjne określone przepisami ustawy o
                                        prawach konsumenta.
                                    </li>
                                </SubOrderedList>
                            </li>
                            <li>
                                W przypadku Kupujących będących Konsumentem zastrzega się, stosownie do art. 22 ustawy o prawach
                                konsumenta, że informacje, o których mowa w art. 12 ust. 1 ustawy o prawach konsumenta stanowią
                                integralną część umowy sprzedaży zawieranej na odległość albo poza lokalem przedsiębiorstwa i
                                mogą być zmienione jedynie za wyraźnym porozumieniem Stron.
                            </li>
                            <li>
                                Wszelkie spory związane z wykonaniem umowy sprzedaży będą rozstrzygane w przypadku Kupujących
                                będących konsumentami przez właściwe polskie sądy powszechne.
                            </li>
                            <li>
                                Regulamin wchodzi w życie niezwłocznie od dnia jego ogłoszenia.
                            </li>
                        </OrderedList>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickDisagree}>Anuluj</Button>
                    <Button onClick={handleClickAgree}>Akceptuję</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default RegulationsAlert;