import { useGlobalState } from "../context/context"
import { fakeTokenMinsk, fakeTokenPiaseczno } from "../config"

export const useUser = () => {

    const { state, dispatch } = useGlobalState()
    const selectedShowroom = localStorage.getItem('selectedShowroom')

    const isAuthMinsk = state.isAuthMinsk && selectedShowroom === 'minsk'
    const isAuthPiaseczno = state.isAuthPiaseczno && selectedShowroom === 'piaseczno'

    const setIsAuthMinsk = (isAuthMinsk: boolean) => {
        dispatch({
            'type': 'setIsAuthMinsk',
            'isAuthMinsk': isAuthMinsk
        })
    }

    const setIsAuthPiaseczno = (isAuthPiaseczno: boolean) => {
        dispatch({
            'type': 'setIsAuthPiaseczno',
            'isAuthPiaseczno': isAuthPiaseczno
        })
    }

    const autoLogin = async () => {
        const fakeTokenMinskStored = localStorage.getItem('fakeTokenMinsk')
        const fakeTokenPiasecznoStored = localStorage.getItem('fakeTokenPiaseczno')

        if (fakeTokenMinskStored === fakeTokenMinsk) {
            setIsAuthMinsk(true)
        }

        if (fakeTokenPiasecznoStored === fakeTokenPiaseczno) {
            setIsAuthPiaseczno(true)
        }
    }

    return {
        setIsAuthMinsk,
        setIsAuthPiaseczno,
        autoLogin,
        isAuthMinsk,
        isAuthPiaseczno,
    }
}