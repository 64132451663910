import { Divider, Typography, IconButton } from "@mui/material";
import { FC, useState, useCallback, useEffect } from "react";
import collectionsController from "../../../../../Api/collectionsController";
import producersController from "../../../../../Api/producersController";
import { ICollection } from "../../../../../interfaces/ICollection";
import { IProducer } from "../../../../../interfaces/IProducer";
import styled from "@emotion/styled";
import { useUser } from "../../../../../hooks/useUser";
import { Edit } from "@mui/icons-material";
import { useModal } from "../../../../../hooks/useModal";
import DimensionsComponent from "./components/DimensionsComponent";
import PriceComponent from "./components/PriceComponent";
import EditNameForm from "../../../../../components/forms/furniture/EditNameForm";
import CustomButton from "../../../../../components/_common/CustomButton";
import ProducerComponent from "./components/ProducerComponent";
import CollectionComponent from "./components/CollectionComponent";
import CategoryComponent from "./components/CategoryComponent";
import PremisesComponent from "./components/PremisesComponent";
import DeleteButtonComponent from "./components/DeleteButtonComponent";

const MyDivider = styled(Divider)`
    margin-top: 15px;
    margin-bottom: 10px;
`

const MyButton = styled(CustomButton) <{ isInCart?: boolean }>`
    background-color: ${({ isInCart }) => isInCart ? 'grey' : '#283890'};
    width: 100%;
    margin-top: 10px;
    padding: 0 10;
    font-size: 0.74rem;
`


interface DetailsComponentProps {
    id: string,
    name: string,
    image: string,
    producerId?: string | null,
    partCollection?: string | null,
    price?: number | null,
    crossedPrice?: number | null,
    width?: number | null,
    height?: number | null,
    depth?: number | null,
    showPrice: boolean,
    showEditTools: boolean,
    furnitureCategories?: string[] | null,
    furniturePremises?: string[] | null,
    onSetShowEditTools: (state: boolean) => void;
    onRefreshFurniture: () => void;
}

const DetailsComponent: FC<DetailsComponentProps> = ({
    id,
    name,
    producerId,
    partCollection,
    price,
    crossedPrice,
    width,
    height,
    depth,
    image,
    showPrice,
    showEditTools,
    furnitureCategories,
    furniturePremises,
    onSetShowEditTools,
    onRefreshFurniture,
}) => {
    const [producer, setProducer] = useState<IProducer | null>(null)
    const [collection, setCollection] = useState<ICollection | null>(null)
    const user = useUser();
    const modal = useModal();

    const getProducer = useCallback(async () => {
        if (!producerId) return;
        const res = await producersController.getSingleProducer(producerId);

        if (res.ok) {
            setProducer(res.producer);
        }
    }, [producerId])


    const getCollection = useCallback(async () => {
        if (!partCollection) return;
        const res = await collectionsController.getSingleCollection(partCollection);

        if (res.ok) {
            setCollection(res.collection);
        }
    }, [partCollection])


    const EditNameButton = () => {
        return (
            <IconButton
                color="secondary"
                onClick={() => modal.open(
                    <EditNameForm
                        furnitureId={id}
                        nameValue={name}
                        onRefreshFurniture={onRefreshFurniture}
                    />
                )}
            >
                <Edit />
            </IconButton>
        )
    }


    useEffect(() => {
        getCollection();
    }, [getCollection])

    useEffect(() => {
        getProducer();
    }, [getProducer])


    return (
        <>
            {(user.isAuthMinsk || user.isAuthPiaseczno) &&
                <>
                    <MyButton
                        onClick={() => onSetShowEditTools(!showEditTools)}
                        text={showEditTools ? "Ukryj narzędzia edycji" : "Pokaż narzędzia edycji"}
                    />
                    <MyDivider />
                </>
            }
            <Typography variant="h5" sx={{ textAlign: 'center' }}>
                {name}
                {showEditTools && <EditNameButton />}
            </Typography>
            <MyDivider />
            <DeleteButtonComponent
                furnitureId={id}
                showEditTools={showEditTools}
            />
            <PriceComponent
                id={id}
                price={price}
                showPrice={showPrice}
                name={name}
                image={image}
                crossedPrice={crossedPrice}
                showEditTools={showEditTools}
                onRefreshFurniture={onRefreshFurniture}
            />
            <DimensionsComponent
                id={id}
                width={width}
                height={height}
                depth={depth}
                showEditTools={showEditTools}
                onRefreshFurniture={onRefreshFurniture}
            />
            {width || height || depth ? <MyDivider /> : <></>}
            <ProducerComponent
                furnitureId={id}
                name={producer?.name}
                logo={producer?.logo}
                producerId={producer?._id}
                showEditTools={showEditTools}
                onRefreshFurniture={onRefreshFurniture}
            />
            {collection && <MyDivider />}
            <CollectionComponent
                furnitureId={id}
                id={collection?._id}
                name={collection?.name}
                image={collection?.image}
                producerId={producer?._id}
                showEditTools={showEditTools}
                onRefreshFurniture={onRefreshFurniture}
            />
            {showEditTools &&
                <>
                    <MyDivider />
                    <CategoryComponent
                        furnitureId={id}
                        furnitureCategories={furnitureCategories}
                        onRefreshFurniture={onRefreshFurniture}
                    />
                    <MyDivider />
                    <PremisesComponent
                        furnitureId={id}
                        furniturePremises={furniturePremises}
                        onRefreshFurniture={onRefreshFurniture}
                    />
                </>
            }
        </>
    )
}

export default DetailsComponent;