import { FC } from "react";
import { Button, Chip } from "@mui/material";
import styled from "@emotion/styled";
import { useModal } from "../../../../../../hooks/useModal";
import EditCategoryForm from "../../../../../../components/forms/furniture/EditCategoryForm";

const MyChip = styled(Chip)`
    margin: 3px;
    margin-left: 0;
`

interface CategoryComponentProps {
    furnitureId: string,
    furnitureCategories?: string[] | null,
    onRefreshFurniture: () => void;
}

const CategoryComponent: FC<CategoryComponentProps> = ({
    furnitureId,
    furnitureCategories,
    onRefreshFurniture,
}) => {
    const modal = useModal();

    return (
        <div>
            <Button
                size="small"
                sx={{
                    width: '100%',
                    mb: 1,
                }}
                color="secondary"
                variant="contained"
                onClick={() => modal.open(
                    <EditCategoryForm
                        furnitureId={furnitureId}
                        furnitureCategories={furnitureCategories}
                        onRefreshFurniture={onRefreshFurniture}
                    />
                )}
            >
                Edytuj kategorie
            </Button>
            {furnitureCategories?.map((category: string) => (
                <div key={category}>
                    <MyChip
                        color="secondary"
                        label={category.toUpperCase()}
                    />
                </div>
            ))}
        </div >
    )
}

export default CategoryComponent;