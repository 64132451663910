import { Box, Card, IconButton, Typography } from "@mui/material";
import { FC } from "react";
import linkify from 'linkify-it';
import CustomButton from "../../../../components/_common/CustomButton";
import styled from "@emotion/styled";
import { Add, Edit } from "@mui/icons-material";
import { useModal } from "../../../../hooks/useModal";
import EditDescriptionForm from "../../../../components/forms/furniture/EditDescriptionForm";


const LeftCard = styled(Card)`
    width: 600px;
    max-width: calc(100% - 40px);
    padding: 20px;

    @media screen and (max-width: 940px){
        width: calc(100% - 40px);
    }
`


interface DescriptionComponentProps {
    description?: string | null;
    showEditTools: boolean;
    furnitureId: string;
    onRefreshFurniture: () => void;
}

const DescriptionComponent: FC<DescriptionComponentProps> = ({
    description,
    showEditTools,
    furnitureId,
    onRefreshFurniture,
}) => {
    const modal = useModal()

    if (!description) return (
        showEditTools ?
            <LeftCard sx={{
                mt: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}>
                <IconButton
                    color="success"
                    onClick={() => modal.open(
                        <EditDescriptionForm
                            furnitureId={furnitureId}
                            descriptionValue={description}
                            onRefreshFurniture={onRefreshFurniture}
                        />
                    )}
                >
                    <Add />
                </IconButton>
                Dodaj opis
            </LeftCard>
            :
            <></>
    )

    const cleanedDescription = description.replace(/\\r\\n/g, '\n');
    const linkifyInstance = new linkify();
    const links = linkifyInstance.match(cleanedDescription);
    const link = links && links[0] && links[0].url;
    const [textBeforeLink, textAfterLink] = link ? cleanedDescription.split(link) : [cleanedDescription, ''];


    return (
        <LeftCard sx={{ mt: 2 }}>
            <Typography variant='h6' sx={{ pb: 2 }}>
                {showEditTools &&
                    <IconButton
                        color='secondary'
                        onClick={() => modal.open(
                            <EditDescriptionForm
                                furnitureId={furnitureId}
                                descriptionValue={description}
                                onRefreshFurniture={onRefreshFurniture}
                            />
                        )}
                    >
                        <Edit />
                    </IconButton>
                }
                Opis:
            </Typography>
            <Typography variant='body1' sx={{ whiteSpace: 'pre-line' }}>
                {textBeforeLink}
                {link &&
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 2,
                    }}>
                        <CustomButton
                            href={link}
                            text='Sprawdź moduły'
                            rel="noopener noreferrer"
                            onClick={() => { }}
                        />
                    </Box>
                }
                {textAfterLink}
            </Typography>
        </LeftCard>
    )
}

export default DescriptionComponent;