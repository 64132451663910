import { FC, useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { useParams } from "react-router-dom";
import importedFurnitureController from "../../Api/importedFurnitureController";
import { IIMportedFurniture } from "../../interfaces/IImportedFurniture";
import LoadingAnimation from "../../components/_common/Loading";
import FurnitureItem from "./components/FurnitureItem/FurnitureItem";


interface IParams {
    symbol?: string,
}

interface SingleImportedFurnitureProps {

}

const SingleImportedFurniture: FC<SingleImportedFurnitureProps> = () => {
    const [furniture, setFurniture] = useState<IIMportedFurniture | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const params: IParams = useParams()


    const getFurnitureData = useCallback(async () => {
        if (!params.symbol) return
        const res = await importedFurnitureController.getSingleImportedFurniture(params.symbol);

        if (!res.ok || !res.data) return

        setFurniture(res.data)
        setLoading(false)
    }, [
        params.symbol,
    ])


    const findFeatureValue = (features: any[], featureName: string) => {
        if (!features) return null;
        const feature = features[0].feature.find((f: any) => f.name[0] === featureName);
        return feature ? feature.value[0] : null;
    }


    useEffect(() => {
        getFurnitureData()
    }, [getFurnitureData])


    const renderFurniture = () => {
        if (loading) return <LoadingAnimation />

        if (!furniture) return <h1>Brak danych</h1>

        const width = findFeatureValue(furniture?.features, "Szerokość");
        const height = findFeatureValue(furniture?.features, "Wysokość");
        const depth = findFeatureValue(furniture?.features, "Długość");
        return (
            <FurnitureItem
                symbol={furniture.symbol}
                name={furniture.name}
                desc={furniture.desc}
                photos={furniture.photos}
                width={width}
                height={height}
                depth={depth}
                image={furniture.photos[0].photo[0]}
                features={furniture.features}
            />
        )
    }


    return (
        <Layout title={furniture?.name || 'Mebel'}>
            {renderFurniture()}
        </Layout>
    )
}

export default SingleImportedFurniture;