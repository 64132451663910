import { ReactNode, createContext, useContext, useReducer } from 'react'
import { IContext } from './IContext'
import { CartItem } from '../hooks/useCart'


type Action =
  | { type: 'setCart', basketFurniture: CartItem[] }
  | { type: 'setIsAuthMinsk', isAuthMinsk: boolean }
  | { type: 'setIsAuthPiaseczno', isAuthPiaseczno: boolean }
  | { type: 'setModalContent', modalContent: React.ReactNode }
  | { type: 'setShowModal', showModal: boolean }

type Dispatch = (action: Action) => void

type ContextProviderProps = { children: ReactNode }

const CountStateContext = createContext<
  { state: IContext; dispatch: Dispatch } | undefined
>(undefined)


const countReducer = (state: IContext, action: Action) => {
  switch (action.type) {

    case 'setShowModal': {
      return {
        ...state,
        showModal: action.showModal,
      }
    }

    case 'setModalContent': {
      return {
        ...state,
        modalContent: action.modalContent,
      }
    }

    case 'setCart': {
      return {
        ...state,
        basketFurniture: action.basketFurniture,
      }
    }

    case 'setIsAuthMinsk': {
      return {
        ...state,
        isAuthMinsk: action.isAuthMinsk,
      }
    }

    case 'setIsAuthPiaseczno': {
      return {
        ...state,
        isAuthPiaseczno: action.isAuthPiaseczno,
      }
    }

    default: {
      throw new Error(`Unhandled action type.`)
    }
  }
}

const ContextProvider = ({ children }: ContextProviderProps) => {
  const [state, dispatch] = useReducer(countReducer, {
    basketFurniture: [],
    isAuthMinsk: false,
    isAuthPiaseczno: false,
    modalContent: null,
    showModal: false,
  })
  const value = { state, dispatch }
  return (
    <CountStateContext.Provider value={value}>
      {children}
    </CountStateContext.Provider>
  )
}

const useGlobalState = () => {
  const context = useContext(CountStateContext)
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a ContextProvider')
  }
  return context
}

export { ContextProvider, useGlobalState }