import axios from "axios";
import IResponse from "../interfaces/IResponse";
import { getShowroomUrl } from "../services/getShowroomUrl";
import { IIMportedFurniture } from "../interfaces/IImportedFurniture";
import { ICategory } from "../interfaces/ICategory";


interface IGetImportedFurniture extends IResponse {
    furniture: IIMportedFurniture[] | null,
    categories: ICategory[] | null,
}

const getAllImportedFurniture = async (): Promise<IGetImportedFurniture> => {
    const url = `${getShowroomUrl()}api/importedFurniture`;

    try {
        const res = await axios.get(url);

        const resoult = {
            message: 'Pobrano meble',
            status: res.status,
            categories: res.data.categories,
            furniture: res.data.importedFurniture,
            ok: true,
        }

        return resoult;
    } catch (error) {
        return {
            message: 'Nie pobrano mebli',
            status: 400,
            furniture: null,
            categories: null,
            ok: false,
        };
    }
}


interface IGetSingleImportedFurniture extends IResponse {
    data: IIMportedFurniture | null,
}

const getSingleImportedFurniture = async (symbol: string): Promise<IGetSingleImportedFurniture> => {
    const url = `${getShowroomUrl()}api/importedFurniture/${symbol}`;

    try {
        const res = await axios.get(url);

        const resoult = {
            message: 'Pobrano mebel',
            status: res.status,
            data: res.data.furniture,
            ok: true,
        }

        return resoult;
    } catch (error) {
        return {
            message: 'Nie pobrano mebla',
            status: 400,
            data: null,
            ok: false,
        };
    }
}


const forceUpdateImportedFurniture = async (): Promise<IResponse> => {
    const url = `${getShowroomUrl()}api/importedFurniture/forceUpdate`;

    try {
        const res = await axios.get(url);

        const resoult = {
            message: 'Zaktualizowano meble',
            status: res.status,
            ok: true,
        }

        return resoult;
    } catch (error) {
        return {
            message: 'Nie zaktualizowano mebli',
            status: 400,
            ok: false,
        };
    }
}


const importedFurnitureController = {
    getAllImportedFurniture,
    getSingleImportedFurniture,
    forceUpdateImportedFurniture,
}

export default importedFurnitureController;