import { FC, useRef, useState } from "react";
import FormLayout from "../FormLayout";
import { useToast } from "../../../hooks/useToast";
import { useModal } from "../../../hooks/useModal";
import { premisesData } from "../../../pages/Premises/premisesData";
import { IPremise } from "../../../interfaces/IPremise";
import { Card, CardContent, ImageList } from "@mui/material";
import styled from "@emotion/styled";
import LoadingAnimation from "../../_common/Loading";
import furnitureController from "../../../Api/furnitureController";


const PremiseImage = styled.img`
    margin-top: 20px;
    width: 200px;
    height: 100px;
    object-fit: contain;
`

const PremisesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 40px;
    margin-bottom: 80px;
    user-select: none;
`

interface EditPremiseFormProps {
    furniturePremises?: string[] | null,
    furnitureId: string,
    onRefreshFurniture: () => void;
}

const EditPremiseForm: FC<EditPremiseFormProps> = ({
    furniturePremises,
    furnitureId,
    onRefreshFurniture,
}) => {
    const [premiseState, setPremiseState] = useState<string[]>(furniturePremises || []);
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useRef(useToast()).current;
    const modal = useModal();


    const handleChangePremiseState = (premiseItem: string) => {
        const premiseItemUpper = premiseItem.toUpperCase();
        if (premiseState?.map(name => name.toUpperCase()).includes(premiseItemUpper)) {
            setPremiseState(prevState => prevState.filter((premise: string) => premise.toUpperCase() !== premiseItemUpper));
        } else {
            setPremiseState(prevState => [...prevState, premiseItem]);
        }
    }


    const handleSavePremises = async () => {
        setLoading(true);
        const res = await furnitureController.editPremises({
            premises: premiseState,
            furnitureId: furnitureId,
        });
        if (!res.ok) {
            setLoading(false);
            return toast.error(res.message);
        }
        setLoading(false);
        onRefreshFurniture();
        modal.close();
    }


    if (loading) return (
        <Card>
            <LoadingAnimation />
        </Card>
    )


    return (
        <FormLayout
            title="Edytuj pomieszczenie"
            onClickButton={() => handleSavePremises()}
            maxWidth='lg'
        >
            <ImageList
                sx={{
                    pt: 4,
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                }}
            >
                <PremisesContainer>
                    {premisesData.map((premise: IPremise) => (
                        <Card key={premise.name}
                            sx={{
                                m: 1,
                                p: 1,
                                '&:hover': {
                                    transform: 'scale(1.15)',
                                },
                                transition: 'transform 0.2s, box-shadow 0.2s',
                                cursor: 'pointer',
                                boxShadow: premiseState.map(name => name.toLowerCase()).includes(premise.name.toLowerCase())
                                    ?
                                    '0px 4px 20px rgba(0, 42, 255, 0.5)'
                                    :
                                    '0px 1px 3px rgba(0, 0, 0, 0.2)'
                            }}
                            onClick={() => handleChangePremiseState(premise.name)}
                        >

                            <PremiseImage
                                src={`${premise.image}`}
                                alt={premise.name}
                            />
                            <CardContent sx={{ textAlign: 'center' }}>
                                {premise.name.toUpperCase()}
                            </CardContent>
                        </Card>))
                    }
                </PremisesContainer>
            </ImageList>
        </FormLayout>
    )
}

export default EditPremiseForm;