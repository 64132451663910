import styled from "@emotion/styled";
import { FC } from "react";
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";


const CustomizedLabel: FC<any> = ({ x, y, stroke, value }) => (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
        {value}
    </text>
);

const CustomizedAxisTick: FC<any> = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload.value}
        </text>
    </g>
);


const Container = styled.div`
    display: flex;
    justify-content: center;
`


interface LineChartComponentProps {
    data: any;
    yearToDisplay: number | null;
}

const LineChartComponent: FC<LineChartComponentProps> = ({
    data,
    yearToDisplay,
}) => {

    if (!yearToDisplay) return (<></>)

    return (
        <Container>
            <LineChart
                width={1000}
                height={420}
                data={data[yearToDisplay]}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 10,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" height={60} tick={<CustomizedAxisTick />} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" name='Wejścia na stronę' dataKey="pv" stroke="#283890" label={<CustomizedLabel />} />
            </LineChart>
        </Container>
    )
}

export default LineChartComponent;