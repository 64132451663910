import { styled } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import { useGlobalState } from '../../context/context';
import { useModal } from '../../hooks/useModal';
import { FC } from 'react';


interface TransitionsModalProps {
};


const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;


const TransitionsModal: FC<TransitionsModalProps> = () => {
  const { state } = useGlobalState()
  const modal = useModal()
  const open = state.showModal

  const handleClose = () => {
    modal.close()
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <>
          {state.modalContent}
        </>
      </Modal>
    </div>
  );
}

export default TransitionsModal;