import { DoneOutline, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails, Card, CardContent, Icon } from "@mui/material";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import LoadingAnimation from "../../../../_common/Loading";
import { ICollection } from "../../../../../interfaces/ICollection";
import { useToast } from "../../../../../hooks/useToast";
import collectionsController from "../../../../../Api/collectionsController";
import styled from "@emotion/styled";
import emptyImage from '../../../../../assets/img/emptyImage.png'
import { getShowroomUrl } from "../../../../../services/getShowroomUrl";

const BASE_URL = getShowroomUrl();

const CollectionImage = styled.img`
    margin-top: 20px;
    width: 200px;
    height: 100px;
    object-fit: contain;
`

const CollectionsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
`

interface CollectionProps {
    partCollection?: string | null,
    producerId?: string | null,
    disabled: boolean,
    expanded: string | false,
    onSetExpanded: (accordionName: string) => void,
    onClickAccordion: (accordionName: string) => void,
    onSetCollection: (collectionId: string) => void,
}

const Collection: FC<CollectionProps> = ({
    partCollection,
    producerId,
    disabled,
    expanded,
    onSetExpanded,
    onClickAccordion,
    onSetCollection,
}) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [collectionsData, setCollectionsData] = useState<ICollection[] | null>(null)
    const toast = useRef(useToast()).current
    const [showOk, setShowOk] = useState<boolean>(false)

    const getCollections = useCallback(async () => {
        setLoading(true);
        if (!producerId) {
            toast.error('Nie znaleziono kolekcji');
            setLoading(false);
            return;
        }
        const res = await collectionsController.getAllProducerCollections(producerId);
        if (res.ok) {
            setCollectionsData(res.collections);
        }
        setLoading(false);
    }, [producerId, toast])


    useEffect(() => {
        if (!producerId) return;
        getCollections();
    }, [getCollections, producerId])


    const renderCollections = () => {
        if (loading) return <LoadingAnimation />

        if (!producerId) return <>Nie wybrano producenta</>

        if (!collectionsData || collectionsData.length === 0) return <>Ten producent nie posiada kolekcji</>

        return (
            <CollectionsContainer>
                <Card
                    sx={{
                        m: 1,
                        p: 1,
                        '&:hover': {
                            transform: 'scale(1.15)',
                        },
                        transition: 'transform 0.2s, box-shadow 0.2s',
                        cursor: 'pointer',
                        boxShadow: ((null || '') === partCollection ? '0px 4px 20px rgba(0, 42, 255, 0.5)' : '0px 1px 3px rgba(0, 0, 0, 0.2)')
                    }}
                    onClick={() => {
                        onSetCollection('')
                        setShowOk(true)
                    }}
                >
                    <CollectionImage src={emptyImage} />
                    <CardContent sx={{ textAlign: 'center' }}>
                        Brak kolekcji
                    </CardContent>
                </Card>
                {collectionsData.map((collectionItem) => (
                    <Card
                        key={collectionItem._id}
                        sx={{
                            m: 1,
                            p: 1,
                            '&:hover': {
                                transform: 'scale(1.15)',
                            },
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            cursor: 'pointer',
                            boxShadow: (collectionItem._id === partCollection ? '0px 4px 20px rgba(0, 42, 255, 0.5)' : '0px 1px 3px rgba(0, 0, 0, 0.2)')
                        }}
                        onClick={() => {
                            onSetCollection(collectionItem._id)
                            setShowOk(true)
                        }}
                    >
                        <CollectionImage
                            src={`${BASE_URL}${collectionItem.image}`}
                            alt={collectionItem.name}
                        />
                        <CardContent sx={{ textAlign: 'center' }}>
                            {collectionItem.name}
                        </CardContent>
                    </Card>))
                }
            </CollectionsContainer>
        )
    }

    return (
        <Accordion
            disabled={disabled}
            expanded={expanded === 'Kolekcja'}
        >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
                onClick={() => {
                    onClickAccordion('Kolekcja')
                }}
            >
                2. Kolekcja
                {(showOk || collectionsData?.length === 0) &&
                    <Icon sx={{ ml: 1 }}>
                        <DoneOutline color="success" />
                    </Icon>
                }
            </AccordionSummary>
            <AccordionDetails>
                {renderCollections()}
            </AccordionDetails>
        </Accordion>
    )
}

export default Collection;