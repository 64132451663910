import { useEffect, useState } from 'react';
import styles from './promotions.module.scss';
import promotionalFurnitureController from '../../Api/promotionalFurnitureController';
import { getShowroomUrl } from '../../services/getShowroomUrl';
import Lottie from 'lottie-react';
import loadingAnimation from '../../assets/animations/Morphing.json';
import Layout from '../../components/layout/Layout';
import PromotionalSalesWindow from '../../components/_common/PromotionalSalesWindow/PromotionalSalesWindow';
import { IPromotionalFurniture } from '../../interfaces/IPromotionalFurniture';

const BASE_URL = getShowroomUrl();

export default function Promotions() {

    const [promotionalFurnitureData, setPromotionalFurnitureData] = useState<IPromotionalFurniture[] | null>(null);
    const [loading, setLoading] = useState(true);

    const getAllFurniture = async () => {
        setLoading(true)
        const res = await promotionalFurnitureController.getAllFurniture();
        setPromotionalFurnitureData(res.furniture);
        setLoading(false)
    }

    useEffect(() => {
        getAllFurniture();
    }, []);

    return (
        <Layout title='Promocje'>
            <div className={styles.general}>

                <div className={styles.furnitureContainer}>

                    {
                        loading ? (
                            <Lottie
                                style={{
                                    maxWidth: '250px',
                                    maxHeight: '250px',
                                }}
                                animationData={loadingAnimation}
                                loop={true}
                            />
                        ) : (
                            !promotionalFurnitureData
                                || !Array.isArray(promotionalFurnitureData)
                                || promotionalFurnitureData.length === 0
                                ? (
                                    <div style={{
                                        marginTop: '150px'
                                    }}>
                                        Brak mebli
                                    </div>
                                ) : (

                                    promotionalFurnitureData.map((furniture, index) => {
                                        return (
                                            <div key={index}>
                                                <PromotionalSalesWindow
                                                    type='furniture'
                                                    key={index}
                                                    link={`mebel-promocyjny/${furniture._id}`}
                                                    id={furniture._id}
                                                    name={furniture.name}
                                                    width={furniture.width || 0}
                                                    depth={furniture.depth || 0}
                                                    height={furniture.height || 0}
                                                    price={furniture.price || 0}
                                                    crossed={furniture.crossed || 0}
                                                    isPriceVissible={furniture.isPriceVissible || false}
                                                    image={`${BASE_URL}${furniture.image.replace('promotionalFurnitureImages', 'promotionalFurnitureImages/thumbnailImages')}`}
                                                />
                                            </div>
                                        )
                                    })

                                )
                        )
                    }

                </div>

            </div>
        </Layout>
    )
}