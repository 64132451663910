import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Card } from "@mui/material";
import ImageComponent from "./components/ImageComponenet";
import DetailsComponent from "./components/DetailsComponent";
import DescriptionComponent from "./components/DescriptionComponent";
import { getShowroomUrl } from "../../../services/getShowroomUrl";

const BASE_URL = getShowroomUrl();

const Container = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 50px;
    width: 100%;

    @media screen and (max-width: 940px){
        display: block;
    }
`

const LeftCard = styled(Card)`
    width: 600px;
    max-width: calc(100% - 40px);
    padding: 20px;

    @media screen and (max-width: 940px){
        width: calc(100% - 40px);
    }
`

const DetailsCard = styled(Card)`
    width: 310px;
    height: 100%;
    padding: 20px;
    margin-left: 10px;
    background-color: #f5f5f5;
    
    @media screen and (min-height: 570px){
        position: sticky;
        top: 60px;
    }

    @media screen and (max-width: 940px){
        margin-top: 30px;
        width: calc(100% - 40px);
        margin-left: 0px;
        position: static;
    }
`


interface DetailsCardComponentProps {
    id: string,
    name: string,
    width?: number | null,
    height?: number | null,
    depth?: number | null,
    image: string;
    producerId?: string | null;
    partCollection?: string | null;
    price?: number | null;
}

const DetailsCardComponent: FC<DetailsCardComponentProps> = ({
    id,
    name,
    width,
    height,
    depth,
    image,
    producerId,
    partCollection,
    price,
}) => {
    return (
        <DetailsCard>
            <DetailsComponent
                id={id}
                name={name}
                width={width}
                height={height}
                depth={depth}
                image={image}
                producerId={producerId}
                partCollection={partCollection}
                price={price}
            />
        </DetailsCard>
    )
}


interface PromotionalFurnitureItemProps {
    id: string,
    name: string,
    image: string;
    description?: string | null;
    width?: number | null;
    height?: number | null;
    depth?: number | null;
    producerId?: string | null;
    partCollection?: string | null;
    price?: number | null;
}

const PromotionalFurnitureItem: FC<PromotionalFurnitureItemProps> = ({
    id,
    name,
    image,
    description,
    width,
    height,
    depth,
    producerId,
    partCollection,
    price,
}) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [screenWidth]);


    return (
        <Container>
            {screenWidth > 940 ?
                <>
                    <div>
                        <LeftCard>
                            <ImageComponent
                                imageUrl={`${BASE_URL}${image}`}
                            />
                        </LeftCard>
                        {
                            description &&
                            <LeftCard sx={{ mt: 2 }}>
                                <DescriptionComponent
                                    description={description}
                                />
                            </LeftCard>
                        }
                    </div>
                    <DetailsCardComponent
                        id={id}
                        name={name}
                        width={width}
                        height={height}
                        depth={depth}
                        image={image}
                        producerId={producerId}
                        partCollection={partCollection}
                        price={price}
                    />
                </>
                :
                <>
                    <div>
                        <LeftCard>
                            <ImageComponent
                                imageUrl={`${BASE_URL}${image}`}
                            />
                        </LeftCard>
                    </div>
                    <DetailsCardComponent
                        id={id}
                        name={name}
                        width={width}
                        height={height}
                        depth={depth}
                        image={image}
                        producerId={producerId}
                        partCollection={partCollection}
                        price={price}
                    />
                    {
                        description &&
                        <LeftCard sx={{ mt: 2 }}>
                            <DescriptionComponent
                                description={description}
                            />
                        </LeftCard>
                    }
                </>
            }
        </Container>
    )
}

export default PromotionalFurnitureItem;