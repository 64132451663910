import { FC } from 'react';
import { ICategory } from '../../../interfaces/ICategory';
import WebCategories from './components/WebCategories/WebCategories';
import styled from 'styled-components';
import MobileFilters from './components/MobileFilters/MobileFilters';
import { ICollection } from '../../../interfaces/ICollection';
import { IPremise } from '../../../interfaces/IPremise';
import { IProducer } from '../../../interfaces/IProducer';


const Container = styled.div`
`


interface CategoriesProps {
    searchValue: string;
    onSetCategoryHandler: (name: string) => void,
    categoryFilter: string,
    categoryData: ICategory[],
    showMobileFilters: boolean,
    onSetShowMobileFilters: (show: boolean) => void;
    onSetSearchValueHandler: (searchValue: string) => void;
    producers?: IProducer[] | null;
    producerFilter?: string | null;
    onSetProducersHandler?: (producerValue: string) => void;
    collections?: ICollection[] | null;
    collectionFilter?: string | null;
    onSetCollectionsHandler?: (collectionValue: string) => void;
    premises?: IPremise[] | null;
    premiseFilter?: string | null;
    onSetPremisesHandler?: (premisesValue: string) => void;
}

const Categories: FC<CategoriesProps> = ({
    searchValue,
    onSetCategoryHandler,
    categoryFilter,
    categoryData,
    showMobileFilters,
    onSetShowMobileFilters,
    onSetSearchValueHandler,
    producers,
    producerFilter,
    onSetProducersHandler,
    collections,
    collectionFilter,
    onSetCollectionsHandler,
    premises,
    premiseFilter,
    onSetPremisesHandler,
}) => {

    return (
        <Container>
            <WebCategories
                categoryFilter={categoryFilter}
                categoryData={categoryData}
                onSetCategoryHandler={onSetCategoryHandler}
            />
            <MobileFilters
                searchValue={searchValue}
                onSetSearchValueHandler={onSetSearchValueHandler}
                categoryFilter={categoryFilter}
                categories={categoryData}
                showMobileFilters={showMobileFilters}
                onSetShowMobileFilters={onSetShowMobileFilters}
                onSetCategoryHandler={onSetCategoryHandler}
                producers={producers}
                producerFilter={producerFilter}
                onSetProducersHandler={onSetProducersHandler}
                collections={collections}
                collectionFilter={collectionFilter}
                onSetCollectionsHandler={onSetCollectionsHandler}
                premises={premises}
                premiseFilter={premiseFilter}
                onSetPremisesHandler={onSetPremisesHandler}
            />
        </Container>
    )
}

export default Categories;