import { useGlobalState } from "../context/context"

export const useModal = () => {


    const { dispatch } = useGlobalState()


    const open = (content: React.ReactNode) => {

        dispatch({
            'type': 'setModalContent',
            'modalContent': content
        })

        dispatch({
            'type': 'setShowModal',
            'showModal': true,
        })

    }


    const close = () => {

        dispatch({
            'type': 'setShowModal',
            'showModal': false,
        })

    }


    return {
        open,
        close,
    }
}