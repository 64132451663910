import { FC } from "react";
import styles from './showroomComponent.module.scss'
import { useNavigate } from "react-router-dom";
import { Button, Card, Chip } from "@mui/material";
import { useGlobalState } from "../../../../../../../../context/context";


interface ShowroomComponentProps {

}

const ShowroomComponent: FC<ShowroomComponentProps> = () => {
    const navigate = useNavigate()
    const { state } = useGlobalState()

    const setShowroomHandler = (showroom: string) => {
        const confirm = window.confirm("Czy na pewno chcesz zmienić salon?")
        if (!confirm) return;
        localStorage.setItem('selectedShowroom', showroom);
        window.location.reload();
    }

    const logoutHandler = () => {
        const confirm = window.confirm('Czy na pewno chcesz się wylogować?')
        if (!confirm) return;
        localStorage.removeItem('fakeTokenMinsk');
        localStorage.removeItem('fakeTokenPiaseczno');
        window.location.reload();
    }

    return (
        <>
            <div className={styles.selectShowroomContainer}>

                {localStorage.getItem('selectedShowroom') === 'minsk' ? (
                    <div className={styles.selectedShowroomContainer}>
                        <div className={styles.selectedShowroom}>
                            Mińsk Mazowiecki
                        </div>
                        <div
                            className={styles.unselectedShowroom}
                            onClick={() => { setShowroomHandler('piaseczno') }}
                        >
                            | Piaseczno
                        </div>
                    </div>

                ) : (

                    <div
                        className={styles.selectedShowroomContainer}
                    >
                        <div
                            className={styles.selectedShowroom}
                        >
                            Piaseczno
                        </div>
                        <div
                            className={styles.unselectedShowroom}
                            onClick={() => { setShowroomHandler('minsk') }}>
                            | Mińsk Mazowiecki
                        </div>
                    </div>
                )}
            </div>

            {
                (state.isAuthMinsk || state.isAuthPiaseczno) &&
                <Card className={styles.adminDiv}>
                    <div>
                        {
                            (localStorage.getItem('fakeTokenMinsk') ||
                                localStorage.getItem('fakeTokenPiaseczno'))
                            &&
                            <>
                                <Button
                                    variant="outlined"
                                    color='success'
                                    onClick={() => navigate('/adminPanel')}
                                >
                                    Panel Admina
                                </Button>

                                <Button
                                    style={{ marginLeft: '5px' }}
                                    variant="outlined"
                                    onClick={logoutHandler}
                                    color='error'
                                >
                                    WYLOGUJ
                                </Button>
                            </>
                        }
                    </div>

                    <div className={styles.loggedIn}>
                        Zalogowano w:
                        {
                            state.isAuthMinsk &&
                            <Chip
                                color='primary'
                                className={styles.showroomChip}
                                label="Mińsk mazowiecki"
                            />
                        }
                        {
                            state.isAuthPiaseczno &&
                            <Chip
                                color='secondary'
                                className={styles.showroomChip}
                                label="Piaseczno"
                            />
                        }
                    </div>
                </Card>
            }
        </>
    )
}

export default ShowroomComponent;