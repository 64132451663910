import styles from './premises.module.scss';
import { premisesData } from './premisesData';
import Tile from '../../components/_common/Tile/Tile';
import Layout from '../../components/layout/Layout';
import { FC } from 'react';


interface PremisesProps {

}


const Premises: FC<PremisesProps> = () => {
    return (
        <Layout title='Pomieszczenia'>
            <div className={styles.general}>
                {
                    !premisesData || !Array.isArray(premisesData) ? (

                        <div>
                            Nie dodano pomieszczeń
                        </div>

                    ) : (

                        premisesData.map((premise, index) => {
                            return (
                                <Tile key={index} {...premise} link={`/meble?premiseName=${premise.name}`} />
                            )
                        })
                    )
                }
            </div>
        </Layout>
    )
}

export default Premises;