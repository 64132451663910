import { DoneOutline, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails, Card, Icon } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import LoadingAnimation from "../../../../_common/Loading";
import { IProducer } from "../../../../../interfaces/IProducer";
import producersController from "../../../../../Api/producersController";
import styled from "@emotion/styled";
import { getShowroomUrl } from "../../../../../services/getShowroomUrl";

const BASE_URL = getShowroomUrl();

const ProducerImage = styled.img`
    width: 200px;
    height: 100px;
    object-fit: contain;
`

const ProducersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
`

interface ProducerProps {
    selectedProducerId: string,
    producerName?: string,
    expanded: string | false,
    onSetProducer: (producerId: string) => void,
    onSetExpanded: (accordionName: string) => void,
    onClickAccordion: (accordionName: string) => void,
}

const Producer: FC<ProducerProps> = ({
    selectedProducerId,
    expanded,
    onClickAccordion,
    onSetExpanded,
    onSetProducer,
}) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [producersData, setProducersData] = useState<IProducer[] | null>(null)

    const getProducers = useCallback(async () => {
        const res = await producersController.getAllProducers();
        if (res.ok) {
            setProducersData(res.producers);
        }
        setLoading(false);
    }, [])

    useEffect(() => {
        getProducers();
    }, [getProducers])


    const renderPremises = () => {
        if (loading) return <LoadingAnimation />

        if (!producersData || producersData.length === 0) {
            return <>Brak danych producentów</>
        }

        return (
            <div>
                <ProducersContainer>
                    {producersData.map((producerItem) => (
                        <Card
                            key={producerItem._id}
                            sx={{
                                m: 1,
                                p: 2,
                                '&:hover': {
                                    transform: 'scale(1.15)',
                                },
                                transition: 'transform 0.2s, box-shadow 0.2s',
                                cursor: 'pointer',
                                boxShadow: (producerItem._id === selectedProducerId
                                    ?
                                    '0px 4px 20px rgba(0, 42, 255, 0.5)'
                                    :
                                    '0px 1px 3px rgba(0, 0, 0, 0.2)')
                            }}
                            onClick={() => onSetProducer(producerItem._id)}
                        >
                            <ProducerImage
                                src={`${BASE_URL}${producerItem.logo}`}
                                alt={producerItem.name}
                            />
                        </Card>))
                    }
                </ProducersContainer>
            </div>
        )
    }

    return (
        <Accordion expanded={expanded === 'Producent'}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
                onClick={() => onClickAccordion('Producent')}
            >
                1. Producent
                {selectedProducerId &&
                    <Icon sx={{ ml: 1 }}>
                        <DoneOutline color="success" />
                    </Icon>
                }
            </AccordionSummary>
            <AccordionDetails>
                {renderPremises()}
            </AccordionDetails>
        </Accordion>
    )
}

export default Producer;