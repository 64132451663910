import { FC, useState } from "react";
import { ICategory } from "../../../../../../interfaces/ICategory";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ListItemButton, ListItem, ListItemText, Collapse, List, styled } from "@mui/material";


interface CategoryPartComponentProps {
    category: ICategory;
    showCategories: boolean;
    categoryFilter: string;
    onHandleClickCategory: (categoryName: string) => () => void;
}

const CategoryPartComponent: FC<CategoryPartComponentProps> = ({
    category,
    showCategories,
    categoryFilter,
    onHandleClickCategory,
}) => {
    const [showSubcategory, setShowSubcategory] = useState<boolean>(false);

    if (category.subcategories) return (
        <>
            <ListItemButton
                sx={{ pl: 4, pt: 0, pb: 0 }}
                onClick={() => setShowSubcategory(!showSubcategory)}
                key={category.name}
            >
                <ListItem>
                    <ListItemText primary={category.name} />
                    {showSubcategory ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            </ListItemButton>
            <Collapse in={showCategories && showSubcategory} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 8 }} key={category.name}>
                        <ListItem>
                            <ListItemText
                                sx={{ color: categoryFilter === category.name ? 'rgba(0, 21, 255, 0.658)' : '' }}
                                primary={'Wszystkie ' + category.name}
                                onClick={onHandleClickCategory(category.name)}
                            />
                        </ListItem>
                    </ListItemButton>
                    {category.subcategories.map((subcategory) => (
                        <ListItemButton sx={{ pl: 8 }} key={subcategory.name}>
                            <ListItem>
                                <ListItemText
                                    sx={{ color: categoryFilter === subcategory.name ? 'rgba(0, 21, 255, 0.658)' : '' }}
                                    primary={subcategory.name}
                                    onClick={onHandleClickCategory(subcategory.name)}
                                />
                            </ListItem>
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
        </>
    )

    return (
        <ListItemButton
            sx={{ pl: 4, pt: 0, pb: 0 }}
            key={category.name}
            >
            <ListItem>
                <ListItemText
                    sx={{ color: categoryFilter === category.name ? 'rgba(0, 21, 255, 0.658)' : '' }}
                    primary={category.name}
                    onClick={onHandleClickCategory(category.name)}
                />
            </ListItem>
        </ListItemButton>
    )
}

export default CategoryPartComponent;