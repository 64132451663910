import { Typography } from "@mui/material";
import { FC } from "react";

interface DescriptionComponentProps {
    description: string;
}

const DescriptionComponent: FC<DescriptionComponentProps> = ({
    description,
}) => {

    return (
        <>
            <Typography variant='h6' sx={{ pb: 2 }}>
                Opis:
            </Typography>
            <Typography variant='body1' sx={{ whiteSpace: 'pre-line' }}>
                <span dangerouslySetInnerHTML={{ __html: description.replace(/<img[^>]*>/g, "") }} />
            </Typography>
        </>
    )
}

export default DescriptionComponent;