import { FC, useState } from "react";
import CustomInput from "../../../components/_common/CustomInput";
import styled from "@emotion/styled";
import { Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, Typography } from "@mui/material";
import CustomButton from "../../../components/_common/CustomButton";
import FormHelper from "../../../helpers/form.helper";
import { useGlobalState } from "../../../context/context";
import EmailsController, { ISendForEstimationDto } from "../../../Api/emailsController";
import LoadingAnimation from "../../../components/_common/Loading";
import 'react-toastify/dist/ReactToastify.css';
import { useToast } from "../../../hooks/useToast";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../../hooks/useCart";
import RegulationsAlert from "../../../components/_common/RegulationsAlert";


const MyInput = styled(CustomInput)`
    width: 100%;
    margin-top: 20px;
`

const MyDivider = styled(Divider)`
    margin-top: 20px;
`

const RegulationsButton = styled(Button)`
    padding: 2px 2px 1px 2px;
    line-height: 1;
    margin: 0 3px;
`

interface CustomerFormProps {

}

const CustomerForm: FC<CustomerFormProps> = () => {
    const [email, setEmail] = useState<string>("")
    const [phone, setPhone] = useState<string>("")
    const [message, setMessage] = useState<string>("")
    const [regulationsAgreement, setRegulationsAgreement] = useState<boolean>(false)
    const [showRegulations, setShowRegulations] = useState<boolean>(false)
    const [showErrors, setShowErrors] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const { state } = useGlobalState()
    const cartItems = state.basketFurniture
    const toast = useToast()
    const navigate = useNavigate()
    const cart = useCart()

    const handleClickButton = async () => {
        setLoading(true)

        setShowErrors(true)
        if (
            !FormHelper.isEmail(email) ||
            !FormHelper.isPhoneNumber(phone) ||
            !regulationsAgreement
        ) {
            setLoading(false)
            return toast.error('Wypełnij poprawnie wszystkie pola')
        }

        const data: ISendForEstimationDto = {
            email,
            phone,
            message,
            cartItems,
            phoneQuote: false,
        }

        const res = await EmailsController.sendForEstimation(data)

        setLoading(false)
        if (!res.ok) return toast.error(res.message)

        cart.clearCart()
        toast.success(res.message)
        navigate('/')
    }

    if (loading) return <LoadingAnimation />

    return (
        <Box
            component='form'
            autoComplete="on"
        >
            <Typography variant="h6">
                Dane kontaktowe
            </Typography>
            <MyInput
                id="email"
                mode="email"
                error={showErrors && (FormHelper.isEmail(email) ? false : true)}
                value={email}
                setValue={setEmail}
                variant='filled'
            />
            <MyInput
                id="phone"
                mode="tel"
                error={showErrors && (FormHelper.isPhoneNumber(phone) ? false : true)}
                value={phone}
                setValue={setPhone}
                variant='filled'
            />
            <MyDivider variant="middle" />
            <MyInput
                mode="multiline"
                value={message}
                setValue={setMessage}
                variant="filled"
                maxRows={10}
                label="Wpisz tutaj dodatkowe informacje"
            />
            <MyDivider variant="middle" />
            <FormGroup sx={{ mt: 2 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={regulationsAgreement}
                            onChange={() => {
                                regulationsAgreement ? setRegulationsAgreement(false) : setShowRegulations(true)
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={
                        <Typography>
                            * Akceptuję
                            <RegulationsButton
                                variant='outlined' color="secondary"
                                onClick={() => { setShowRegulations(true) }}
                            >
                                Regulamin
                            </RegulationsButton>
                            sprzedaży produktów oferowanych przez spółkę UFO Sp. z o.o.
                        </Typography>
                    }
                />
            </FormGroup>
            <CustomButton
                disabled={!regulationsAgreement}
                text="Zamów wycenę"
                onClick={handleClickButton}
                sx={{ width: '100%', marginTop: '10px' }}
            />
            <RegulationsAlert
                open={showRegulations}
                handleSetOpen={setShowRegulations}
                handleAgree={() => setRegulationsAgreement(true)}
                handleDisagree={() => setRegulationsAgreement(false)}
            />
        </Box>
    )
}

export default CustomerForm;