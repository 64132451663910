import Lottie from "lottie-react";
import { FC } from "react";
import styled from "styled-components";
import loadingAnimation from "../../assets/animations/Morphing.json";

const AnimationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
 `

interface LoadingAnimationProps {
    style?: React.CSSProperties;
    loadingText?: string;
}

const LoadingAnimation: FC<LoadingAnimationProps> = ({
    style,
    loadingText,
}) => {
    return (
        <AnimationContainer>
            <div>
                <Lottie
                    style={style || {
                        maxWidth: '250px',
                        maxHeight: '250px',
                        padding: '50px',
                        paddingBottom: `${loadingText ? '0' : '50px'}`,
                    }}
                    animationData={loadingAnimation}
                    loop={true}
                />
                {loadingText &&
                    <div style={{ textAlign: 'center', paddingBottom: '50px' }}>
                        {loadingText}
                    </div>
                }
            </div>
        </AnimationContainer>
    )
}

export default LoadingAnimation;