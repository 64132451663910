import { FC, ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import Navigation from './components/Navigation/Navigation';
import Footer from './components/Footer/Footer';

const Children = styled.div`
  @media (max-width: 940px) {
    margin-top: 75px;
  }
`;

interface LayoutProps {
  title: string;
  children: ReactNode;
  onHandleClickSearchIcon?: () => void;
}

const Layout: FC<LayoutProps> = ({ title, children, onHandleClickSearchIcon }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <Navigation onHandleClickSearchIcon={onHandleClickSearchIcon}/>
      <Children>{children}</Children>
      <Footer />
    </>
  );
};

export default Layout;