import { Divider, Typography, Card, Table, TableCell } from "@mui/material";
import { FC, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import collectionsController from "../../../../Api/collectionsController";
import producersController from "../../../../Api/producersController";
import { useCart } from "../../../../hooks/useCart";
import { ICollection } from "../../../../interfaces/ICollection";
import { IProducer } from "../../../../interfaces/IProducer";
import { getShowroomUrl } from "../../../../services/getShowroomUrl";
import CustomButton from "../../CustomButton";
import styled from "@emotion/styled";


const BASE_URL = getShowroomUrl();

const MyDivider = styled(Divider)`
    margin-top: 15px;
    margin-bottom: 10px;
`

const MyButton = styled(CustomButton) <{ isInCart?: boolean }>`
    background-color: ${({ isInCart }) => isInCart ? 'grey' : '#283890'};
    width: 100%;
    margin-top: 10px;
    padding: 0 10;
    font-size: 0.74rem;
`

const CustomImage = styled('img')`
    margin-top: 20px;
    max-height: 170px;
    object-fit: contain;
`

interface DetailsComponentProps {
    id: string,
    name: string,
    image: string,
    producerId?: string | null,
    partCollection?: string | null,
    price?: number | null,
    width?: number | null,
    height?: number | null,
    depth?: number | null,
}

const DetailsComponent: FC<DetailsComponentProps> = ({
    id,
    name,
    producerId,
    partCollection,
    price,
    width,
    height,
    depth,
    image,
}) => {
    const [producer, setProducer] = useState<IProducer | null>(null)
    const [collection, setCollection] = useState<ICollection | null>(null)
    const navigate = useNavigate();
    const cart = useCart();
    const pageURL = window.location.origin;

    const getProducer = useCallback(async () => {
        if (!producerId) return;
        const res = await producersController.getSingleProducer(producerId);

        if (res.ok) {
            setProducer(res.producer);
        }
    }, [producerId])


    const getCollection = useCallback(async () => {
        if (!partCollection) return;
        const res = await collectionsController.getSingleCollection(partCollection);

        if (res.ok) {
            setCollection(res.collection);
        }
    }, [partCollection])


    const handleClickAddToCart = () => {
        if (cart.isInCart(id)) {
            cart.remove(id);
        } else {
            cart.add({
                type: 'furniture',
                name: name,
                id: id,
                image: `${BASE_URL}${image}`,
                link: `${pageURL}/mebel/${id}`
            })
        }
    }

    useEffect(() => {
        getCollection();
    }, [getCollection])

    useEffect(() => {
        getProducer();
    }, [getProducer])


    return (
        <>
            <Typography variant="h5" sx={{ textAlign: 'center' }}>
                {name}
            </Typography>
            <MyDivider />
            {(price && price !== 0) ?
                <>
                    <Typography variant="h4" sx={{ textAlign: 'center' }}>
                        {price} zł
                    </Typography>
                    <MyDivider />
                </> : <></>
            }
            <Card>
                {(width && width !== 0) ?
                    <Table sx={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <TableCell align="left">Szerokość:</TableCell>
                                <TableCell align="right">{width}</TableCell>
                            </tr>
                        </tbody>
                    </Table> : <></>
                }
                {(height && height !== 0) ?
                    <Table sx={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <TableCell align="left">Wysokość:</TableCell>
                                <TableCell align="right">{height}</TableCell>
                            </tr>
                        </tbody>
                    </Table> : <></>
                }
                {(depth && depth !== 0) ?
                    <Table sx={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <TableCell align="left">Głębokość:</TableCell>
                                <TableCell align="right">{depth}</TableCell>
                            </tr>
                        </tbody>
                    </Table> : <></>
                }
            </Card>
            {(!price || price === 0) &&
                <>
                    <MyButton
                        isInCart={cart.isInCart(id)}
                        onClick={handleClickAddToCart}
                        text={cart.isInCart(id) ? "Usuń z koszyka" : "Dodaj do koszyka"}
                    />
                    <MyDivider />
                </>
            }
            {producer &&
                <>
                    <Card sx={{ p: 2, pt: 0, mt: 2 }}>
                        <CustomImage
                            src={`${BASE_URL}${producer.logo}`}
                            alt={producer.name}
                            style={{ width: '100%' }}
                        />
                        <MyButton
                            onClick={() => navigate(`/meble?producerId=${producerId}`)}
                            text="Wszystkie meble tego producenta"
                        />
                        {/* <MyButton
                        onClick={() => { }}
                        text="Odwiedź stronę producenta"
                    /> */}
                    </Card>
                    {collection && <MyDivider />}
                </>
            }
            {collection &&
                <>
                    <Card sx={{ p: 2, pt: 0, mt: 2 }}>
                        <CustomImage
                            src={`${BASE_URL}${collection.image}`}
                            alt={collection.name}
                            style={{ width: '100%' }}
                        />
                        <MyButton
                            onClick={() => navigate(`/meble?producerId${producerId}&collectionId=${partCollection}`)}
                            text="Zobacz całą kolekcję"
                        />
                    </Card>
                </>
            }
        </>
    )
}

export default DetailsComponent;