import { FC, useCallback, useEffect, useRef, useState } from "react";
import FormLayout from "../FormLayout";
import { ICollection } from "../../../interfaces/ICollection";
import { useToast } from "../../../hooks/useToast";
import { useModal } from "../../../hooks/useModal";
import { Card, CardContent, ImageList } from "@mui/material";
import LoadingAnimation from "../../_common/Loading";
import styled from "@emotion/styled";
import { getShowroomUrl } from "../../../services/getShowroomUrl";
import collectionsController from "../../../Api/collectionsController";
import furnitureController from "../../../Api/furnitureController";
import emptyImage from '../../../assets/img/emptyImage.png'

const BASE_URL = getShowroomUrl();

const CollectionImage = styled.img`
    margin-top: 20px;
    width: 200px;
    height: 100px;
    object-fit: contain;
`

const CollectionsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
`


interface EditCollectionFormProps {
    collectionId?: string;
    furnitureId?: string;
    producerId?: string;
    onRefreshFurniture: () => void;
}

const EditCollectionForm: FC<EditCollectionFormProps> = ({
    collectionId,
    furnitureId,
    producerId,
    onRefreshFurniture,
}) => {
    const [collectionsList, setCollectionsList] = useState<ICollection[] | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useRef(useToast()).current;
    const modal = useModal();


    const handleEditCollection = async (collectionId: string) => {
        setLoading(true);
        if (!furnitureId) {
            toast.error('Błąd edycji kolekcji');
            setLoading(false);
            return
        }
        const furnitureData = {
            collectionId,
            furnitureId,
        }
        const res = await furnitureController.editCollection(furnitureData);
        if (!res.ok) {
            setLoading(false);
            return toast.error(res.message);
        }
        setLoading(false);
        onRefreshFurniture();
        modal.close();
    }


    const getCollections = useCallback(async () => {
        setLoading(true);
        if (!producerId) {
            toast.error('Nie znaleziono kolekcji');
            setLoading(false);
            return;
        }
        const res = await collectionsController.getAllProducerCollections(producerId);
        if (res.ok) {
            setCollectionsList(res.collections);
        }
        setLoading(false);
    }, [producerId, toast])


    useEffect(() => {
        getCollections();
    }, [getCollections])


    if (loading) return (
        <Card>
            <LoadingAnimation />
        </Card>
    )

    if (!collectionsList) return (
        <FormLayout
            title="Błąd"
        >
            Błąd ładowania kolekcji
        </FormLayout>
    )

    return (
        <FormLayout
            title="Edytuj kolekcję"
            maxWidth="xl"
        >
            <ImageList
                sx={{
                    pt: 4,
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                }}
            >
                <CollectionsContainer style={{ userSelect: 'none' }}>
                    <Card
                        sx={{
                            m: 1,
                            p: 1,
                            '&:hover': {
                                transform: 'scale(1.15)',
                            },
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            cursor: 'pointer',
                            boxShadow: ('' === collectionId ? '0px 4px 20px rgba(0, 42, 255, 0.5)' : '0px 1px 3px rgba(0, 0, 0, 0.2)')
                        }}
                        onClick={() => handleEditCollection('')}
                    >
                        <CollectionImage src={emptyImage} />
                        <CardContent sx={{ textAlign: 'center' }}>
                            Brak kolekcji
                        </CardContent>
                    </Card>
                    {collectionsList.map((collectionItem) => (
                        <Card
                            key={collectionItem._id}
                            sx={{
                                m: 1,
                                p: 1,
                                '&:hover': {
                                    transform: 'scale(1.15)',
                                },
                                transition: 'transform 0.2s, box-shadow 0.2s',
                                cursor: 'pointer',
                                boxShadow: (collectionItem._id === collectionId ? '0px 4px 20px rgba(0, 42, 255, 0.5)' : '0px 1px 3px rgba(0, 0, 0, 0.2)')
                            }}
                            onClick={() => handleEditCollection(collectionItem._id)}
                        >
                            <CollectionImage
                                src={`${BASE_URL}${collectionItem.image}`}
                                alt={collectionItem.name}
                            />
                            <CardContent sx={{ textAlign: 'center' }}>
                                {collectionItem.name}
                            </CardContent>
                        </Card>))
                    }
                </CollectionsContainer>
            </ImageList>
        </FormLayout>
    )
}

export default EditCollectionForm;