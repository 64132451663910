import styled from "@emotion/styled";
import { Button, Card } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { getShowroomUrl } from "../../../../../../services/getShowroomUrl";
import CustomButton from "../../../../../../components/_common/CustomButton";
import { useModal } from "../../../../../../hooks/useModal";
import EditProducerForm from "../../../../../../components/forms/furniture/EditProducerForm";

const BASE_URL = getShowroomUrl()

const CustomImage = styled('img')`
    margin-top: 20px;
    max-height: 170px;
    object-fit: contain;
`

const MyButton = styled(CustomButton) <{ isInCart?: boolean }>`
    background-color: ${({ isInCart }) => isInCart ? 'grey' : '#283890'};
    width: 100%;
    margin-top: 10px;
    padding: 0 10;
    font-size: 0.74rem;
`

interface ProducerComponentProps {
    name?: string,
    logo?: string,
    producerId?: string,
    showEditTools: boolean,
    furnitureId: string,
    onRefreshFurniture: () => void;
}

const ProducerComponent: FC<ProducerComponentProps> = ({
    name,
    logo,
    producerId,
    showEditTools,
    furnitureId,
    onRefreshFurniture,
}) => {
    const navigate = useNavigate();
    const modal = useModal();


    return (
        <>
            {name ?
                <>
                    <Card sx={{ p: 2, pt: 0, mt: 2 }}>
                        <CustomImage
                            src={`${BASE_URL}${logo}`}
                            alt={name}
                            style={{ width: '100%' }}
                        />
                        {showEditTools ?
                            <Button
                                sx={{ width: '100%', mt: 1 }}
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={() => modal.open(
                                    <EditProducerForm
                                        furnitureId={furnitureId}
                                        producerId={producerId}
                                        onRefreshFurniture={onRefreshFurniture}
                                    />
                                )}
                            >
                                Edytuj producenta
                            </Button>
                            :
                            <MyButton
                                onClick={() => navigate(`/meble?producerId=${producerId}`)}
                                text="Wszystkie meble tego producenta"
                            />
                        }
                        {/* <MyButton
                        onClick={() => { }}
                        text="Odwiedź stronę producenta"
                    /> */}
                    </Card>
                </>
                :
                <>

                </>
            }
        </>
    )
}

export default ProducerComponent;