import { FC } from "react";
import { CartItem, useCart } from "../../../hooks/useCart";
import { IconButton, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";


const FurnitureImage = styled.img`
    width: 150px;
    height: 150px;
    object-fit: contain;
    border-radius: 5px;
`;


interface FurnitureItemProps {
    furnitureItem: CartItem,
}

const FurnitureItem: FC<FurnitureItemProps> = ({
    furnitureItem,
}) => {
    const cart = useCart();
    const navigate = useNavigate();


    const handleClickName = () => {
        if (furnitureItem.type === 'furniture') {
            navigate(`/mebel/${furnitureItem.id}`)
        }

        if (furnitureItem.type === 'imported furniture') {
            navigate(`/mebel-importowany/${furnitureItem.id}`)
        }
    }


    return (
        <ListItem sx={{ p: 1, userSelect: 'none' }}>
            <ListItemAvatar>
                <FurnitureImage
                    src={furnitureItem.image}
                    alt={furnitureItem.name}
                />
            </ListItemAvatar>
            <ListItemText
                onClick={handleClickName}
                sx={{ marginLeft: 3, cursor: 'pointer'}}
                primary={furnitureItem.name}
            />
            <IconButton color="error" onClick={() => cart.remove(furnitureItem.id)}>
                <Close />
            </IconButton>
        </ListItem>
    )
}

export default FurnitureItem;