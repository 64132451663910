import { FC } from "react";
import WebNavigation from "./Components/WebNavigation/WebNavigation";
import MobileNavigation from "./Components/MobileNavigation";


interface NavigationProps {
    onHandleClickSearchIcon?: () => void,
}


const Navigation: FC<NavigationProps> = ({
    onHandleClickSearchIcon,
}) => {
    return (
        <>
            <WebNavigation />
            
            <MobileNavigation onHandleClickSearchIcon={onHandleClickSearchIcon}/>
        </>
    )
}

export default Navigation;