import { FC, useRef, useState } from "react";
import FormLayout from "../../FormLayout";
import Producer from "./components/Producer";
import Collection from "./components/Collection";
import Details from "./components/Details";
import Premises from "./components/Premises";
import Categories from "./components/Categories";
import { useToast } from "../../../../hooks/useToast";
import CustomButton from "../../../_common/CustomButton";
import { Card, Divider } from "@mui/material";
import LoadingAnimation from "../../../_common/Loading";
import furnitureController, { IAddFurnitureDto } from "../../../../Api/furnitureController";
import { useModal } from "../../../../hooks/useModal";

interface AddFurnitureFormProps {

}

const AddFurnitureForm: FC<AddFurnitureFormProps> = () => {
    const [expanded, setExpanded] = useState<string | false>('Producent')
    const [loading, setLoading] = useState<boolean>(false)
    const [name, setName] = useState<string>('')
    const [producer, setProducer] = useState<string>('')
    const [partCollection, setPartCollection] = useState<string>('')
    const [price, setPrice] = useState<number>(0)
    const [width, setWidth] = useState<number>(0)
    const [depth, setDepth] = useState<number>(0)
    const [height, setHeight] = useState<number>(0)
    const [crossed, setCrossed] = useState<number>(0)
    const [isPriceVissible, setIsPriceVissible] = useState<boolean>(false)
    const [premises, setPremises] = useState<string[]>([])
    const [furnitureCategories, setFurnitureCategories] = useState<string[]>([])
    const [images, setImages] = useState<any[]>([])
    const [description, setDescription] = useState<string>('')
    const toast = useRef(useToast()).current
    const modal = useModal()


    const accordionClickhandler = (accordionName: string) => {
        if (accordionName === expanded) return setExpanded(false)
        setExpanded(accordionName)
    }


    const handleSetProducer = (producerId: string) => {
        setProducer(producerId)
        setPartCollection('')
        setExpanded('Kolekcja')
    }


    const handleSetCollection = (collectionId: string) => {
        setPartCollection(collectionId)
        setExpanded('Szczegóły')
    }


    const handleChangePremiseState = (premiseItem: string) => {
        const premiseItemUpper = premiseItem.toUpperCase();
        if (premises?.map(name => name.toUpperCase()).includes(premiseItemUpper)) {
            setPremises(prevState => (prevState ?? []).filter((premise: string) => premise.toUpperCase() !== premiseItemUpper));
        } else {
            setPremises(prevState => [...(prevState ?? []), premiseItem]);
        }
    }


    const validate = name && producer && images.length > 0


    const handleSubmit = async () => {
        setLoading(true)
        if (!validate) {
            return toast.error('Dodaj niezbędne dane')
        }
        const furniture: IAddFurnitureDto = {
            name,
            producer,
            partCollection,
            price,
            width,
            depth,
            height,
            crossed,
            isPriceVissible,
            description,
            premises,
            furnitureCategories,
            images
        }
        const res = await furnitureController.addFurniture(furniture)
        setLoading(false)
        if (!res.ok) {
            toast.error(res.message)
            return
        }
        toast.success('Mebel został dodany')
        modal.close()
        window.location.href = `/mebel/${res.newFurniture?._id}`
    }


    if (loading) return (
        <Card>
            <LoadingAnimation loadingText="Zapisywanie mebla" />
        </Card>
    )

    return (
        <FormLayout
            title="Dodawanie mebla"
            maxWidth='xl'
        >
            <Producer
                expanded={expanded}
                onSetExpanded={setExpanded}
                selectedProducerId={producer}
                onSetProducer={handleSetProducer}
                onClickAccordion={accordionClickhandler}
            />
            <Collection
                partCollection={partCollection}
                expanded={expanded}
                disabled={!producer}
                producerId={producer}
                onSetExpanded={setExpanded}
                onClickAccordion={accordionClickhandler}
                onSetCollection={handleSetCollection}
            />
            <Details
                images={images}
                onSetImages={setImages}
                name={name}
                onSetName={setName}
                price={price}
                onSetPrice={setPrice}
                width={width}
                onSetWidth={setWidth}
                depth={depth}
                onSetDepth={setDepth}
                height={height}
                onSetHeight={setHeight}
                crossed={crossed}
                onSetCrossed={setCrossed}
                isPriceVissible={isPriceVissible}
                onSetIsPriceVissible={setIsPriceVissible}
                description={description}
                onSetDescription={setDescription}
                expanded={expanded}
                onSetExpanded={setExpanded}
                onClickAccordion={accordionClickhandler}
            />
            <Premises
                expanded={expanded}
                onSetExpanded={setExpanded}
                onClickAccordion={accordionClickhandler}
                premises={premises}
                onSetPremises={handleChangePremiseState}
            />
            <Categories
                onSetExpanded={setExpanded}
                categoryState={furnitureCategories}
                onSetCategories={setFurnitureCategories}
                expanded={expanded}
                onClickAccordion={accordionClickhandler}
            />
            <Divider sx={{ m: 2 }} />
            <CustomButton
                disabled={!validate}
                onClick={() => handleSubmit()}
                text="Zapisz"
                sx={{ width: '100%' }}
            />
        </FormLayout>
    )
}

export default AddFurnitureForm;