import axios from "axios";
import { CartItem } from "../hooks/useCart";
import IResponse from "../interfaces/IResponse";
import { getShowroomUrl } from "../services/getShowroomUrl";

const BASE_URL = `${getShowroomUrl()}api/emails`;


export interface ISendForEstimationDto {
    email: string,
    phone: string,
    message: string,
    cartItems: CartItem[],
    phoneQuote?: boolean,
}

interface ISendForEstimationResponse extends IResponse {
}

const sendForEstimation = async (data: ISendForEstimationDto): Promise<ISendForEstimationResponse> => {
    const url = `${BASE_URL}/sendForEstimation`;
    try {
        const res = await axios.post(url, data)

        const resoult = {
            ok: true,
            status: res.status,
            message: 'Wysłano prośbę o wycenę',
        }

        return resoult;
    } catch (error) {
        let message = 'Nie udało się wysłać wyceny';
        let status = 400;

        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 403) message = 'Niedostępne'
        }

        return {
            message,
            status,
            ok: false,
        }
    }
}

const EmailsController = {
    sendForEstimation,
}

export default EmailsController;