import promotionalFurnitureController from "../../Api/promotionalFurnitureController";
import { useParams } from "react-router-dom";
import { FC, useCallback, useState } from "react";
import { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import "yet-another-react-lightbox/styles.css";
import LoadingAnimation from "../../components/_common/Loading";
import PromotionalFurnitureItem from "../../components/_common/PromotionalFurnitureItem/PromotionalFurnitureItem";
import { IPromotionalFurniture } from "../../interfaces/IPromotionalFurniture";

interface IParams {
  furnitureId?: string;
}

interface PromotionalPieceOfFurnitureProps { }

const PromotionalPieceOfFurniture: FC<PromotionalPieceOfFurnitureProps> = () => {
  const [pieceOfFurnitureData, setPieceOfFurnitureData] = useState<IPromotionalFurniture | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const params: IParams = useParams();


  const getPromotionalPieceOfFurniture = useCallback(async () => {
    setLoading(true);
    const furnitureId = params.furnitureId;
    if (!furnitureId) return;
    const res = await promotionalFurnitureController.getPieceOfFurniture(
      furnitureId
    );
    if (!res?.furniture) return null;
    setPieceOfFurnitureData(res.furniture);
    setLoading(false);
  }, [params.furnitureId])


  useEffect(() => {
    getPromotionalPieceOfFurniture();
  }, [
    getPromotionalPieceOfFurniture,
  ]);


  const renderPieceOfFurniture = () => {
    if (loading) return <LoadingAnimation />

    if (!pieceOfFurnitureData) return <div>Dany mebel nie znajduje się w bazie</div>


    return (
      <PromotionalFurnitureItem
        id={pieceOfFurnitureData._id}
        name={pieceOfFurnitureData.name}
        image={pieceOfFurnitureData.image}
        description={pieceOfFurnitureData.description}
        width={pieceOfFurnitureData.width}
        height={pieceOfFurnitureData.height}
        depth={pieceOfFurnitureData.depth}
        price={pieceOfFurnitureData.price}
      />
    )
  }

  return (
    <Layout title={pieceOfFurnitureData?.name || "Mebel"}>
      {renderPieceOfFurniture()}
    </Layout>
  );
}

export default PromotionalPieceOfFurniture;