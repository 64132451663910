import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { FC } from "react";


const MyCustomButton = styled(Button)`
    background-color: #283890;

    &:hover {
        background-color: #151e52;
    }
`


interface CustomButtonProps {
    text: string,
    onClick?: () => void,
    variant?: 'text' | 'contained' | 'outlined',
    sx?: any,
    className?: string,
    disabled?: boolean,
    href?: string,
    rel?: string,
    size?: 'small' | 'medium' | 'large',
}

const CustomButton: FC<CustomButtonProps> = ({
    text,
    variant,
    onClick,
    sx,
    className,
    disabled,
    href,
    rel,
    size,
}) => {

    const handleClick = () => {
        if (!onClick) return;
        onClick();
    }


    return (
        <MyCustomButton
            disabled={disabled}
            variant={variant || 'contained'}
            onClick={handleClick}
            sx={sx}
            className={className}
            href={href}
            rel={rel}
            size={size}
>
            {text}
        </MyCustomButton>
    )
}

export default CustomButton;