import { ProductionQuantityLimits, ShoppingCart } from "@mui/icons-material";
import { Badge, BadgeProps, Box, Divider, IconButton, List, Popover, Typography } from "@mui/material";
import { FC, useState } from "react";
import styled from "styled-components";
import { CartItem } from "../../../../../../../../hooks/useCart";
import { useGlobalState } from "../../../../../../../../context/context";
import BasketFurniture from "./components/BasketFurniture";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../../../../_common/CustomButton";


const Container = styled.div`

`;

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid`,
        padding: '0 4px',
    },
}));

const EmptyCartContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 0;
    padding: 20px;
`;

interface CartComponentProps {

}

const CartComponent: FC<CartComponentProps> = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { state } = useGlobalState();
    const navigate = useNavigate();
    const cartItems = state.basketFurniture;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <Container>
            <IconButton
                aria-label="cart"
                onClick={handleClick}
            >
                <StyledBadge badgeContent={cartItems?.length || 0} color="secondary">
                    <ShoppingCart />
                </StyledBadge>
            </IconButton>
            <Popover
                id="cart"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography
                    sx={{ p: 2, pb: 0 }}
                    variant="h6" gutterBottom>
                    Koszyk
                </Typography>
                <Divider variant='middle' />
                {(cartItems === null || cartItems.length === 0) ? (
                    <Box sx={{ p: 5 }}>
                        <Typography variant="h6" gutterBottom>
                            Twój koszyk jest pusty
                        </Typography>
                        <EmptyCartContainer>
                            <ProductionQuantityLimits
                                sx={{ width: 100, height: 100 }}
                            />
                        </EmptyCartContainer>
                    </Box>
                ) : (
                    <Box sx={{ width: 500, p: 2, pt: 0 }}>
                        <List>
                            {Array.isArray(cartItems) && cartItems.map((item: CartItem, index) => (
                                <div key={item.id}>
                                    <BasketFurniture
                                        key={item.id}
                                        type={item.type}
                                        id={item.id}
                                        image={item.image}
                                        name={item.name}
                                    />
                                    {index !== cartItems.length - 1 && <Divider variant="middle" />}
                                </div>
                            ))}
                        </List>
                        <ButtonContainer>
                            <CustomButton
                                onClick={() => navigate('/koszyk')}
                                variant='contained'
                                text='Przejdź do koszyka'
                            />
                        </ButtonContainer>
                    </Box>
                )}
            </Popover>
        </Container >
    )
}

export default CartComponent;