import { Box, Typography } from "@mui/material";
import { FC } from "react";
import CustomButton from "../../CustomButton";
import linkify from 'linkify-it';

interface DescriptionComponentProps {
    description: string;
}

const DescriptionComponent: FC<DescriptionComponentProps> = ({
    description,
}) => {
    const cleanedDescription = description.replace(/\\r\\n/g, '\n');
    const linkifyInstance = new linkify();
    const links = linkifyInstance.match(cleanedDescription);
    const link = links && links[0] && links[0].url;
    const [textBeforeLink, textAfterLink] = link ? cleanedDescription.split(link) : [cleanedDescription, ''];

    return (
        <>
            <Typography variant='h6' sx={{ pb: 2 }}>
                Opis:
            </Typography>
            <Typography variant='body1' sx={{ whiteSpace: 'pre-line' }}>
                {textBeforeLink}
                {link &&
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 2,
                    }}>
                        <CustomButton
                            href={link}
                            text='Sprawdź moduły'
                            rel="noopener noreferrer"
                            onClick={() => { }}
                        />
                    </Box>
                }
                {textAfterLink}
            </Typography>
        </>
    )
}

export default DescriptionComponent;