import styled from "@emotion/styled";
import { Container, Chip, Stack } from "@mui/material";
import { FC } from "react";
import { ResponsiveContainer } from "recharts";
import BarChartComponent from "./chartsComponents/BarChartComponent";
import LineChartComponent from "./chartsComponents/LineChartComponet";
import PieChartComponent from "./chartsComponents/PieChartComponent";
import AreaChartComponent from "./chartsComponents/AreaChartComponent";


const YearsViewContainer = styled.div`
    display: flex;
    justify-content: center;
    user-select: none;
    margin: 10px 0;
`

const CustomChip = styled(Chip) <{ isActive: boolean }>`
    background-color: ${({ isActive }) => isActive ? '#283890' : '#fff'};
    color: ${({ isActive }) => isActive ? '#fff' : '#283890'};
    border: 1px solid #283890;
    margin: 5px;
    cursor: pointer;

    &:hover {
        background-color: ${({ isActive }) => isActive ? '#283890' : '#ddd'};
        color: ${({ isActive }) => isActive ? '#272727' : '#283890'};
    }
`

interface ChartLayoutProps {
    data: any;
    selectedChart: 'BarChart' | 'LineChart' | 'PieChart' | 'AreaChart';
    yearToDisplay: number | null;
    onYearToDisplayChange: (year: number) => void;
}

const ChartLayout: FC<ChartLayoutProps> = ({
    data,
    selectedChart,
    yearToDisplay,
    onYearToDisplayChange,
}) => {

    if (!yearToDisplay) return (<></>)
    if (!data) return (<></>)

    const yearlySum = data[yearToDisplay]?.reduce((sum: number, month: any) => sum + month.pv, 0) || 0;


    const renderChart = () => {
        if (selectedChart === 'BarChart') {
            return <BarChartComponent data={data} yearToDisplay={yearToDisplay} />
        }

        if (selectedChart === 'LineChart') {
            return <LineChartComponent data={data} yearToDisplay={yearToDisplay} />
        }

        if (selectedChart === 'PieChart') {
            return <PieChartComponent data={data} yearToDisplay={yearToDisplay} />
        }

        if (selectedChart === 'AreaChart') {
            return <AreaChartComponent data={data} yearToDisplay={yearToDisplay} />
        }
    }

    return (
        <>
            <ResponsiveContainer width="100%" height="100%">
                <Container>
                    {renderChart()}
                </Container>
            </ResponsiveContainer>
            <YearsViewContainer>
                <Chip
                    color="primary"
                    label={`Wejścia w danym roku ${yearlySum}`}
                    variant="outlined"
                />
            </YearsViewContainer>
            <Stack direction="row" spacing={1}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    padding: '3px 40px'
                }}
            >
                {
                    Object.keys(data).map(Number).map((year) => (
                        <CustomChip
                            key={year}
                            label={year}
                            variant="outlined"
                            onClick={() => onYearToDisplayChange(year)}
                            isActive={year === yearToDisplay}
                        />
                    ))
                }
            </Stack>
        </>
    )
}

export default ChartLayout;