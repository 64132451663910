import { Search } from "@mui/icons-material";
import { Box, IconButton, TextField } from "@mui/material";
import { FC } from "react";
import styled from "styled-components";


const Searchbar = styled(TextField)`
    width: 100%;
`


const SearchbarContainer = styled(Box)`
    padding-left: 20px;
    display: flex;
    align-items: center;
    margin-top: 30px;
`

interface SearchbarComponentProps {
    newSearchValue: string;
    onSetNewSearchValue: (searchValue: string) => void;
    onHandleSearch: (searchValue: string) => void;
}

const SearchbarComponent: FC<SearchbarComponentProps> = ({
    newSearchValue,
    onSetNewSearchValue,
    onHandleSearch,
}) => {
    return (
        <SearchbarContainer>
            <Searchbar
                label="Wyszukaj..."
                variant="outlined"
                type="search"
                value={newSearchValue}
                onChange={(e) => onSetNewSearchValue(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        onHandleSearch(newSearchValue);
                    }
                }}
            />
            <IconButton onClick={() => onHandleSearch(newSearchValue)}>
                <Search />
            </IconButton>
        </SearchbarContainer>
    )
}

export default SearchbarComponent;