import { DoneOutline, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel, FormGroup, Divider, Icon } from "@mui/material";
import { FC, useState } from "react";
import { ICategory, IOpenedCategory } from "../../../../../interfaces/ICategory";
import { categoryData } from "../../../../../pages/Furniture/categoryData";
import CustomButton from "../../../../_common/CustomButton";
import React from "react";

interface CategoriesProps {
    categoryState: string[] | null,
    expanded: string | false,
    onClickAccordion: (accordionName: string) => void,
    onSetCategories: React.Dispatch<React.SetStateAction<string[]>>,
    onSetExpanded: React.Dispatch<React.SetStateAction<string | false>>,
}

const Categories: FC<CategoriesProps> = ({
    categoryState,
    expanded,
    onClickAccordion,
    onSetCategories,
    onSetExpanded,
}) => {
    const [showOk, setShowOk] = useState(false)

    const handleChangeCategory = (categoryItem: string) => {
        if (categoryState?.includes(categoryItem)) {
            handleDeleteSubcategories(categoryItem);
            onSetCategories(prevState => prevState.filter((category: string) => category !== categoryItem));
        } else {
            onSetCategories(prevState => [...prevState, categoryItem]);
        }
    }

    const handleDeleteSubcategories = (categoryItem: string) => {
        const subcategories = categoryData.find((category: ICategory) => category.name === categoryItem)?.subcategories;
        if (!subcategories) return;

        const subcategoryNames = subcategories.map((subcategory: IOpenedCategory) => subcategory.name);
        onSetCategories((prevState: string[]) => prevState.filter((category: string) => !subcategoryNames.includes(category)));
    }

    const handleChangeSubcategory = (categoryItem: string) => {
        if (categoryState?.includes(categoryItem)) {
            onSetCategories(prevState => prevState.filter((category: string) => category !== categoryItem));
        } else {
            onSetCategories(prevState => [...prevState, categoryItem]);
        }
    }


    return (
        <Accordion expanded={expanded === 'Kategorie'}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
                onClick={() => onClickAccordion('Kategorie')}
            >
                5. Kategorie
                {showOk &&
                    <Icon sx={{ ml: 1 }}>
                        <DoneOutline color="success" />
                    </Icon>
                }
            </AccordionSummary>
            <AccordionDetails>
                <FormGroup sx={{ mb: 5 }}>
                    {categoryData.map((categoryItem: ICategory) => (
                        <React.Fragment key={categoryItem.name}>
                            <FormControlLabel
                                key={categoryItem.name}
                                label={categoryItem.name}
                                control={
                                    <Checkbox
                                        checked={categoryState?.includes(categoryItem.name)}
                                        onChange={() => handleChangeCategory(categoryItem.name)}
                                    />
                                }
                            />
                            {(categoryItem.subcategories) &&
                                categoryItem.subcategories.map((subcategory: IOpenedCategory) => {
                                    if (!categoryState?.includes(categoryItem.name)
                                        &&
                                        !categoryState?.includes(subcategory.name)
                                    ) return null;

                                    return (
                                        <FormControlLabel
                                            key={subcategory.name}
                                            sx={{ ml: 2 }}
                                            label={subcategory.name}
                                            control={
                                                <Checkbox
                                                    checked={categoryState?.includes(subcategory.name)}
                                                    onChange={() => handleChangeSubcategory(subcategory.name)}
                                                />
                                            }
                                        />
                                    )
                                })}
                        </React.Fragment>
                    ))}
                </FormGroup>
                <Divider sx={{ m: 2 }} />
                <CustomButton
                    sx={{ width: '100%' }}
                    text="Dalej"
                    onClick={() => {
                        onSetExpanded(false);
                        setShowOk(true);
                    }}
                />
            </AccordionDetails>
        </Accordion>
    )
}

export default Categories;