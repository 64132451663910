import { FC } from "react";
import { IPremise } from "../../../../../../interfaces/IPremise";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ListItemText, ListItemButton, Collapse, List } from "@mui/material";


interface PremisesComponentProps {
    premises: IPremise[];
    premiseFilter?: string | null;
    showPremises: boolean;
    onSetShowPremises: (show: boolean) => void;
    onHandleClickPremise: (premiseName: string) => () => void;
}

const PremisesComponent: FC<PremisesComponentProps> = ({
    premises,
    showPremises,
    premiseFilter,
    onSetShowPremises,
    onHandleClickPremise,
}) => {
    return (
        <>
            <ListItemButton onClick={() => onSetShowPremises(!showPremises)} >
                <ListItemText primary="Pomieszczenia" />
                {showPremises ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={showPremises} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {premises.map((premise) => {
                        return (
                            <ListItemButton
                                sx={{ pl: 4 }}
                                key={premise.name}
                                onClick={onHandleClickPremise(premise.name)}
                            >
                                <ListItemText
                                    primary={premise.name}
                                    sx={{ color: premiseFilter === premise.name ? 'rgba(0, 21, 255, 0.658)' : '' }}
                                />
                            </ListItemButton>
                        )
                    }
                    )}
                </List>
            </Collapse>
        </>
    )
}

export default PremisesComponent;