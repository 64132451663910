import { FC, useCallback, useEffect, useState } from "react";
import LoadingAnimation from "../../../../../components/_common/Loading";
import { Box, Chip, Divider, Popover, Stack, Tab, Tabs, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import styled from "@emotion/styled";
import viewCounterController from "../../../../../Api/viewCountController";
import ChartLayout from "./components/ChartLayout";
import { BarChartRounded, DataUsageRounded, LegendToggleRounded, StackedLineChartRounded } from "@mui/icons-material";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}


const Container = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;
`

const CustomDivider = styled(Divider)`
    margin: 120px 0;
`

const CustomLabel = styled(Typography)`
    display: flex;
    justify-content: center;
    margin-top: 40px;
`

const SelectChartTypeContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`

const ProjectedVisitsContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;
`


interface ChartsProps {

}

const Charts: FC<ChartsProps> = () => {
    const [data, setData] = useState<any[]>([])
    const [weeklyData, setWeeklyData] = useState<number | null>(0)
    const [monthlyData, setMonthlyData] = useState<number | null>(0)
    const [dailyStatsPerYear, setDailyStatsPerYear] = useState<any | null>(null)
    const [hourlyStatsPerYear, setHourlyStatsPerYear] = useState<any | null>(null)
    const [hourlyStatsPerDayByYear, setHourlyStatsPerDayByYear] = useState<any | null>(null)
    const [yearToDisplay, setYearToDisplay] = useState<number | null>(null)
    const [viewCount, setViewCount] = useState<number | null>(0)
    const [loading, setLoading] = useState<boolean>(true)
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [selectedChart, setSelectedChart] = useState<'BarChart' | 'LineChart' | 'PieChart' | 'AreaChart'>('BarChart')
    const [showProjectsMonthlyInfo, setshowProjectsMonthlyInfo] = useState<HTMLElement | null>(null);


    const calculateProjectedMonthlyVisits = () => {
        if (!monthlyData) return
        const today = new Date();
        const currentDayOfMonth = today.getDate();
        const daysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
        const averageDailyVisits = monthlyData / currentDayOfMonth;
        return Math.round(averageDailyVisits * daysInMonth);
    };


    const projectedMonthlyVisits = calculateProjectedMonthlyVisits();


    const handleChangeChartType = (
        event: React.MouseEvent<HTMLElement>,
        chartType: 'BarChart' | 'LineChart' | 'PieChart' | 'AreaChart',
    ) => {
        setSelectedChart(chartType);
    };


    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };


    const getViews = useCallback(async () => {
        const res = await viewCounterController.getStatisticsData()

        if (!res.ok) {
            return setLoading(false);
        }

        if (!res.views) {
            return setLoading(false);
        }

        const latestYear = Math.max(...Object.keys(res.views).map(Number));
        setYearToDisplay(latestYear)
        setData(res.views)
        if (res.weeklyViews) setWeeklyData(res.weeklyViews)
        if (res.monthlyViews) setMonthlyData(res.monthlyViews)
        if (res.viewCount) setViewCount(res.viewCount)
        if (res.dailyStatsPerYear) setDailyStatsPerYear(res.dailyStatsPerYear)
        if (res.hourlyStatsPerYear) setHourlyStatsPerYear(res.hourlyStatsPerYear)
        if (res.hourlyStatsPerDayByYear) setHourlyStatsPerDayByYear(res.hourlyStatsPerDayByYear)
        setLoading(false)
    }, [])


    const handleOpenProjectsMonthlyInfo = (event: React.MouseEvent<HTMLElement>) => {
        setshowProjectsMonthlyInfo(event.currentTarget);
    };


    const handleCloseProjectsMonthlyInfo = () => {
        setshowProjectsMonthlyInfo(null);
    };


    const open = Boolean(showProjectsMonthlyInfo);


    useEffect(() => {
        getViews()
    }, [getViews])


    if (loading) {
        return (
            <Container>
                <LoadingAnimation loadingText="Ładowanie statystyk" />
            </Container>
        )
    }


    if (!data) {
        return (
            <Container>
                Błąd podczas pobierania statystyk
            </Container>
        )
    }

    return (
        <>
            <CustomDivider />
            <CustomLabel variant="h5" gutterBottom>
                Statystyki roczne z podziałem na:
            </CustomLabel>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={selectedTab}
                        onChange={handleChangeTab}
                        aria-label="basic tabs example"
                        centered
                    >
                        <Tab label="Miesiące" />
                        <Tab label="Dni tygodnia" />
                        <Tab label="Godziny" />
                        <Tab label="Godziny w dane dni" />
                    </Tabs>
                </Box>
                <SelectChartTypeContainer>
                    <ToggleButtonGroup
                        value={selectedChart}
                        exclusive
                        onChange={handleChangeChartType}
                        aria-label="chart type"
                    >
                        <ToggleButton value="BarChart" aria-label="left aligned">
                            <BarChartRounded />
                        </ToggleButton>
                        <ToggleButton value="LineChart" aria-label="left aligned">
                            <StackedLineChartRounded />
                        </ToggleButton>
                        <ToggleButton value="PieChart" aria-label="left aligned">
                            <DataUsageRounded />
                        </ToggleButton>
                        <ToggleButton value="AreaChart" aria-label="left aligned">
                            <LegendToggleRounded />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </SelectChartTypeContainer>
                <CustomTabPanel value={selectedTab} index={0}>
                    <ChartLayout
                        selectedChart={selectedChart}
                        data={data}
                        yearToDisplay={yearToDisplay}
                        onYearToDisplayChange={(year: number) => setYearToDisplay(year)}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={selectedTab} index={1}>
                    <ChartLayout
                        selectedChart={selectedChart}
                        data={dailyStatsPerYear}
                        yearToDisplay={yearToDisplay}
                        onYearToDisplayChange={(year: number) => setYearToDisplay(year)}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={selectedTab} index={2}>
                    <ChartLayout
                        selectedChart={selectedChart}
                        data={hourlyStatsPerYear}
                        yearToDisplay={yearToDisplay}
                        onYearToDisplayChange={(year: number) => setYearToDisplay(year)}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={selectedTab} index={3}>
                    <ChartLayout
                        selectedChart={selectedChart}
                        data={hourlyStatsPerDayByYear}
                        yearToDisplay={yearToDisplay}
                        onYearToDisplayChange={(year: number) => setYearToDisplay(year)}
                    />
                </CustomTabPanel>
            </Box>
            <CustomDivider />
            <Container>
                <h2>Statystyki</h2>
            </Container>
            <Stack direction="row" spacing={1}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    padding: '3px 40px'
                }}
            >
                <Chip color='success' label={`Wejścia w tym tygodniu: ${weeklyData}`} variant="outlined" />
                <Chip color='secondary' label={`Wejścia w tym miesiącu: ${monthlyData}`} variant="outlined" />
                <Chip color='warning' label={`Wejścia ogółem: ${viewCount}`} variant="outlined" />
            </Stack>
            <ProjectedVisitsContainer>
                <Chip
                    id="cart"
                    color='info'
                    label={`Prognoza na obecny miesiąc: ${projectedMonthlyVisits}`}
                    variant="outlined"
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handleOpenProjectsMonthlyInfo}
                    onMouseLeave={handleCloseProjectsMonthlyInfo}
                />
            </ProjectedVisitsContainer>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={showProjectsMonthlyInfo}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handleCloseProjectsMonthlyInfo}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1, textAlign: 'center' }}>
                    Prognoza na obecny miesiąc została obliczona na podstawie średniej ilości wejść dziennie w obecnym miesiącu.
                    <br />
                    Prognoza może być zawyżona lub zaniżona w pierwszych dniach miesiąca.
                </Typography>
            </Popover>
        </>
    )
}

export default Charts;