import { FC, useRef, useState } from "react";
import FormLayout from "../FormLayout";
import { categoryData } from "../../../pages/Furniture/categoryData";
import { ICategory, IOpenedCategory } from "../../../interfaces/ICategory";
import { Card, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import LoadingAnimation from "../../_common/Loading";
import furnitureController, { IEditFurnitureCategoriesDto } from "../../../Api/furnitureController";
import { useToast } from "../../../hooks/useToast";
import { useModal } from "../../../hooks/useModal";


interface EditCategoryFormProps {
    furnitureId: string,
    furnitureCategories?: string[] | null,
    onRefreshFurniture: () => void;
}

const EditCategoryForm: FC<EditCategoryFormProps> = ({
    furnitureId,
    furnitureCategories,
    onRefreshFurniture,
}) => {
    const [categoryState, setCategoryState] = useState<string[]>(furnitureCategories || []);
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useRef(useToast()).current;
    const modal = useModal();


    const handleChangeCategory = (categoryItem: string) => {
        if (categoryState?.includes(categoryItem)) {
            handleDeleteSubcategories(categoryItem);
            setCategoryState(prevState => prevState.filter((category: string) => category !== categoryItem));
        } else {
            setCategoryState(prevState => [...prevState, categoryItem]);
        }
    }

    const handleDeleteSubcategories = (categoryItem: string) => {
        const subcategories = categoryData.find((category: ICategory) => category.name === categoryItem)?.subcategories;
        if (!subcategories) return;

        const subcategoryNames = subcategories.map((subcategory: IOpenedCategory) => subcategory.name);
        setCategoryState(prevState => prevState.filter((category: string) => !subcategoryNames.includes(category)));
    }

    const handleChangeSubcategory = (categoryItem: string) => {
        if (categoryState?.includes(categoryItem)) {
            setCategoryState(prevState => prevState.filter((category: string) => category !== categoryItem));
        } else {
            setCategoryState(prevState => [...prevState, categoryItem]);
        }
    }


    const handleSaveCategories = async () => {
        setLoading(true);
        const furnitureData: IEditFurnitureCategoriesDto = {
            categories: categoryState,
            furnitureId: furnitureId,
        }
        const res = await furnitureController.editFurnitureCategories(furnitureData);
        if (!res.ok) {
            setLoading(false);
            return toast.error(res.message);
        }
        if (res.ok && res.editedFurniture?.furnitureCategories?.length === 0) {
            toast.error('Kategorie zapisane jako puste');
        }
        setLoading(false);
        onRefreshFurniture();
        modal.close();
    }


    if (loading) return (
        <Card>
            <LoadingAnimation />
        </Card>
    )


    return (
        <FormLayout
            title='Edytuj kategorie'
            onClickButton={handleSaveCategories}
        >
            <FormGroup sx={{ mb: 5 }}>
                {categoryData.map((categoryItem: ICategory) => (
                    <>
                        <FormControlLabel
                            key={categoryItem.name}
                            label={categoryItem.name}
                            control={
                                <Checkbox
                                    checked={categoryState?.includes(categoryItem.name)}
                                    onChange={() => handleChangeCategory(categoryItem.name)}
                                />
                            }
                        />
                        {(categoryItem.subcategories) &&
                            categoryItem.subcategories.map((subcategory: IOpenedCategory) => {
                                if (!categoryState?.includes(categoryItem.name)
                                    &&
                                    !categoryState?.includes(subcategory.name)
                                ) return null;

                                return (
                                    <FormControlLabel
                                        key={subcategory.name}
                                        sx={{ ml: 2 }}
                                        label={subcategory.name}
                                        control={
                                            <Checkbox
                                                checked={categoryState?.includes(subcategory.name)}
                                                onChange={() => handleChangeSubcategory(subcategory.name)}
                                            />
                                        }
                                    />
                                )
                            })}
                    </>
                ))}
            </FormGroup>
        </FormLayout>
    )
}

export default EditCategoryForm;