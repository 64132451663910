import { toast, Bounce } from 'react-toastify';

export const useToast = (autoClose?: number) => {
    const generateToast = (message: string, type: 'success' | 'error') => toast[type](message, {
        position: "top-right",
        autoClose: autoClose || 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

    const success = (message: string) => generateToast(message, 'success');
    const error = (message: string) => generateToast(message, 'error');

    return { success, error };
};