import { Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { blue } from "@mui/material/colors";
import { FC } from "react";

interface ButtonProps {
    name: string,
    onClick: () => void;
    isActived?: boolean;
}

interface ShowroomDialogProps {
    onClose: () => void;
    open: boolean;
    selectedValue?: string | null;
    buttons: ButtonProps[];
}

const ShowroomDialog: FC<ShowroomDialogProps> = ({
    onClose,
    open,
    selectedValue,
    buttons,
}) => {
    return (
        <Dialog
            onClose={onClose}
            open={open}
            PaperProps={{
                style: {
                    width: '80%',
                    maxHeight: '80%',
                },
            }}
        >
            <DialogTitle>Wybierz salon</DialogTitle>
            <List sx={{ pt: 0 }}>
                {buttons.map((button) => (
                    <ListItem key={button.name} disablePadding>
                        <ListItemButton
                            sx={{
                                backgroundColor: button.isActived ? blue[50] : 'white',
                                '&:hover': {
                                    backgroundColor: blue[100],
                                }
                            }}
                            onClick={button.onClick}
                        >
                            <ListItemText primary={button.name} />
                        </ListItemButton>
                    </ListItem>
                ))
                }
            </List>
        </Dialog>
    )
}

export default ShowroomDialog;