import { FC, useState } from "react"
import { Link } from "react-router-dom"
import { INavigationButton } from "../../../../../../interfaces/INavigationButton"
import { navigationButtons } from "../../navigationButtons"
import styles from './webNavigation.module.scss'
import logo from '../../../../../../assets/img/ufo.png'
import styled from "styled-components"
import ShowroomComponent from "./components/ShowroomComponent/ShowroomComponent"
import CartComponent from "./components/CartComponent/CartComponent"


const CartContainer = styled.div`
    display: flex;
    align-items: center;
    right: 30px;
    top: 6px;
    position: fixed;
    z-index: 1000;
`;


const Buttons: FC = () => {
    return (
        <div className={styles.buttonsContainer}>
            {navigationButtons.map((button: INavigationButton, index) => (
                <Link to={button.url} key={index}>
                    <div className={styles.buttonPart}>
                        {button.name}
                    </div>
                </Link>
            ))}
        </div>
    )
}


const PopUpButtons: FC = () => {
    return (
        <div className={`${styles.popUpNavigation}`}>
            {navigationButtons.map((button: INavigationButton, index) => (
                <Link to={button.url} key={index}>
                    <div className={styles.buttonPart}>
                        {button.name}
                    </div>
                </Link>
            ))}
        </div>
    )
}


interface WebNavigationProps {

}

const WebNavigation: FC<WebNavigationProps> = () => {


    const [showPopUpMenu, setShowPopUpMenu] = useState(false)


    window.addEventListener('scroll', function () {
        const scroll = window.scrollY;
        85 >= scroll ? setShowPopUpMenu(false) : setShowPopUpMenu(true);
    })


    return (
        <div className={styles.webContainer}>

            <div className={styles.firstLane} >
                <Link to='/' >
                    <img className={styles.logo} alt='logo' src={logo} />
                </Link>
                <ShowroomComponent />
                <CartContainer>
                    <CartComponent />
                </CartContainer>
            </div>

            <Buttons />

            {showPopUpMenu && <PopUpButtons />}

        </div>
    )
}

export default WebNavigation;