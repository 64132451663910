import { useGlobalState } from "../context/context";
import { useLocalStorage } from "./useLocalStorage"
import { useToast } from "./useToast";

export interface CartItem {
    type: 'furniture' | 'imported furniture';
    name: string;
    id: string;
    image: string;
    link: string;
};

export const useCart = () => {
    const localStorage = useLocalStorage();
    const { dispatch } = useGlobalState();
    const toast = useToast();

    const get = () => {
        const cartItems = localStorage.get('cart') || null;
        dispatch({ type: 'setCart', basketFurniture: cartItems })
    }

    const isInCart = (id: string) => {
        const cart = localStorage.get('cart') || [];
        return cart.some((item: CartItem) => item.id === id);
    }

    const add = (item: CartItem) => {
        const cart = localStorage.get('cart') || [];

        if (cart.find((i: CartItem) => i.id === item.id)) return

        cart.push(item);
        localStorage.set('cart', cart);

        dispatch({ type: 'setCart', basketFurniture: cart })
        toast.success(`Dodano ${item.name} do koszyka!`)
    }

    const remove = (id: string) => {
        const cart = localStorage.get('cart') || [];

        const itemIndex = cart.findIndex((cartItem: CartItem) => cartItem.id === id);

        if (itemIndex !== -1) {
            cart.splice(itemIndex, 1);
            localStorage.set('cart', cart);
            dispatch({ type: 'setCart', basketFurniture: cart })
        }
    }

    const clearCart = () => {
        localStorage.set('cart', []);
        dispatch({ type: 'setCart', basketFurniture: [] })
    }

    return {
        get,
        isInCart,
        add,
        remove,
        clearCart,
    }
}