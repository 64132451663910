import styled from "@emotion/styled";
import { FC } from "react";
import { AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Area } from "recharts";


const Container = styled.div`
    display: flex;
    justify-content: center;
`


interface AreaChartComponentProps {
    data: any;
    yearToDisplay: number | null;
}

const AreaChartComponent: FC<AreaChartComponentProps> = ({
    data,
    yearToDisplay,
}) => {

    if (!yearToDisplay) return (<></>)

    return (
        <Container>
            <AreaChart
                width={1000}
                height={420}
                data={data[yearToDisplay]}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="pv" name='Wejścia na stronę' stroke="283890" fill="#283890" />
            </AreaChart>
        </Container>
    )
}

export default AreaChartComponent;