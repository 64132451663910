import furnitureController from "../../Api/furnitureController";
import { useParams } from "react-router-dom";
import { FC, useCallback, useState } from "react";
import { useEffect } from "react";
import { IFurniture } from "../../interfaces/IFurniture";
import Layout from "../../components/layout/Layout";
import "yet-another-react-lightbox/styles.css";
import LoadingAnimation from "../../components/_common/Loading";
import FurnitureItem from "./FurnitureItem/FurnitureItem";

interface IParams {
  furnitureId?: string;
}

interface PieceOfFurnitureProps { }

const PieceOfFurniture: FC<PieceOfFurnitureProps> = () => {
  const [pieceOfFurnitureData, setPieceOfFurnitureData] = useState<IFurniture | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showEditTools, setShowEditTools] = useState(false);
  const [index, setIndex] = useState(0);
  const [showImageView, setShowImageView] = useState(false);

  const params: IParams = useParams();


  const getPieceOfFurniture = useCallback(async () => {
    setLoading(true);
    if (!params.furnitureId) return;
    const res = await furnitureController.getPieceOfFurniture(
      params.furnitureId
    );
    if (res.ok) {
      setPieceOfFurnitureData(res.furniture);
    }
    setLoading(false);
  }, [params.furnitureId])




  useEffect(() => {
    getPieceOfFurniture();
  }, [
    getPieceOfFurniture,
  ]);


  const renderPieceOfFurniture = () => {
    if (loading) return <LoadingAnimation />

    if (!pieceOfFurnitureData) return <div>Dany mebel nie znajduje się w bazie</div>

    return (
      <FurnitureItem
        furnitureCategories={pieceOfFurnitureData.furnitureCategories}
        furniturePremises={pieceOfFurnitureData.premises}
        showPrice={pieceOfFurnitureData.isPriceVissible}
        onRefreshFurniture={getPieceOfFurniture}
        id={pieceOfFurnitureData._id}
        name={pieceOfFurnitureData.name}
        images={pieceOfFurnitureData.images}
        thumbnailImages={pieceOfFurnitureData.thumbnailImagesPaths}
        description={pieceOfFurnitureData.description}
        width={pieceOfFurnitureData.width}
        height={pieceOfFurnitureData.height}
        depth={pieceOfFurnitureData.depth}
        producerId={pieceOfFurnitureData.producer}
        partCollection={pieceOfFurnitureData.partCollection}
        price={pieceOfFurnitureData.price}
        crossedPrice={pieceOfFurnitureData.crossed}
        onSetLoading={(loading: boolean) => setLoading(loading)}
        showEditTools={showEditTools}
        onSetShowEditTools={setShowEditTools}
        index={index}
        onSetIndex={setIndex}
        showImageView={showImageView}
        onSetShowImageView={setShowImageView}
      />
    )
  }

  return (
    <Layout title={pieceOfFurnitureData?.name || "Mebel"}>
      {renderPieceOfFurniture()}
    </Layout>
  );
}

export default PieceOfFurniture;