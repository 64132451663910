import { FC, useRef, useState } from "react";
import FormLayout from "../FormLayout";
import CustomInput from "../../_common/CustomInput";
import CustomButton from "../../_common/CustomButton";
import { Card, Divider } from "@mui/material";
import LoadingAnimation from "../../_common/Loading";
import furnitureController, { IEditDimensionDto } from "../../../Api/furnitureController";
import { useToast } from "../../../hooks/useToast";
import { useModal } from "../../../hooks/useModal";

interface EditDimensionFormProps {
    _id: string,
    dimensionName: 'width' | 'height' | 'depth',
    value: number | null | undefined,
    onRefreshFurniture: () => void,
}

const EditDimensionForm: FC<EditDimensionFormProps> = ({
    _id,
    dimensionName,
    value,
    onRefreshFurniture,
}) => {
    const [dimensionValue, setDimensionValue] = useState<number>(value || 0);
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useRef(useToast()).current;
    const modal = useModal();

    const handleSaveDimension = async () => {
        setLoading(true);
        const furnitureData: IEditDimensionDto = {
            furnitureId: _id,
            dimensionName,
            value: dimensionValue,
        }
        const res = await furnitureController.editDimension(furnitureData)
        setLoading(false);
        if (!res.ok) return toast.error(res.message);
        onRefreshFurniture();
        modal.close();
    }


    if (loading) return (
        <Card>
            <LoadingAnimation />
        </Card>
    )

    return (
        <FormLayout
            title={
                dimensionName === 'width' ? 'Edytuj szerokość' :
                dimensionName === 'height' ? 'Edytuj wysokość' :
                dimensionName === 'depth' ? 'Edytuj głębokość' : ''
            }
        >
            <CustomInput
                sx={{
                    width: '100%',
                }}
                value={dimensionValue}
                setValue={setDimensionValue}
                mode="numeric"
                label={
                    dimensionName === 'width' ? 'Szerokość' :
                        dimensionName === 'height' ? 'Wysokość' :
                            dimensionName === 'depth' ? 'Głębokość' : ''
                }
                variant="filled"
            />
            <Divider sx={{ m: 1 }} />
            <CustomButton
                sx={{
                    width: '100%',
                }}
                text="Zapisz"
                onClick={handleSaveDimension}
            />
        </FormLayout>
    )
}

export default EditDimensionForm;